.ReactModalPortal {
  position: relative;
  z-index: 51;
}

.popup-headline {
  margin-bottom: 28px;
}

.modal--call-me {
  label {
    font-weight: normal;
  }
}

.modal-body {
  margin-bottom: 40px;
}

.modal__body {
  padding: 48px 46px;
  background-color: $blue-background;

  .modal__title {
    margin-bottom: 26px;
  }

  p {
    font-weight: 600;
  }
}

.modal__title {
  @include fs(28);
  color: $blue-primary;
  margin-bottom: 0;
  font-weight: 700;
}

.modal__subtitle {
  margin-bottom: 20px;
  display: block;
  p {
    font-weight: 500;
  }
}

.modal__subtitle--blue {
  color: $blue-primary;
}

.modal__actions {
  padding-top: 20px;
  display: flex;
  justify-content: space-between;
}

.channel__list {
  h2 {
    @include fs(17);
    color: $blue-primary;
    font-weight: 700;
    margin-top: 1.5em;
  }

  div {
    display: flex;
    flex-wrap: wrap;

    > span {
      @media (max-width: $screen-ts-max) {
        flex-basis: calc(100% / 2);
      }
      @media (min-width: $screen-sm) {
        flex-basis: calc(100% / 4);
      }
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 8px 0;
      img {
        width: auto;
        object-fit: contain;
        object-position: left;
        height: auto;
        max-width: 50px;
        max-height: 30px;
        display: block;
        padding: 5px 15px 5px 0;
      }
    }
  }
}
