.services {
  .h2 {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 10px;
  }

  .h3 {
    font-size: 20px;
    font-weight: 500;
  }

  .ui__loading {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }
}

.services__subtitle {
  margin-bottom: 30px;
  line-height: 1.5;
}

.activation {
  padding-top: 46px;
}

.packages {
  margin-top: 30px;
  margin-bottom: 70px;
  display: flex;
  flex-wrap: wrap;
}

.packages__item {
  padding: 23px;
  border: 1px solid #c8c8c8;
  flex-basis: calc(100% / 3 - 4px);
  margin-bottom: 6px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (max-width: 1300px) {
    text-align: center;
  }
  .btn.btn--primary {
    @media (max-width: 1300px) {
      width: 100%;
    }
  }
  button.link {
    @media (max-width: 1300px) {
      margin: 0.5rem 0 0 0;
      text-align: center;
    }
    @media (max-width: $screen-lg-max) {
      margin-left: 0.5rem;
    }
    @media (min-width: $screen-xl) {
      margin-left: 1rem;
    }
  }

  &:not(:nth-child(4n)) {
    margin-right: 6px;
  }

  @media (min-width: $screen-sm) and (max-width: $screen-sm-max) {
    padding: 15px;
  }

  @media (max-width: $screen-xm-max) {
    flex-basis: 100%;
    &:not(:nth-child(4n)) {
      margin-right: 0;
    }
  }
}

.packages__item--selected {
  background-color: $blue-background;
  border: 1px solid #1e1ec8;
}

.packages__item--disabled {
  background-color: #eee;
  border: 1px solid #ccc;
  filter: grayscale(100%);
}

.packages__item__title {
  @include fs(30);
  font-weight: 700;
}

.packages__tag {
  position: absolute;
  top: 1rem;
  right: -1px;
  background-color: $red;
  color: #fff;
  @include fs(15);
  padding: 0.3rem 0.5rem;
  // font-weight: 700;
}

.packages__channels {
  margin-top: 1rem;
  img {
    max-height: 80px;
    margin: 5px 7px 4px 0;
    filter: grayscale(100%);
  }
}

.packages__item__price__outer {
  display: flex;
  flex-wrap: wrap;
  padding: 22px 0;
  @media (max-width: $screen-sm-max) {
      justify-content: center;
  }
}

.packages__item__price {
  font-weight: 700;
  color: $blue-primary;
  @include fs(18);
  &:not(:last-child) {
    margin-right: 13px;
  }
}

.packages__item__price--discounted {
  text-decoration: line-through;
}

.packages__item__discount {
  color: $white;
  background-color: $blue-primary;
  border-radius: 14px;
  padding: 1px 13px;
  @include fs(18);
  span {
    font-weight: 700;
  }

  @media (max-width: $screen-md-max) {
    @include fs(16);
  }
  @media (min-width: $screen-sm) and (max-width: $screen-sm-max) {
    @include fs(15);
  }
}

.supplementary-packages {
  margin-top: 30px;
  margin-bottom: 70px;
}

.supplementary-packages__item {
  padding: 28px 23px;
  border: 1px solid #c8c8c8;
  margin-bottom: 7px;
  display: flex;

  justify-content: space-between;
  @media (max-width: $screen-sm-max) {
    flex-wrap: wrap;
    padding: 15px 10px;
  }

  &--selected {
    background-color: #e8e8f9;
    border: 1px solid #1e1ec8;
  }

  &--disabled {
    background-color: #eee;
    border: 1px solid #ccc;
    filter: grayscale(100);
  }

  &--is-ppv {
    color: $white;
    position: relative;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      background-color: $blue-overlay;
    }

    .supplementary-packages__item__inner {
      display: block;

      .supplementary-packages__item__info {
        padding-left: 32px;
        text-align: left;

        .link {
          color: $white;
        }
      }

      span.supplementary-packages__item__name {
        color: $white;
      }
    }

    .supplementary-packages__item__price-wrapper {

      .supplementary-packages__item__price {
        color: $white;
      }
    }
  }
}

.supplementary-packages__item__inner {
  position: relative;
  display: flex;
  @media (max-width: $screen-sm-max) {
    justify-content: space-between;
    flex-basis: 100%;
  }
}

.supplementary-packages__item__price-wrapper {
  position: relative;
}

span.supplementary-packages__item__name {
  font-size: 18px;
  font-weight: 700;
  color: $blue-primary;
  white-space: nowrap;
  margin-top: 0;
  margin-right: 20px;
  @media (max-width: $screen-sm-max) {
    margin-right: 10px;
  }
}

.supplementary-packages__item__info {
  padding-left: 20px;
  flex-basis: 100%;
  @media (max-width: $screen-sm-max) {
    padding-left: 9px;
    text-align: right;
  }
}

.supplementary-packages__item__price__outer {
  display: flex;
  justify-content: flex-end;
  @media (max-width: $screen-sm-max) {
    padding-top: 10px;
    flex-basis: 100%;
    text-align: right;
  }
}

.supplementary-packages__item__price {
  justify-self: flex-end;
  color: $blue-primary;
  @include fs(18);
  font-weight: 700;
}

.supplementary-packages__item__price--discounted {
  text-decoration: line-through;
}

.supplementary-packages__item__discount {
  color: $white;
  background-color: $blue-primary;
  border-radius: 14px;
  padding: 1px 13px;
  @include fs(18);
  margin-left: 13px;
  white-space: nowrap;

  span {
    font-weight: 700;
  }

  @media (max-width: $screen-md-max) {
    @include fs(16);
  }
}

.portfolio__options {
  display: flex;
}

.portfolio__options__item {
  flex-basis: 50%;
  padding: 10px;
  @include fs(18);
  border: 1px solid #c8c8c8;
  border-radius: 0;
  box-shadow: none;
  background-color: $white;
  color: $black;

  &:not(:last-child) {
    border-right: 0;
  }
}

.portfolio__options__item--selected {
  background-color: $blue-primary;
  color: $white;
}

.portfolio__content {
  border: 1px solid #c8c8c8;
  border-top: 0;
  padding: 37px 20px;
  @media (max-width: $screen-sm-max) {
    padding: 16px 8px;
  }
}

.portfolio__content__info {
  u {
    cursor: pointer;
  }
}

.portfolio__content__text {
  font-weight: 700;
  margin-bottom: 10px;
  @include fs(18);
  color: $black;

  u {
    cursor: pointer;
  }
}

.portfolio__content__text--lonely {
  margin-bottom: 30px;
}

.portfolio__content__subtitle {
  margin-bottom: 30px;
}

.portfolio__content__subtitle--warning {
  color: $validation-text-alert;
  font-weight: 700;
}

.portfolio__content__item {
  margin-bottom: 20px;

  &:only-child {
    margin-bottom: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.portfolio__content__item__content {
  display: flex;
  justify-content: space-between;
  position: relative;

  @media (max-width: $screen-sm-max) {
    flex-wrap: wrap;
  }
}

span.portfolio__content__item__name {
  white-space: nowrap;
  margin-top: 0;
  margin-right: 20px;
  @include fs(18);
  @media (max-width: $screen-sm-max) {
    @include fs(16);
  }
}

span.portfolio__content__item__info {
  width: 20px;
  height: 20px;
  display: inline-block;
  border-radius: 50%;
  border: 1px solid $border-gray-light;
  color: $border-gray-light;
  font-weight: 700;
  text-align: center;
  line-height: 19px;
  cursor: pointer;
  margin-left: 10px;
  text-decoration: none;

  &--white {
    color: $white;
    border-color: $white;
  }
}

.portfolio__content__item__dialog {
  position: absolute;
  bottom: 100%;
  left: calc(100% - 110px);
  margin-bottom: 7px;
  text-align: center;
  padding: 4px 10px;
  width: 200px;
  border-radius: 6px;
  color: $white;
  background-color: $placeholder-gray;
  opacity: 0.9;
  @media (max-width: $screen-sm-max) {
    right: 0;
    left: auto;
    margin-right: -10px;
  }

  &:after {
    content: ' ';
    position: absolute;
    top: 100%;
    right: calc(50% - 5px);
    border-width: 5px;
    border-style: solid;
    border-color: $placeholder-gray transparent transparent transparent;
    @media (max-width: $screen-sm-max) {
      margin-left: -5px;
      right: 15px;
    }
  }
}

.portfolio__content__item__name {
  // text-decoration: underline;
  position: relative;
}

.portfolio__content__item__price {
  font-weight: 700;
  @media (max-width: $screen-sm-max) {
    text-align: right;
    padding-top: 10px;
    flex-basis: 100%;
  }
}

.portfolio__content__item__inner {
  display: flex;
  flex-basis: 30%;
  justify-content: space-between;
  @media (max-width: $screen-lg-max) {
    flex-basis: 40%;
  }
  @media (max-width: $screen-sm-max) {
    padding-top: 8px;
    flex-basis: 100%;
  }
}

.instalation__item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  @media (max-width: $screen-sm-max) {
    flex-wrap: wrap;
  }

  &:last-child {
    margin-bottom: 0;
  }

  // span {
  //   text-decoration: underline;
  // }
}

.installation__item__price {
  font-weight: 700;
  @media (max-width: $screen-sm-max) {
    padding-top: 8px;
    flex-basis: 100%;
    text-align: right;
  }
}

.list__item {
  font-weight: 500;
  display: flex;

  &.list__item--active {
    font-weight: 600;

    * {
      font-weight: inherit;
    }
  }

  /*
  &.list__item--order {
      margin-top: 15px;
      margin-bottom: 15px;
  }*/
}

.list__item__info {
  font-weight: 500;

  &.list__item__info--active {
    font-weight: 600;
  }
}

.updown__header {
  display: flex;
  align-items: center;
  margin-top: 25px;
  margin-bottom: 15px;

  a {
    color: $blue-primary;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  h3 {
    flex: 1;
    color: black;
    font-size: 17px;
    margin-bottom: 0;
    position: relative;
    margin-right: 15px;

    &:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 1px;
      left: 0;
      top: 50%;
      background-color: $silver;
    }

    span {
      background-color: white;
      padding-right: 10px;
      position: relative;
    }
  }
}

.list--inkaso {
  width: 100%;
}

.list__invoice {
  display: flex;
  // overflow: hidden;

  .form__item {
    &:first-child {
      flex-basis: 200px;
    }

    &:nth-child(2) {
      flex-basis: calc(100% - 325px);
    }

    &:last-child {
      flex-basis: 125px;
    }
  }
}

.updown__header,
.updown__content {
  h3 {
    font-weight: 600;
  }

  a,
  label {
    font-weight: 500;
  }
}

.slide_switch {
  position: relative;
  padding-right: 13px;
  display: inline-block;

  &:after {
    content: '';
    position: absolute;
    right: 0;
    top: 50%;
    margin-top: -2px;
    background-image: url(../images/ico-up.svg);
    width: 9px;
    height: 6px;
  }
}

.closed {
  .slide_switch {
    &:after {
      transform: scaleY(-1);
    }
  }

  .updown__content {
    height: 0;
    overflow: hidden;
  }
}

.tags {
  margin-left: -10px;
  margin-right: -10px;
}

.tag {
  margin: 0 10px;

  &.tag--active {
    color: $green;
  }

  &.tag--discount {
    color: $discount;
  }

  &.tag--price {
    color: $black;
  }

  &.tag--old-price {
    color: green;
    text-decoration: line-through;
  }
}

.order__list {
  border-top: 1px solid $silver;
  border-bottom: 1px solid $silver;
  margin: 15px 0;
  padding: 5px 0;
}

.confirmation-wrap {
  text-align: center;
}

.confirmation {
  padding: 50px 0;
  display: inline-block;
  text-align: left;
}

.confirmation__header {
  display: inline-block;
}

.offer {
  > .list__row {
    justify-content: space-between;
    flex-wrap: nowrap;
  }

  .list__col--label {
    padding-right: 6px;
  }

  .list__col--info {
    padding-left: 0;
    flex: 1 1 auto;
  }
}

.list--modal {
  @media (max-width: $screen-xm-max) {
    .list__col {
      flex-basis: 100%;
      margin-bottom: 10px;
    }
  }
}
