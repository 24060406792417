a.btn,
.btn {
  display: inline-block;
  padding: 12px 22px;
  font-weight: 400;
  font-size: 18px;
  line-height: 1;
  border: none;
  transition: background-color 0.2s;
  position: relative;
  text-decoration: none;
  text-align: center;
  &:disabled {
    background-color: $border-gray-light;
    &:hover {
      background-color: $border-gray-light;
    }
  }
}

a.btn--primary,
.btn--primary {
  color: $btn-primary-text;
  background-color: $btn-primary-background;

  &:hover {
    background-color: #11118f;
  }
}

.btn--item_selected {
  background-color: #8eeb00;
  &:focus {
    box-shadow: none;
    outline: none;
  }
  &:disabled {
    background-color: #8eeb00;
    &:hover {
      background-color: #8eeb00;
    }
  }
}

.btn--reward {
  background-color: $red;
  color: $white;
  &:hover {
    color: $white;
  }
  &:focus {
    box-shadow: none;
    outline: none;
  }
  &:disabled {
    background-color: $red;
    &:hover {
      background-color: $red;
    }
  }
}

.btn--secondary {
  color: $black;
  border: 1px solid $border-gray-light;
  background-color: transparent;
  transition-duration: 0.3s;

  &:hover {
    background-color: $black;
    color: $white;

    &.btn--prev {
      &:after {
        background-image: url(../images/ico-more-white.svg);
        transform: scaleX(-1);
        margin-left: -1px;
      }
    }

    &.btn--next {
      &:after {
        background-image: url(../images/ico-more-white.svg);
      }
    }
  }

  &.btn--prev {
    &:after {
      background-image: url(../images/ico-more-left-blue.svg);
    }
  }

  &.btn--next {
    &:after {
      background-image: url(../images/ico-more-right-blue.svg);
    }
  }
}

a.btn--next,
.btn--next {
  padding-right: 45px;

  &:after {
    content: '';
    background-image: url(../images/ico-more-white.svg);
    background-repeat: no-repeat;
    width: 9px;
    height: 12px;
    position: absolute;
    right: 22px;
    top: 50%;
    margin-top: -6px;
    background-size: contain;
  }
}

a.btn--prev,
.btn--prev {
  padding-left: 42px;

  &:after {
    content: '';
    background-image: url(../images/ico-more-white.svg);
    background-repeat: no-repeat;
    width: 9px;
    height: 12px;
    position: absolute;
    left: 26px;
    top: 50%;
    margin-top: -6px;
    background-size: contain;
  }
}

a.btn--plus,
.btn--plus {
  padding-left: 42px;

  &:after {
    content: '';
    background-image: url(../images/primary-btn-plus.svg);
    background-repeat: no-repeat;
    width: 11px;
    height: 11px;
    position: absolute;
    left: 25px;
    top: 50%;
    margin-top: -5px;
    background-size: contain;
  }
}

a.btn--lock,
.btn--lock {
  padding-right: 52px;

  &:after {
    content: '';
    background-image: url(../images/login_lock.svg);
    background-repeat: no-repeat;
    width: 10px;
    height: 12px;
    position: absolute;
    right: 36px;
    top: 50%;
    margin-top: -6px;
  }
}

a.btn--delete,
a.btn--edit,
.btn--edit,
.btn--delete {
  padding: 0;
  text-indent: -999px;
  position: relative;
  overflow: hidden;
  width: 30px;
  line-height: 20px;
  border: none;
  background-color: transparent;

  &:after {
    text-indent: 0;
    content: '';
    background-repeat: no-repeat;
    position: absolute;
    top: 0;
    right: 0;
    background-size: contain;
    width: 100%;
    height: 100%;
    background-position: center;
  }
}

a.btn--delete,
.btn--delete {
  &:after {
    background-image: url(../images/delete.svg);
  }
}

a.btn--edit,
.btn--edit {
  &:after {
    background-image: url(../images/edit.svg);
  }
}

a.btn--filter,
.btn--filter {
  display: inline-block;
  background-color: white;
  color: $text-gray;
  border: 1px solid $silver;

  &:hover,
  &.active {
    color: black;
    border-color: $blue-primary;
  }
}

.btn--wideboi {
  min-width: 190px;
}

.btn--right {
  float: right;
}

.btn--help {
  position: absolute;
  top: 0;
  left: 0;
  width: 22px;
  height: 22px;
  border: none;
  background-color: transparent;
  background-image: url(../images/question.svg);
  background-repeat: no-repeat;
  background-size: contain;
  text-indent: -999px;
  overflow: hidden;
  margin-left: 5px;
}
.btn--big {
  padding: 1rem 1.25rem;
}
