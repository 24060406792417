.grid {
	padding: 0 1rem;

	@include variant(container) {
		margin: 0 auto;
		max-width: 1200px;
		width: 100%;
	}

	@include variant(ng) {
		padding: 0;
	}

	@media (min-width: $screen-lg) {
		@include variant(big) {
			padding-left: 2rem;
			padding-right: 2rem;
		}
	}
}