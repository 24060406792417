.admin__inner,
.bann {
	background-color: white;
	margin-bottom: 2.5rem;
	@media (max-width: $screen-ts-max) {
		padding: 15px;
	}
	@media (min-width: $screen-sm) {
		padding: 35px;
	}
}

.admin__inner {
	width: 100%;

	p {
		strong {
			color: $black;
		}
	}

	h2 {
		@media (max-width: $screen-sm-max) {
			@include fs(22);
		}
		@media (min-width: $screen-md) and (max-width: $screen-lg-max) {
			@include fs(24);
		}
		@media (min-width: $screen-xl) {
			@include fs(26);
		}
		font-weight: $fw-700;
		color: $blue-primary;
		margin-bottom: 20px;
	}

	/*
	h3 {
			@include fs(24);
			color: $blue-primary;
			font-weight: 400;
	}
	*/

	article {
		position: relative;

		time {
			padding-top: 10px;
			text-align: right;
			@include fs(16);
			color: $blue-primary;
			width: 80px;
			@media (max-width: $screen-xm-max) {
				position: relative;
				text-align: center;
				display: inline-block;
				margin: 10px 0;
			}
			@media (min-width: $screen-ts) {
				position: absolute;
			}

			&:before {
				content: '';
				border-radius: 2px;
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 4px;
				background-color: $blue-primary;
			}
		}
	}

	&.promo {
		padding: 0;
		position: relative;

		p {
			color: $white;
		}
	}

	&.tv {
		background-size: cover;
		position: relative;
		background-position: center;
		background-image: url(../images/promo_tv.jpg);
		border: none;
		text-align: left;
		margin: 0;
		// min-height: 216px;

		// @media(min-width: $screen-md) and (max-width: $screen-lg-max) {
		//     width: 50%;
		// }

		h2 {
			@include fs(32);
			padding-bottom: 40px;
			color: $white;
		}

		p {
			background-color: $green;
			color: $white;
			font-weight: 600;
			display: inline-block;
			padding: 1rem 2rem;
			@include fs(17);
			text-decoration: none;
			transition-duration: 0.2s;
		}

		&:hover {
			p {
				background-color: darken($green, 8%);
			}
		}
	}

	.line-label {
		position: relative;
		font-size: 17px;
		color: $black;
		font-weight: 500;

		span {
			background-color: white;
			padding-right: 10px;
			position: relative;
		}

		&:before {
			content: "";
			position: absolute;
			width: 100%;
			height: 1px;
			left: 0;
			top: 50%;
			background-color: #d9d9d9;
		}
	}
}

.section {
	margin-bottom: 35px;
}

.mt0 {
	margin-top: 0;
}

.mt20 {
	margin-top: 20px;
}

.mb20,
.form__row.mb20 {
	margin-bottom: 20px;
}

.ui__loading {
	border-radius: 3px;
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
	flex-direction: column;
	background-color: $white-6;

	span {
		font-size: 14px;
		margin-top: 10px;
	}

	.ui__loading__text {
		font-weight: 600;
		text-transform: uppercase;
		font-size: 25px;
		background-color: $white-7;
		padding: 20px 30px;
		margin: 20px 0 0;
		color: $blue-primary;

	}

	&--fixed {
		position: fixed;
		width: 100%;
		height: 100%;
		left: 0;
		top: 0;
	}
}

.ui__loading__rect {
	background-color: $blue-primary;
	height: 50px;
	width: 6px;
	margin: 0 2px;
	display: inline-block;
	animation: ui__loading 1.2s infinite ease-in-out;
	position: relative;
}


.ui__loading__rect--1 {
	animation-delay: -1.2s;
}

.ui__loading__rect--2 {
	animation-delay: -1.1s;
}

.ui__loading__rect--3 {
	animation-delay: -1s;
}

.ui__loading__rect--4 {
	animation-delay: -0.9s;
}

.ui__loading__rect--5 {
	animation-delay: -0.8s;
}

@keyframes ui__loading {
	0%,
	40%,
	100% {
		transform: scaleY(0.4);
	}
	20% {
		transform: scaleY(1);
	}
}

.global-loading {
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	z-index: 55;
}

.with-input {
	display: flex;
	align-items: center;

	label {
		padding-right: 10px;
		flex: none;
	}
}

.wrap {
	flex-wrap: wrap;
}
