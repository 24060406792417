.fade-enter {
  opacity: 0;
  height: 0;
}
.fade-enter-active {
  opacity: 1;
  transition: opacity 200ms;
}
.fade-enter-done {
  opacity: 1;
  transition: opacity 200ms;
}
.fade-exit {
  opacity: 1;
  height: 32px;
}
.fade-exit-active {
  opacity: 0;
  height: 32px;
  transition: opacity 200ms;
}
.fade-exit-done {
  opacity: 0;
  height: 0;
  transition: opacity 200ms;
}
