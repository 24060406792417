.form__bank__details {
  .form__item, input, select {
    display: inline;
    &[name="bankPre"] {
      width: calc(578px * 0.3);
    }
    &[name="bankAccount"] {
      width: calc(578px * 0.4);
    }
    &[name="bankCode"] {
      width: calc(578px * 0.22);
    }
  }
}
