.col {
  flex: 0 0 100%;
  max-width: 100%;
  padding: 1rem 1rem 0 1rem;

  @include variant(ng) {
    padding: 0;
  }

	@media (min-width: $screen-lg) {
    @include variant(big) {
      padding: 1rem 1rem 0 1rem;
    }
  }

  @include variant(banner) {
    flex: 0 0 calc(160px + 2rem);
    padding: 1rem 1rem 0 1rem;
  }
}