.progress {
  display: flex;
  padding: 0;
}

.progress__item {
  list-style-type: none;
  font-size: 17px;
  color: #a2a2a2;
  flex: 1 1 0;
  position: relative;
  padding-bottom: 20px;
  margin-right: 6px;

  &:before,
  &:after {
    bottom: 0;
    left: 0;
    box-sizing: border-box;
    content: "";
    height: 8px;
    position: absolute;
    width: 100%;
  }

  &:before {
    background-color: $silver;
  }

  &:last-child {
    margin-right: 0;
  }
}
.progress__item--active {
    color: #000;
    &:before {
        background-color: $green;
    }
}

.progress__item--finished {
    color: $green;
    cursor: pointer;
    &:before {
        background-color: $green;
    }
}

.progress_label {
  text-align: center;
  display: block;
  font-weight: $fw-700;
}