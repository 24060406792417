// normalize.less v2.0.1 | git.io/normalize-less | $reitermarkus

// ============================
// HTML5 display definitions =
// ============================

// Correct ‘block’ display not defined in IE 8-9.
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
nav,
section,
summary {
	display: block;
}

// Correct ‘inline-block’ display not defined in IE 8-9.
audio,
canvas,
video {
	display: inline-block;
}

// Prevent modern browsers from displaying ‘audio’ without controls and remove excess height in iOS 5 devices.
audio:not([controls]) {
	display: none;
	height: 0;
}

// Address styling for ‘hidden’ attribute absent in IE 8-9.
[hidden] {
	display: none;
}


// ============================
// Base =
// ============================

// 1. Set default font family to ‘sans-serif’.
// 2. Prevent iOS text size adjust after orientation change, without disabling user zoom.
html {
	font-family: $standard-font; // 1
	-webkit-text-size-adjust: 100%; // 2
	-ms-text-size-adjust: 100%; // 2
}

// Remove default margin.
body {
	margin: 0;
}


// ============================
// Links =
// ============================

// Address ‘outline’ inconsistency between Chrome and other browsers.
a:focus {
	outline: thin dotted;
}

// Improve readability when focussed or hovered in all browsers.
a:active,
a:hover {
	outline: 0;
}


// ============================
// Typography =
// ============================

// Address ‘h1’ font sizes within ‘section’ and ‘article’ in Firefox 4+, Safari 5 and Chrome.


// Address styling absent in IE 8-9, Safari 5 and Chrome.
abbr[title] {
	border-bottom: 1px dotted;
}

// Address style set to ‘bolder’ in Firefox 4+, Safari 5 and Chrome.
b,
strong {
	font-weight: bold;
}

// Address styling absent in Safari 5 and Chrome.
dfn {
	font-style: italic;
}

// Address styling absent in IE 8-9.
mark {
	background: $mark-bg;
	color: $mark-color;
}

// Correct font family set oddly in Safari 5 and Chrome.
code,
kbd,
pre,
samp {
	font-family: $monospace-font;
	font-size: 1em;
}

// Improve readability of pre-formatted text in all browsers.
pre {
	white-space: pre;
	white-space: pre-wrap;
	word-wrap: break-word;
}

// Set consistent quote types.
q {
	quotes: "\201C" "\201D" "\2018" "\2019";
}

// Address inconsistent and variable font size in all browsers.
small {
	font-size: 80%;
}

// Prevent ‘sub’ and ‘sup’ affecting ‘line-height’ in all browsers.
sub,
sup {
	font-size: 75%;
	line-height: 0;
	position: relative;
	vertical-align: baseline;
}

sup {
	top: -0.5em;
}

sub {
	bottom: -0.25em;
}


// ============================
// Embedded content =
// ============================

// Remove border when inside ‘a’ element in IE 8-9.
img {
	border: 0;
}

// Correct overflow displayed oddly in IE 9.
svg:not(:root) {
	overflow: hidden;
}


// ============================
// Figures =
// ============================

// Address margin absent in IE 8-9 and Safari 5.
figure {
	margin: 0;
}


// ============================
// Forms =
// ============================

// Define consistent border, margin and padding.
fieldset {
	border: 1px solid $fieldset-border;
	margin: 0 2px;
	padding: 0.35em 0.625em 0.75em;
}

// 1. Correct color not being inherited in IE 8-9.
// 2. Remove padding so people aren't caught out if they zero out fieldsets.
legend {
	border: 0; // 1
	padding: 0; // 2
}

// 1. Correct font family not being inherited in all browsers.
// 2. Correct font size not being inherited in all browsers.
// 3. Address margins set differently in Firefox 4+, Safari 5, and Chrome
button,
input,
select,
textarea {
	font-family: inherit; // 1
	font-size: 100%; // 2
	margin: 0; // 3
}

// Address Firefox 4+ setting ‘line-height’ on ‘input’ using ‘!important’ in the UA stylesheet.
button,
input {
	line-height: normal;
}

// 1. Avoid the WebKit bug in Android 4.0.* where (2) destroy native ‘audio’ and ‘video’ controls.
// 2. Correct inability to style clickable ‘input’ types in iOS.
// 3. Improve usability and consistency of cursor style between image-type ‘input’ and others.
button,
html input[type="button"], // 1
input[type="reset"],
input[type="submit"] {
	-webkit-appearance: button; // 2
	cursor: pointer; // 3
}

// Re-set default cursor for disabled elements.
button[disabled],
input[disabled] {
	cursor: default;
}

// 1. Addresses box sizing set to ‘content-box’ in IE 8-9.
// 2. Removes excess padding in IE 8-9.
input[type="checkbox"],
input[type="radio"] {
	padding: 0; /* 2 */
}

// 1. Addresses ‘appearance’ set to ‘searchfield’ in Safari 5 and Chrome.
// 2. Addresses ‘box-sizing’ set to ‘border-box’ in Safari 5 and Chrome (include ‘-moz’ to future-proof).
input[type="search"] {
	-webkit-appearance: textfield; // 1
	box-sizing: content-box;
}

// Removes inner padding and search cancel button in Safari 5 and Chrome on OS X.
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
	-webkit-appearance: none;
}

// Removes inner padding and border in Firefox 4+.
button::-moz-focus-inner,
input::-moz-focus-inner {
	border: 0;
	padding: 0;
}

// 1. Removes default vertical scrollbar in IE 8-9.
// 2. Improves readability and alignment in all browsers.
textarea {
	overflow: auto; // 1
	vertical-align: top; // 2
}


// ============================
// Tables =
// ============================

// Remove most spacing between table cells.
table {
	border-collapse: collapse;
	border-spacing: 0;
}
* {
	box-sizing: border-box;
}