@mixin clearfix() {
	&:before,
	&:after {
		content: "";
		display: table;
	}
	&:after {
		clear: both;
	}
}

@mixin grayscale() {
	filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale");
	filter: gray;
	-webkit-filter: grayscale(100%);
}

@mixin grayscale-remove() {
	filter: none;
	-webkit-filter: grayscale(0);
}

@mixin fs($value) {
	$remValue: ($value / $fs-base);

	font-size: $value+px;
	font-size: $remValue+rem;
}

@mixin mtem($value) {
	$emValue: ($fs-base / $value);
	@if ($value < $fs-base) {
		$emValue: ($value / $fs-base);
	}

	margin-top: $emValue+em;
}

@mixin mbem($value) {
	$emValue: ($fs-base / $value);
	@if ($value < $fs-base) {
		$emValue: ($value / $fs-base);
	}

	margin-bottom: $emValue+em;
}

@mixin placeholder($color) {
	&::-moz-placeholder {
		color: $color;
		opacity: 1;
	}
	&:-ms-input-placeholder {
		color: $color;
	}

	&::-webkit-input-placeholder {
		color: $color;
	}
}