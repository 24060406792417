$loaderSteps: 5;

.loader {
  position: relative;
  margin: 40px 0 60px 0;
}

.vertical {
  width: 15px;
  max-height: 300px;
  height: calc(100vh - 300px);
  background-color: #d3d3d3;
  border-radius: 7px;
  @include fs(0);
  position: relative;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 0;
    background-color: $green;
    border-radius: 7px 7px 0 0;
    transition-duration: 0.8s;
  }
}

@for $i from 1 through $loaderSteps {
  .vertical--#{$i} {
    &:before {
      height: calc(100% / #{$loaderSteps} * (#{$i - 1}));
      @if $i == $loaderSteps {
        border-radius: 7px;
      }
    }
  }
}

.vertical--#{$loaderSteps+1}{
  &:before {
    height: 100%;
    border-radius: 7px;
  }
}

@for $i from 1 through $loaderSteps+1 {
  .loader--#{$i} {
    li {
      &:nth-child(-n + #{$i}) {
        opacity: 1;

        &:before {
          opacity: 0;
        }

        &:after {
          opacity: 1;
        }
      }
      &:nth-child(#{$i}){
        &:before {
          opacity: 1;
        }
        &:after {
          opacity: 0;
        }
      }
    }
  }
}



.loader__notice {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 12px;
  top: 0;
  margin: 0;
  list-style: none;

  li {
    opacity: 0;
    position: absolute;
    transition-duration: 0.3s;
    @for $i from 0 through $loaderSteps {
      &:nth-child(#{$i}) {
        top: calc((100% / #{$loaderSteps} * (#{$i} - 1)) + (100% / #{$loaderSteps})/2 - 11px);
      }
    }

    &:before {
      content: '';
      width: 20px;
      height: 20px;
      background-image: url('../images/loader.svg');
      background-size: 20px;
      background-repeat: no-repeat;
      background-position: center;
      transition-duration: 0.3s;
      opacity: 1;
      display: inline-block;
      margin-left: 10px;
      position: absolute;
      right: 0;
    }

    &:after {
      content: '';
      width: 16px;
      height: 14px;
      background-image: url('../images/checkbox.svg');
      background-repeat: no-repeat;
      background-position: center;
      transition-duration: 0.8s;
      opacity: 0;
      display: inline-block;
      margin-left: 10px;
    }
  }
}
