.admin__article__inner {
    margin-bottom: 30px;
    @media (min-width: $screen-ts) {
        padding-left: 100px;
    }
    h3 {
        @include fs(18);
        font-weight: $fw-300;
    }
    p {
        //@include fs(15);
        font-weight: $fw-300;
        margin-top: 10px;
        color: $text-gray;
    }
}

.admin__inner__wrap {
    position: absolute;
    display: flex;
    height: 100%;
    width: 50%;
    top: 0;
    left: 30px;
    align-items: center;
}

.admin__inner__wrap__content {
    display: block;

    color: $white;
    h2 {
        font-weight: $fw-400;
        color: $white;
    }
    strong {
        margin-bottom: 20px;
        display: block;
    }
    p {
        font-weight: $fw-300;
        margin-bottom: 40px;
    }
}