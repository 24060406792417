.debug__notices {
	position: fixed;
	bottom: 0;
	left: 0;
	z-index: 999;
	cursor: pointer;
	width: 60px;
	height: 40px;
	background-color: #f00;
	&:before {
		@include fs(18);
		position: absolute;
		text-align: center;
		color: #fff;
		line-height: 40px;
		left: 0;
		width: 60px;
		top: 0;
		font-weight: 700;
		@media (max-width: $screen-is-max) {
			content: 'IS-1';
		}
		@media (min-width: $screen-xs) and (max-width: $screen-xs-max) {
			content: 'XS-2';
		}
		@media (min-width: $screen-xm) and (max-width: $screen-xm-max) {
			content: 'XM-3';
		}
		@media (min-width: $screen-ts) and (max-width: $screen-ts-max) {
			content: 'TS-4';
		}
		@media (min-width: $screen-sm) and (max-width: $screen-sm-max) {
			content: 'SM-5';
		}
		@media (min-width: $screen-md) and (max-width: $screen-md-max) {
			content: 'MD-6';
		}
		@media (min-width: $screen-lg) {
			content: 'LG-7';
		}
	}
}

.debug__login {
	padding: 0 10px;
	position: fixed;
	bottom: 0;
	left: 60px;
	z-index: 999;
	cursor: pointer;
	display: block;
	line-height: 40px;
	background-color: #000;
	text-decoration: none;
	color: #fff;
	&:hover {
		color: #fff;
		background-color: #5a5a5a;
	}
}