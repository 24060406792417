// classes
.block {
	display: block;
}

.none {
	display: none;
}

.inline-block {
	display: inline-block;
}

.inline {
	display: inline;
}

.center {
	margin-left: auto;
	margin-right: auto;
}

.relative {
	position: relative;
}

.absolute {
	position: absolute;
}

.static {
	position: static;
}

.clearfix {
	@include clearfix();
}

.cleaner {
	clear: both;
}

.cleaner-left {
	clear: left;
}

.cleaner-right {
	clear: right;
}

.strong {
	font-weight: $fw-700;
}

.stronger {
	font-weight: $fw-500;
}

.slim {
	font-weight: $fw-300;
}

.italic {
	font-style: italic;
}

.text-uppercase {
	text-transform: uppercase;
}

.rm--margin {
	margin: 0;
}

.spacer {
	position: relative;
	display: block;
}

.spacer--10 {
	padding-bottom: 10px;
	margin-bottom: 10px;
}

.spacer--20 {
	padding-bottom: 20px;
	margin-bottom: 20px;
}

.spacer--30 {
	padding-bottom: 30px;
	margin-bottom: 30px;
}

.spacer--top--30 {
	margin-top: 30px;
}

.small-note,
.small-note p {
	font-size: 13px;
	color: #333;
}

.small-note {
	a {
		text-decoration: none;

		&:hover {
			text-decoration: underline;
		}
	}

	h3 {
		font-size: inherit;
		margin: 0;
		font-weight: 600;
		color: black;
	}

	.checkbox input [type='checkbox'],
	.radio input [type='radio'] {
		vertical-align: -3px;
	}

	&.small-note--order {
		margin: 40px 0;
	}
}

.hidden {
	visibility: none;
}

.visible {
	visibility: visible;
}

.flex-middle {
	display: flex;
	align-items: center;
}

.mb0 {
	margin-bottom: 0;
}

@media (max-width: $screen-md-max) {
	.melt--to--md {
		padding-top: 0;
	}
}

.separator {
	margin-left: 10px;
	margin-right: 10px;
}

.pin {
	text-align: center;
	display: flex;
	justify-content: center;

	.pin__input {
		max-width: 48px;
		margin-left: 5px;
		margin-right: 5px;
		border-radius: 5px;
		padding: 0 5px;
		text-align: center;
		border: 1px solid $placeholder-gray;
	}
}

.pin-action {
	text-align: center;
	margin: 10px 0;
}

::-webkit-scrollbar {
	-webkit-appearance: none;
	width: 7px;
}

::-webkit-scrollbar-thumb {
	border-radius: 4px;
	background-color: rgba(0, 0, 0, 0.5);
	box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.no__mobile {
	@media (max-width: $screen-ts-max) {
		display: none;
	}
}

.btn.no__desktop,
.no__desktop {
	@media (min-width: $screen-sm) {
		display: none;
	}
}

/*
body::-webkit-scrollbar {
	-webkit-appearance: none;
    width: 1em;
}
body::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
}
*/

.mb-20 {
	margin-bottom: 20px;
}
