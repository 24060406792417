.list {
    padding: 0;
    margin: 0;    
    li {
        margin-top: 15px;
        margin-bottom: 15px;
    }
    .list__row {
        margin-left: -10px;
        margin-right: -10px;
        display: flex;
        flex-wrap: wrap;
    }
    .list__row--info {
        .list__action {
            @media (max-width: $screen-xs-max) {
                margin-top: 10px;
            }
        }
    }

    .list__col {
        padding-left: 10px;
        padding-right: 10px;
    }
    p {
        margin-bottom: 0;
    }
    &.align-center {
        div,
        li {
            align-items: center;
            &.list__row--info {
                align-items: flex-start;
            }
        }
    }
    &.list--nowrap {
        > .list__row {
            flex-wrap: nowrap;
        }
    }

}
.list__label {
    flex: 0 0 220px;
    /*
    font-size: 16px;
    */
    color: black;
    font-weight: 500;
    .list__label__sub {
        color: #84939f;
        font-weight: 400;
        font-size: 13px;
    }
}
.list__label__info {
    text-transform: lowercase;
}
.list__col--auto {
    flex: 1;
}
/*
.list__label--payment {
    flex: 0 0 180px;
}
*/
.info__box {
    .list__label {
        flex: 0 0 215px;
    }
}
.list-col {
    padding-left: 10px;
    padding-right: 10px;
}
.list__value {
    flex: 1;
    /*
    font-size: 15px;
    */
    padding-left: 10px;
    padding-right: 10px;
    strong {
        font-weight: 500;
    }
    &.list__value--number {
        .form__item {
            max-width: 100px;
            display: inline-block;
        }
    }
}
.list__value--cut {
    text-overflow: ellipsis;
    overflow: hidden;
}
.list__action {
    justify-content: space-between;
}
.list__action--icons {
    flex: 0 0 60px;
}
.list__action--icons--single {
    flex: 0 0 30px;
}
.list--services {
    .list__value {
        flex: 0 0 255px;
    }
    .list__label {
        flex: 0 0 320px;
    }
}
.list--payment-setup {
    label {
        font-weight: 400;
    }
    .active {
        label {
            font-weight: 500;
        }
    }
}
.footline {
    margin-top: 30px;
}
.footline__btn--left {
    margin-right: 40px;
}
.footline--contact {
    margin-top: 20px;
    margin-bottom: 60px;
}


.footline--activation {
    margin-top: 50px;
    display: flex;
    justify-content: space-between;
}

.footline--activation--1 {
    margin-top: 25px;
    justify-content: flex-end;
}

.list--inside {
    margin-left: -10px;
    margin-right: -10px;
}


.list__row--col {
    > div {
        @media (max-width: $screen-lg-max) and (min-width: $screen-md), (max-width: $screen-sm-max) and (min-width: $screen-sm), (max-width: $screen-ts-max) {
            flex-basis: 100%;
        }
    }
}
@media (max-width: $screen-ts-max) {
    .list--form {

    }
    .list__col--width-1 {
        flex-basis: 100%;
    }
}