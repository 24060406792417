.panel {
    background-color: white;
    max-height: 0;
    transition: max-height 0.2s ease-out;
    &:not(.panel--open) {
        overflow: hidden;
    }
}
.panel--open {
    max-height: none;
}

/*
.address-form {
    .panel {
        padding-top: 32px;
        margin-top: -32px;
    }
}
*/