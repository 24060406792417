.digi-table {
	width: 100%;
	border-collapse: collapse;

	.cell__label {
		display: none;
	}

	thead {
		td,
		th {
			background-color: $blue-primary;
			color: white;
		}
	}

	td,
	th {
		font-weight: 500;
	}

	.cell {
		padding: 15px;

		&:after {
			visibility: hidden;
			display: block;
			font-size: 0;
			content: " ";
			clear: both;
			height: 0;
		}
	}

	.cell__label {
		padding-right: 10px;
	}

	.even {
		td,
		th {
			background-color: #f2f2f2;
		}
	}

	.payment-note {
		display: inline-block;
		font-weight: 300;
	}

	.payment-issue {
		margin-right: 20px;
	}

	@media (max-width: $screen-sm-max + 110px) and (min-width: $screen-sm), (max-width: $screen-ts-max) {
		thead {
			display: none;
		}
		td,
		th {
			display: block;
			clear: left;
			float: left;
			width: 100%;
		}
		.cell__label {
			display: block;
		}
		.cell__label,
		.cell__content {
			float: left;
			width: 50%;
		}
	}
}

.digi-table--invoices {
	p {
		margin-bottom: 0;
	}

	tr {
		td,
		th {
			&:last-child {
				text-align: right;
			}
		}
	}
}

.order__total {
	margin-top: 50px;
	width: 100%;
	border: 1px solid $border-gray-light;

	th,
	td {
		&:first-child {
			text-align: left;
		}

		&:last-child {
			text-align: right;
		}

		&.order__total__text {

			text-align: left;
		}
	}

	.order__total__row {
		td {
			&.order__total__text {
				border-bottom: 1px solid #c8c8c8;
			}
		}
	}

	thead {
		th {
			background-color: $blue-primary;
			padding: 30px 35px;
			color: $white;
			@media (max-width: $screen-sm-max) {
				padding: 15px 15px;
			}
		}
	}

	.order__total__title {
		color: $white;
		margin-bottom: 0;
		@media (max-width: $screen-sm-max) {
			@include fs(18);
		}
	}

	.order__total__row {
		position: relative;

		td {
			padding: 15px 35px;
			border-bottom: 1px solid $silver;
			@media (max-width: $screen-sm-max) {
				padding: 10px 15px;
			}

		}

		&:first-child {
			td {
				padding-top: 40px;
			}
		}
	}

	.order__total__row--gray {
		background-color: #f1f0f0;
	}


	.order__total__row__name {
		@include fs(18);
		color: $blue-primary;
	}

	td.order__total__row__one-time-payment {
		@include fs(18);
		font-weight: 700;
		color: $white;
		background-color: $blue-primary;
		text-align: left;
	}

	td.order__total__row__name--big {
		font-weight: 700;
		color: $black;
		border-bottom: 1px solid #c8c8c8;
	}

	td.order__total__row__name--final {
		font-weight: 700;
		@include fs(24);
		border-bottom: 0;
		padding-top: 40px;
		@media (max-width: $screen-xm-max) {
			@include fs(22);
		}

		span {
			text-decoration: underline;
			cursor: pointer;
			@include fs(16);
			font-weight: 400;
			color: $text-gray;
			@media (max-width: $screen-xm-max) {
				@include fs(14);
				color: $text-gray;
			}
		}

		p {
			color: $blue-primary;
			margin: 0;
		}
	}

	.order__total__row__price__outer {
		div {
			display: inline-block;
		}
	}

	.order__total__row__price {
		@include fs(18);
		font-weight: 700;
		color: $black;
	}

	.order__total__row__discount {
		color: $blue-primary;
		@include fs(18);
		font-weight: 700;
		margin-left: 9px;
		white-space: nowrap;
	}

	.order__total__row__price--discounted {
		text-decoration: line-through;
	}

	td.order__total__row__price--big {
		border-bottom: 1px solid #c8c8c8;
		@include fs(24);
		@media (max-width: $screen-xm-max) {
			@include fs(22);
		}
	}

	td.order__total__row__price--final {
		@include fs(32);
		color: $blue-primary;
		border-bottom: 0;
		padding-bottom: 0;
		@media (max-width: $screen-xm-max) {
			@include fs(26);
		}
	}


}

.order__total__loyalty {
	.order__total__row:last-of-type {
		background-color: $border-gray;
		.order__total__row__name.order__total__row__name--big,
		.order__total__row__price.order__total__row__price--big {
			color: $blue-primary;
			@include fs(24);
		}
	}
}


.order__total__more__info {
	text-decoration: underline;
	cursor: pointer;
	@include fs(16);
	font-weight: 400;
	color: $text-gray;
	@media (max-width: $screen-xm-max) {
		@include fs(14);
		color: $text-gray;
	}
}

.order__total__text__inner {
	display: inline;
	> p {
		display: inline;
	}
}

.order__total__parent__more__info {
	display: inline;
	> span {
		@include fs(13);
	}
}

/*
.digi-table {
    padding-left: 1rem;
    padding-right: 1rem;
}
.digi-table__row--header {
    background-color: $blue-primary;
    color: white;
    p {
        color: white;
    }
}
.digi-table__row {
    margin-right: -1rem;
    margin-left: -1rem;
    border-radius: 6px;
    display: flex;
    p {
        margin-bottom: 6px;
    }
}
.digi-table__col {
    padding-right: 1rem;
    padding-left: 1rem;
}

.digi-table--invoice {

    .digi-table__row {
        flex-wrap: wrap;
    }
    .digi-table__col {
        flex-basis: 100%;
    }

    .col--1,
    .col--2,
    .col--3,
    .col--4 {
        padding-left: 50%;
        position: relative;
        &:before {
            content: "oooxxxxxxxxxxrrr";
            position: absolute;
            right: 50%;
            top: 0;
            padding-right: 15px;
            color: black;
        }
    }

}
*/


/*
.row,
.digi-table__row {
    &.even {
        background-color: #f2f2f2;
    }
}
.digi-table__row {
    padding-right: 25px;
    padding-left: 25px;
    margin-bottom: 20px;
    border-radius: 6px;
    p {
        margin-bottom: 6px;
    }
}
.digi-table__row--header {
    background-color: $blue-primary;
    color: white;
    p {
        color: white;
    }
}
.digi-table__row__multi {
    display: flex;
    .date {
        flex: 0 0 85px;
    }
    .payment-issue {
        flex: 0 0 100px;
    }
    .payment-note {
        font-weight: 300;
    }
}
.digi-table--adresses {
    .digi-table__row {
        margin-bottom: 0;
        padding-top: 10px;
        padding-bottom: 10px;
    }
    label {
        width: 100%;
    }
}
*/
