.validation--error {
    border: 1px solid $validation-text-alert;
    &:after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        background-color: yellow;
        width: 100%;
        height: 100%;
    }
}

.validation--message {
    position: absolute;
    left: 0;
    bottom: calc(100% + 6px);
    border-radius: 4px;
    color: $validation-text-alert;
    font-weight: $fw-600;
    padding: 5px 10px;
    background-color: $validataion-background-alert;
    @include fs(14);
    &:before {
        content: '';
        position: absolute;
        left: 20px;
        bottom: -5px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 5px 5px 0 5px;
        border-color: #ffc2ba transparent transparent transparent;
    }
}

.validation--block {
    font-weight: $fw-600;
    padding: 5px 10px;
    border-radius: 4px;
    h3 {
        color: inherit;
        margin: 10px 0;
    }
}

.alert {
    background-color: $validataion-background-alert;
    color: $validation-text-alert;
    &.alert--full {
        width: 100%;
    }
}
.success {
    background-color: $validataion-background-success;
    color: $validation-text-success;
}
.info {
    background-color: $validataion-background-info;
    color: $validation-text-info;
}
.flash {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 9991;
    transition: transform 0.5s ease-in-out;
    transform: translate(0, -100%);
    .button-close {
        color: white;
        top: 20px;
        right: 20px;
        &:after {
            background-image: url(../images/ico-close-white.svg);
        }
    }
    .alert {
        position: relative;
        margin-bottom: 5px;
        padding: 20px 10px;
        color: white;
        h2 {
            color: inherit;
            margin-bottom: 5px;
            margin-top: 15px;
            font-size: 18px;
            font-weight: 500;
        }
        ul {
            margin-top: 0;
            margin-bottom: 0;
            padding-left: 18px;
        }
    }
}
.flash-down {
    transform: translate(0, 0);
    margin-top: 0;
}
.alert--error {
    background-color: $alert--error;
    color: white;
    box-shadow: 0 2px 2px 0 rgba(122, 0, 0, 1);
}
.alert--success {
    background-color: $alert--success;
    color: white;
    box-shadow: 0 2px 2px 0 rgb(7, 151, 38);
}
.alert--warning {
    background-color: $alert--warning;
    color: white;
    box-shadow: 0 2px 2px 0 rgba(122, 0, 0, 1);
}
.alert--info {
    background-color: $alert--info;
    color: white;
    box-shadow: 0 2px 2px 0 rgba(122, 0, 0, 1);
}
.check {
    background-repeat: no-repeat;
    background-position: center center;
    height: 150px;
    margin: 15px 0;
}
.check--success {
    background-image: url(../images/check.svg);
}