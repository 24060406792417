.message-wrapper {
  display: flex;
  justify-content: center;
}

div.message-block {
  max-width: 507px;
  padding: 46px;
  // background-color: $blue-background;
  @media (max-width: $screen-lg-max) {
    padding: 16px;
  }
  @media (max-width: $screen-sm-max) {
      display: none;
  }

  h1 {
    font-weight: 700;
    color: $blue-primary;
    @include fs(32);
    padding-right: 0;
    @media (max-width: $screen-lg-max) {
      @include fs(24);
    }
  }

  p {
    @include fs(28);
    font-weight: 700;
    @media (max-width: $screen-lg-max) {
      @include fs(20);
    }
  }
}
