@charset "UTF-8";
.Toastify__toast-container {
  z-index: 9999;
  -webkit-transform: translate3d(0, 0, 9999px);
  position: fixed;
  padding: 4px;
  width: 100%;
  box-sizing: border-box;
  color: #fff; }
  .Toastify__toast-container--top-left {
    top: 1em;
    left: 1em; }
  .Toastify__toast-container--top-center {
    top: 1em;
    left: 50%;
    margin-left: -50%; }
  .Toastify__toast-container--top-right {
    top: 1em;
    right: 1em; }
  .Toastify__toast-container--bottom-left {
    bottom: 1em;
    left: 1em; }
  .Toastify__toast-container--bottom-center {
    bottom: 1em;
    left: 50%;
    margin-left: -50%; }
  .Toastify__toast-container--bottom-right {
    bottom: 1em;
    right: 1em; }

@media only screen and (max-width: 480px) {
  .Toastify__toast-container {
    width: 100vw;
    padding: 0;
    left: 0;
    margin: 0; }
    .Toastify__toast-container--top-left, .Toastify__toast-container--top-center, .Toastify__toast-container--top-right {
      top: 0; }
    .Toastify__toast-container--bottom-left, .Toastify__toast-container--bottom-center, .Toastify__toast-container--bottom-right {
      bottom: 0; }
    .Toastify__toast-container--rtl {
      right: 0;
      left: initial; } }

.Toastify__toast {
  position: relative;
  min-height: 64px;
  box-sizing: border-box;
  margin-bottom: 1rem;
  padding: 8px;
  border-radius: 1px;
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1), 0 2px 15px 0 rgba(0, 0, 0, 0.05);
  display: flex;
  justify-content: space-between;
  max-height: 800px;
  overflow: hidden;
  font-family: sans-serif;
  cursor: pointer;
  direction: ltr; }
  .Toastify__toast--rtl {
    direction: rtl; }
  .Toastify__toast--default {
    background: #fff;
    color: #aaa; }
  .Toastify__toast--info {
    background: #3498db; }
  .Toastify__toast--success {
    background: #07bc0c; }
  .Toastify__toast--warning {
    background: #f1c40f; }
  .Toastify__toast--error {
    background: #d61400; }
  .Toastify__toast-body {
    margin: auto 0;
    flex: 1; }

@media only screen and (max-width: 480px) {
  .Toastify__toast {
    margin-bottom: 0; } }

.Toastify__close-button {
  color: #fff;
  font-weight: bold;
  font-size: 14px;
  background: transparent;
  outline: none;
  border: none;
  padding: 0;
  cursor: pointer;
  opacity: 0.7;
  transition: 0.3s ease;
  align-self: flex-start; }
  .Toastify__close-button--default {
    color: #000;
    opacity: 0.3; }
  .Toastify__close-button:hover, .Toastify__close-button:focus {
    opacity: 1; }

@keyframes Toastify__trackProgress {
  0% {
    transform: scaleX(1); }
  100% {
    transform: scaleX(0); } }

.Toastify__progress-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 5px;
  z-index: 9999;
  opacity: 0.7;
  background-color: rgba(255, 255, 255, 0.7);
  transform-origin: left; }
  .Toastify__progress-bar--animated {
    animation: Toastify__trackProgress linear 1 forwards; }
  .Toastify__progress-bar--controlled {
    transition: transform .2s; }
  .Toastify__progress-bar--rtl {
    right: 0;
    left: initial;
    transform-origin: right; }
  .Toastify__progress-bar--default {
    background: linear-gradient(to right, #4cd964, #5ac8fa, #007aff, #34aadc, #5856d6, #ff2d55); }

@keyframes Toastify__bounceInRight {
  from,
  60%,
  75%,
  90%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    transform: translate3d(3000px, 0, 0); }
  60% {
    opacity: 1;
    transform: translate3d(-25px, 0, 0); }
  75% {
    transform: translate3d(10px, 0, 0); }
  90% {
    transform: translate3d(-5px, 0, 0); }
  to {
    transform: none; } }

@keyframes Toastify__bounceOutRight {
  20% {
    opacity: 1;
    transform: translate3d(-20px, 0, 0); }
  to {
    opacity: 0;
    transform: translate3d(2000px, 0, 0); } }

@keyframes Toastify__bounceInLeft {
  from,
  60%,
  75%,
  90%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    transform: translate3d(-3000px, 0, 0); }
  60% {
    opacity: 1;
    transform: translate3d(25px, 0, 0); }
  75% {
    transform: translate3d(-10px, 0, 0); }
  90% {
    transform: translate3d(5px, 0, 0); }
  to {
    transform: none; } }

@keyframes Toastify__bounceOutLeft {
  20% {
    opacity: 1;
    transform: translate3d(20px, 0, 0); }
  to {
    opacity: 0;
    transform: translate3d(-2000px, 0, 0); } }

@keyframes Toastify__bounceInUp {
  from,
  60%,
  75%,
  90%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    transform: translate3d(0, 3000px, 0); }
  60% {
    opacity: 1;
    transform: translate3d(0, -20px, 0); }
  75% {
    transform: translate3d(0, 10px, 0); }
  90% {
    transform: translate3d(0, -5px, 0); }
  to {
    transform: translate3d(0, 0, 0); } }

@keyframes Toastify__bounceOutUp {
  20% {
    transform: translate3d(0, -10px, 0); }
  40%,
  45% {
    opacity: 1;
    transform: translate3d(0, 20px, 0); }
  to {
    opacity: 0;
    transform: translate3d(0, -2000px, 0); } }

@keyframes Toastify__bounceInDown {
  from,
  60%,
  75%,
  90%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    transform: translate3d(0, -3000px, 0); }
  60% {
    opacity: 1;
    transform: translate3d(0, 25px, 0); }
  75% {
    transform: translate3d(0, -10px, 0); }
  90% {
    transform: translate3d(0, 5px, 0); }
  to {
    transform: none; } }

@keyframes Toastify__bounceOutDown {
  20% {
    transform: translate3d(0, 10px, 0); }
  40%,
  45% {
    opacity: 1;
    transform: translate3d(0, -20px, 0); }
  to {
    opacity: 0;
    transform: translate3d(0, 2000px, 0); } }

.Toastify__bounce-enter--top-left, .Toastify__bounce-enter--bottom-left {
  animation-name: Toastify__bounceInLeft; }

.Toastify__bounce-enter--top-right, .Toastify__bounce-enter--bottom-right {
  animation-name: Toastify__bounceInRight; }

.Toastify__bounce-enter--top-center {
  animation-name: Toastify__bounceInDown; }

.Toastify__bounce-enter--bottom-center {
  animation-name: Toastify__bounceInUp; }

.Toastify__bounce-exit--top-left, .Toastify__bounce-exit--bottom-left {
  animation-name: Toastify__bounceOutLeft; }

.Toastify__bounce-exit--top-right, .Toastify__bounce-exit--bottom-right {
  animation-name: Toastify__bounceOutRight; }

.Toastify__bounce-exit--top-center {
  animation-name: Toastify__bounceOutUp; }

.Toastify__bounce-exit--bottom-center {
  animation-name: Toastify__bounceOutDown; }

@keyframes Toastify__zoomIn {
  from {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3); }
  50% {
    opacity: 1; } }

@keyframes Toastify__zoomOut {
  from {
    opacity: 1; }
  50% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3); }
  to {
    opacity: 0; } }

.Toastify__zoom-enter {
  animation-name: Toastify__zoomIn; }

.Toastify__zoom-exit {
  animation-name: Toastify__zoomOut; }

@keyframes Toastify__flipIn {
  from {
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    animation-timing-function: ease-in;
    opacity: 0; }
  40% {
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    animation-timing-function: ease-in; }
  60% {
    transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    opacity: 1; }
  80% {
    transform: perspective(400px) rotate3d(1, 0, 0, -5deg); }
  to {
    transform: perspective(400px); } }

@keyframes Toastify__flipOut {
  from {
    transform: perspective(400px); }
  30% {
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    opacity: 1; }
  to {
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    opacity: 0; } }

.Toastify__flip-enter {
  animation-name: Toastify__flipIn; }

.Toastify__flip-exit {
  animation-name: Toastify__flipOut; }

@keyframes Toastify__slideInRight {
  from {
    transform: translate3d(110%, 0, 0);
    visibility: visible; }
  to {
    transform: translate3d(0, 0, 0); } }

@keyframes Toastify__slideInLeft {
  from {
    transform: translate3d(-110%, 0, 0);
    visibility: visible; }
  to {
    transform: translate3d(0, 0, 0); } }

@keyframes Toastify__slideInUp {
  from {
    transform: translate3d(0, 110%, 0);
    visibility: visible; }
  to {
    transform: translate3d(0, 0, 0); } }

@keyframes Toastify__slideInDown {
  from {
    transform: translate3d(0, -110%, 0);
    visibility: visible; }
  to {
    transform: translate3d(0, 0, 0); } }

@keyframes Toastify__slideOutRight {
  from {
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    transform: translate3d(110%, 0, 0); } }

@keyframes Toastify__slideOutLeft {
  from {
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    transform: translate3d(-110%, 0, 0); } }

@keyframes Toastify__slideOutDown {
  from {
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    transform: translate3d(0, 500px, 0); } }

@keyframes Toastify__slideOutUp {
  from {
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    transform: translate3d(0, -500px, 0); } }

.Toastify__slide-enter--top-left, .Toastify__slide-enter--bottom-left {
  animation-name: Toastify__slideInLeft; }

.Toastify__slide-enter--top-right, .Toastify__slide-enter--bottom-right {
  animation-name: Toastify__slideInRight; }

.Toastify__slide-enter--top-center {
  animation-name: Toastify__slideInDown; }

.Toastify__slide-enter--bottom-center {
  animation-name: Toastify__slideInUp; }

.Toastify__slide-exit--top-left, .Toastify__slide-exit--bottom-left {
  animation-name: Toastify__slideOutLeft; }

.Toastify__slide-exit--top-right, .Toastify__slide-exit--bottom-right {
  animation-name: Toastify__slideOutRight; }

.Toastify__slide-exit--top-center {
  animation-name: Toastify__slideOutUp; }

.Toastify__slide-exit--bottom-center {
  animation-name: Toastify__slideOutDown; }

/* Import theme styles */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
nav,
section,
summary {
  display: block; }

audio,
canvas,
video {
  display: inline-block; }

audio:not([controls]) {
  display: none;
  height: 0; }

[hidden] {
  display: none; }

html {
  font-family: "neue-haas-grotesk-display", sans-serif;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%; }

body {
  margin: 0; }

a:focus {
  outline: thin dotted; }

a:active,
a:hover {
  outline: 0; }

abbr[title] {
  border-bottom: 1px dotted; }

b,
strong {
  font-weight: bold; }

dfn {
  font-style: italic; }

mark {
  background: #ff0;
  color: #000; }

code,
kbd,
pre,
samp {
  font-family: "neue-haas-grotesk-display", monospace, serif;
  font-size: 1em; }

pre {
  white-space: pre;
  white-space: pre-wrap;
  word-wrap: break-word; }

q {
  quotes: "\201C" "\201D" "\2018" "\2019"; }

small {
  font-size: 80%; }

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

img {
  border: 0; }

svg:not(:root) {
  overflow: hidden; }

figure {
  margin: 0; }

fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

legend {
  border: 0;
  padding: 0; }

button,
input,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  margin: 0; }

button,
input {
  line-height: normal; }

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer; }

button[disabled],
input[disabled] {
  cursor: default; }

input[type="checkbox"],
input[type="radio"] {
  padding: 0;
  /* 2 */ }

input[type="search"] {
  -webkit-appearance: textfield;
  box-sizing: content-box; }

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0; }

textarea {
  overflow: auto;
  vertical-align: top; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

* {
  box-sizing: border-box; }

.col {
  flex: 0 0 100%;
  max-width: 100%;
  padding: 1rem 1rem 0 1rem; }
  .col--ng {
    padding: 0; }
  @media (min-width: 1180px) {
    .col--big {
      padding: 1rem 1rem 0 1rem; } }
  .col--banner {
    flex: 0 0 calc(160px + 2rem);
    padding: 1rem 1rem 0 1rem; }

.grid {
  padding: 0 1rem; }
  .grid--container {
    margin: 0 auto;
    max-width: 1200px;
    width: 100%; }
  .grid--ng {
    padding: 0; }
  @media (min-width: 1180px) {
    .grid--big {
      padding-left: 2rem;
      padding-right: 2rem; } }

.row {
  display: flex;
  flex: 0 1 100%;
  flex-direction: row;
  flex-wrap: wrap;
  list-style: none;
  margin: -1rem -1rem 1rem -1rem;
  padding: 0; }
  .row--ng {
    margin: 0; }
  @media (min-width: 1180px) {
    .row--big {
      margin: -1rem -1rem 1rem -1rem; } }

.text--left {
  text-align: left; }

.text--center {
  text-align: center; }

.text--right {
  text-align: right; }

@media (min-width: 320px) {
  .row--is--top {
    align-items: flex-start; }
  .row--is--center {
    align-items: center; }
  .row--is--bottom {
    align-items: flex-end; }
  .col--is--align-top {
    align-self: flex-start; }
  .col--is--align-center {
    align-self: center; }
  .col--is--align-bottom {
    align-self: flex-end; }
  .col--is--first {
    order: -1; }
  .col--is--last {
    order: 1; }
  .col--is--reset {
    order: 0; }
  .row--is--reverse {
    flex-direction: row-reverse; }
  .col--is--flex {
    display: flex; }
  .text--is--right {
    text-align: right; }
  .text--is--left {
    text-align: left; }
  .text--is--center {
    text-align: center; }
  .col--is {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
    padding: 1rem 1rem 0 1rem; }
  .col--is--1 {
    width: 8.33333%;
    flex: none; }
  .col--is--2 {
    width: 16.66667%;
    flex: none; }
  .col--is--3 {
    width: 25%;
    flex: none; }
  .col--is--4 {
    width: 33.33333%;
    flex: none; }
  .col--is--5 {
    width: 41.66667%;
    flex: none; }
  .col--is--6 {
    width: 50%;
    flex: none; }
  .col--is--7 {
    width: 58.33333%;
    flex: none; }
  .col--is--8 {
    width: 66.66667%;
    flex: none; }
  .col--is--9 {
    width: 75%;
    flex: none; }
  .col--is--10 {
    width: 83.33333%;
    flex: none; }
  .col--is--11 {
    width: 91.66667%;
    flex: none; }
  .col--is--12 {
    width: 100%;
    flex: none; }
  .col--is--offset--1 {
    margin-left: 8.33333%; }
  .col--is--offset--2 {
    margin-left: 16.66667%; }
  .col--is--offset--3 {
    margin-left: 25%; }
  .col--is--offset--4 {
    margin-left: 33.33333%; }
  .col--is--offset--5 {
    margin-left: 41.66667%; }
  .col--is--offset--6 {
    margin-left: 50%; }
  .col--is--offset--7 {
    margin-left: 58.33333%; }
  .col--is--offset--8 {
    margin-left: 66.66667%; }
  .col--is--offset--9 {
    margin-left: 75%; }
  .col--is--offset--10 {
    margin-left: 83.33333%; }
  .col--is--offset--11 {
    margin-left: 91.66667%; }
  .col--is--offset--12 {
    margin-left: 100%; } }

@media (min-width: 360px) {
  .row--xs--top {
    align-items: flex-start; }
  .row--xs--center {
    align-items: center; }
  .row--xs--bottom {
    align-items: flex-end; }
  .col--xs--align-top {
    align-self: flex-start; }
  .col--xs--align-center {
    align-self: center; }
  .col--xs--align-bottom {
    align-self: flex-end; }
  .col--xs--first {
    order: -1; }
  .col--xs--last {
    order: 1; }
  .col--xs--reset {
    order: 0; }
  .row--xs--reverse {
    flex-direction: row-reverse; }
  .col--xs--flex {
    display: flex; }
  .text--xs--right {
    text-align: right; }
  .text--xs--left {
    text-align: left; }
  .text--xs--center {
    text-align: center; }
  .col--xs {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
    padding: 1rem 1rem 0 1rem; }
  .col--xs--1 {
    width: 8.33333%;
    flex: none; }
  .col--xs--2 {
    width: 16.66667%;
    flex: none; }
  .col--xs--3 {
    width: 25%;
    flex: none; }
  .col--xs--4 {
    width: 33.33333%;
    flex: none; }
  .col--xs--5 {
    width: 41.66667%;
    flex: none; }
  .col--xs--6 {
    width: 50%;
    flex: none; }
  .col--xs--7 {
    width: 58.33333%;
    flex: none; }
  .col--xs--8 {
    width: 66.66667%;
    flex: none; }
  .col--xs--9 {
    width: 75%;
    flex: none; }
  .col--xs--10 {
    width: 83.33333%;
    flex: none; }
  .col--xs--11 {
    width: 91.66667%;
    flex: none; }
  .col--xs--12 {
    width: 100%;
    flex: none; }
  .col--xs--offset--1 {
    margin-left: 8.33333%; }
  .col--xs--offset--2 {
    margin-left: 16.66667%; }
  .col--xs--offset--3 {
    margin-left: 25%; }
  .col--xs--offset--4 {
    margin-left: 33.33333%; }
  .col--xs--offset--5 {
    margin-left: 41.66667%; }
  .col--xs--offset--6 {
    margin-left: 50%; }
  .col--xs--offset--7 {
    margin-left: 58.33333%; }
  .col--xs--offset--8 {
    margin-left: 66.66667%; }
  .col--xs--offset--9 {
    margin-left: 75%; }
  .col--xs--offset--10 {
    margin-left: 83.33333%; }
  .col--xs--offset--11 {
    margin-left: 91.66667%; }
  .col--xs--offset--12 {
    margin-left: 100%; } }

@media (min-width: 480px) {
  .row--xm--top {
    align-items: flex-start; }
  .row--xm--center {
    align-items: center; }
  .row--xm--bottom {
    align-items: flex-end; }
  .col--xm--align-top {
    align-self: flex-start; }
  .col--xm--align-center {
    align-self: center; }
  .col--xm--align-bottom {
    align-self: flex-end; }
  .col--xm--first {
    order: -1; }
  .col--xm--last {
    order: 1; }
  .col--xm--reset {
    order: 0; }
  .row--xm--reverse {
    flex-direction: row-reverse; }
  .col--xm--flex {
    display: flex; }
  .text--xm--right {
    text-align: right; }
  .text--xm--left {
    text-align: left; }
  .text--xm--center {
    text-align: center; }
  .col--xm {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
    padding: 1rem 1rem 0 1rem; }
  .col--xm--1 {
    width: 8.33333%;
    flex: none; }
  .col--xm--2 {
    width: 16.66667%;
    flex: none; }
  .col--xm--3 {
    width: 25%;
    flex: none; }
  .col--xm--4 {
    width: 33.33333%;
    flex: none; }
  .col--xm--5 {
    width: 41.66667%;
    flex: none; }
  .col--xm--6 {
    width: 50%;
    flex: none; }
  .col--xm--7 {
    width: 58.33333%;
    flex: none; }
  .col--xm--8 {
    width: 66.66667%;
    flex: none; }
  .col--xm--9 {
    width: 75%;
    flex: none; }
  .col--xm--10 {
    width: 83.33333%;
    flex: none; }
  .col--xm--11 {
    width: 91.66667%;
    flex: none; }
  .col--xm--12 {
    width: 100%;
    flex: none; }
  .col--xm--offset--1 {
    margin-left: 8.33333%; }
  .col--xm--offset--2 {
    margin-left: 16.66667%; }
  .col--xm--offset--3 {
    margin-left: 25%; }
  .col--xm--offset--4 {
    margin-left: 33.33333%; }
  .col--xm--offset--5 {
    margin-left: 41.66667%; }
  .col--xm--offset--6 {
    margin-left: 50%; }
  .col--xm--offset--7 {
    margin-left: 58.33333%; }
  .col--xm--offset--8 {
    margin-left: 66.66667%; }
  .col--xm--offset--9 {
    margin-left: 75%; }
  .col--xm--offset--10 {
    margin-left: 83.33333%; }
  .col--xm--offset--11 {
    margin-left: 91.66667%; }
  .col--xm--offset--12 {
    margin-left: 100%; } }

@media (min-width: 580px) {
  .row--ts--top {
    align-items: flex-start; }
  .row--ts--center {
    align-items: center; }
  .row--ts--bottom {
    align-items: flex-end; }
  .col--ts--align-top {
    align-self: flex-start; }
  .col--ts--align-center {
    align-self: center; }
  .col--ts--align-bottom {
    align-self: flex-end; }
  .col--ts--first {
    order: -1; }
  .col--ts--last {
    order: 1; }
  .col--ts--reset {
    order: 0; }
  .row--ts--reverse {
    flex-direction: row-reverse; }
  .col--ts--flex {
    display: flex; }
  .text--ts--right {
    text-align: right; }
  .text--ts--left {
    text-align: left; }
  .text--ts--center {
    text-align: center; }
  .col--ts {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
    padding: 1rem 1rem 0 1rem; }
  .col--ts--1 {
    width: 8.33333%;
    flex: none; }
  .col--ts--2 {
    width: 16.66667%;
    flex: none; }
  .col--ts--3 {
    width: 25%;
    flex: none; }
  .col--ts--4 {
    width: 33.33333%;
    flex: none; }
  .col--ts--5 {
    width: 41.66667%;
    flex: none; }
  .col--ts--6 {
    width: 50%;
    flex: none; }
  .col--ts--7 {
    width: 58.33333%;
    flex: none; }
  .col--ts--8 {
    width: 66.66667%;
    flex: none; }
  .col--ts--9 {
    width: 75%;
    flex: none; }
  .col--ts--10 {
    width: 83.33333%;
    flex: none; }
  .col--ts--11 {
    width: 91.66667%;
    flex: none; }
  .col--ts--12 {
    width: 100%;
    flex: none; }
  .col--ts--offset--1 {
    margin-left: 8.33333%; }
  .col--ts--offset--2 {
    margin-left: 16.66667%; }
  .col--ts--offset--3 {
    margin-left: 25%; }
  .col--ts--offset--4 {
    margin-left: 33.33333%; }
  .col--ts--offset--5 {
    margin-left: 41.66667%; }
  .col--ts--offset--6 {
    margin-left: 50%; }
  .col--ts--offset--7 {
    margin-left: 58.33333%; }
  .col--ts--offset--8 {
    margin-left: 66.66667%; }
  .col--ts--offset--9 {
    margin-left: 75%; }
  .col--ts--offset--10 {
    margin-left: 83.33333%; }
  .col--ts--offset--11 {
    margin-left: 91.66667%; }
  .col--ts--offset--12 {
    margin-left: 100%; } }

@media (min-width: 768px) {
  .row--sm--top {
    align-items: flex-start; }
  .row--sm--center {
    align-items: center; }
  .row--sm--bottom {
    align-items: flex-end; }
  .col--sm--align-top {
    align-self: flex-start; }
  .col--sm--align-center {
    align-self: center; }
  .col--sm--align-bottom {
    align-self: flex-end; }
  .col--sm--first {
    order: -1; }
  .col--sm--last {
    order: 1; }
  .col--sm--reset {
    order: 0; }
  .row--sm--reverse {
    flex-direction: row-reverse; }
  .col--sm--flex {
    display: flex; }
  .text--sm--right {
    text-align: right; }
  .text--sm--left {
    text-align: left; }
  .text--sm--center {
    text-align: center; }
  .col--sm {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
    padding: 1rem 1rem 0 1rem; }
  .col--sm--1 {
    width: 8.33333%;
    flex: none; }
  .col--sm--2 {
    width: 16.66667%;
    flex: none; }
  .col--sm--3 {
    width: 25%;
    flex: none; }
  .col--sm--4 {
    width: 33.33333%;
    flex: none; }
  .col--sm--5 {
    width: 41.66667%;
    flex: none; }
  .col--sm--6 {
    width: 50%;
    flex: none; }
  .col--sm--7 {
    width: 58.33333%;
    flex: none; }
  .col--sm--8 {
    width: 66.66667%;
    flex: none; }
  .col--sm--9 {
    width: 75%;
    flex: none; }
  .col--sm--10 {
    width: 83.33333%;
    flex: none; }
  .col--sm--11 {
    width: 91.66667%;
    flex: none; }
  .col--sm--12 {
    width: 100%;
    flex: none; }
  .col--sm--offset--1 {
    margin-left: 8.33333%; }
  .col--sm--offset--2 {
    margin-left: 16.66667%; }
  .col--sm--offset--3 {
    margin-left: 25%; }
  .col--sm--offset--4 {
    margin-left: 33.33333%; }
  .col--sm--offset--5 {
    margin-left: 41.66667%; }
  .col--sm--offset--6 {
    margin-left: 50%; }
  .col--sm--offset--7 {
    margin-left: 58.33333%; }
  .col--sm--offset--8 {
    margin-left: 66.66667%; }
  .col--sm--offset--9 {
    margin-left: 75%; }
  .col--sm--offset--10 {
    margin-left: 83.33333%; }
  .col--sm--offset--11 {
    margin-left: 91.66667%; }
  .col--sm--offset--12 {
    margin-left: 100%; } }

@media (min-width: 992px) {
  .row--md--top {
    align-items: flex-start; }
  .row--md--center {
    align-items: center; }
  .row--md--bottom {
    align-items: flex-end; }
  .col--md--align-top {
    align-self: flex-start; }
  .col--md--align-center {
    align-self: center; }
  .col--md--align-bottom {
    align-self: flex-end; }
  .col--md--first {
    order: -1; }
  .col--md--last {
    order: 1; }
  .col--md--reset {
    order: 0; }
  .row--md--reverse {
    flex-direction: row-reverse; }
  .col--md--flex {
    display: flex; }
  .text--md--right {
    text-align: right; }
  .text--md--left {
    text-align: left; }
  .text--md--center {
    text-align: center; }
  .col--md {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
    padding: 1rem 1rem 0 1rem; }
  .col--md--1 {
    width: 8.33333%;
    flex: none; }
  .col--md--2 {
    width: 16.66667%;
    flex: none; }
  .col--md--3 {
    width: 25%;
    flex: none; }
  .col--md--4 {
    width: 33.33333%;
    flex: none; }
  .col--md--5 {
    width: 41.66667%;
    flex: none; }
  .col--md--6 {
    width: 50%;
    flex: none; }
  .col--md--7 {
    width: 58.33333%;
    flex: none; }
  .col--md--8 {
    width: 66.66667%;
    flex: none; }
  .col--md--9 {
    width: 75%;
    flex: none; }
  .col--md--10 {
    width: 83.33333%;
    flex: none; }
  .col--md--11 {
    width: 91.66667%;
    flex: none; }
  .col--md--12 {
    width: 100%;
    flex: none; }
  .col--md--offset--1 {
    margin-left: 8.33333%; }
  .col--md--offset--2 {
    margin-left: 16.66667%; }
  .col--md--offset--3 {
    margin-left: 25%; }
  .col--md--offset--4 {
    margin-left: 33.33333%; }
  .col--md--offset--5 {
    margin-left: 41.66667%; }
  .col--md--offset--6 {
    margin-left: 50%; }
  .col--md--offset--7 {
    margin-left: 58.33333%; }
  .col--md--offset--8 {
    margin-left: 66.66667%; }
  .col--md--offset--9 {
    margin-left: 75%; }
  .col--md--offset--10 {
    margin-left: 83.33333%; }
  .col--md--offset--11 {
    margin-left: 91.66667%; }
  .col--md--offset--12 {
    margin-left: 100%; } }

@media (min-width: 1180px) {
  .row--lg--top {
    align-items: flex-start; }
  .row--lg--center {
    align-items: center; }
  .row--lg--bottom {
    align-items: flex-end; }
  .col--lg--align-top {
    align-self: flex-start; }
  .col--lg--align-center {
    align-self: center; }
  .col--lg--align-bottom {
    align-self: flex-end; }
  .col--lg--first {
    order: -1; }
  .col--lg--last {
    order: 1; }
  .col--lg--reset {
    order: 0; }
  .row--lg--reverse {
    flex-direction: row-reverse; }
  .col--lg--flex {
    display: flex; }
  .text--lg--right {
    text-align: right; }
  .text--lg--left {
    text-align: left; }
  .text--lg--center {
    text-align: center; }
  .col--lg {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
    padding: 1rem 1rem 0 1rem; }
  .col--lg--1 {
    width: 8.33333%;
    flex: none; }
  .col--lg--2 {
    width: 16.66667%;
    flex: none; }
  .col--lg--3 {
    width: 25%;
    flex: none; }
  .col--lg--4 {
    width: 33.33333%;
    flex: none; }
  .col--lg--5 {
    width: 41.66667%;
    flex: none; }
  .col--lg--6 {
    width: 50%;
    flex: none; }
  .col--lg--7 {
    width: 58.33333%;
    flex: none; }
  .col--lg--8 {
    width: 66.66667%;
    flex: none; }
  .col--lg--9 {
    width: 75%;
    flex: none; }
  .col--lg--10 {
    width: 83.33333%;
    flex: none; }
  .col--lg--11 {
    width: 91.66667%;
    flex: none; }
  .col--lg--12 {
    width: 100%;
    flex: none; }
  .col--lg--offset--1 {
    margin-left: 8.33333%; }
  .col--lg--offset--2 {
    margin-left: 16.66667%; }
  .col--lg--offset--3 {
    margin-left: 25%; }
  .col--lg--offset--4 {
    margin-left: 33.33333%; }
  .col--lg--offset--5 {
    margin-left: 41.66667%; }
  .col--lg--offset--6 {
    margin-left: 50%; }
  .col--lg--offset--7 {
    margin-left: 58.33333%; }
  .col--lg--offset--8 {
    margin-left: 66.66667%; }
  .col--lg--offset--9 {
    margin-left: 75%; }
  .col--lg--offset--10 {
    margin-left: 83.33333%; }
  .col--lg--offset--11 {
    margin-left: 91.66667%; }
  .col--lg--offset--12 {
    margin-left: 100%; } }

@media (min-width: 1680px) {
  .row--xl--top {
    align-items: flex-start; }
  .row--xl--center {
    align-items: center; }
  .row--xl--bottom {
    align-items: flex-end; }
  .col--xl--align-top {
    align-self: flex-start; }
  .col--xl--align-center {
    align-self: center; }
  .col--xl--align-bottom {
    align-self: flex-end; }
  .col--xl--first {
    order: -1; }
  .col--xl--last {
    order: 1; }
  .col--xl--reset {
    order: 0; }
  .row--xl--reverse {
    flex-direction: row-reverse; }
  .col--xl--flex {
    display: flex; }
  .text--xl--right {
    text-align: right; }
  .text--xl--left {
    text-align: left; }
  .text--xl--center {
    text-align: center; }
  .col--xl {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
    padding: 1rem 1rem 0 1rem; }
  .col--xl--1 {
    width: 8.33333%;
    flex: none; }
  .col--xl--2 {
    width: 16.66667%;
    flex: none; }
  .col--xl--3 {
    width: 25%;
    flex: none; }
  .col--xl--4 {
    width: 33.33333%;
    flex: none; }
  .col--xl--5 {
    width: 41.66667%;
    flex: none; }
  .col--xl--6 {
    width: 50%;
    flex: none; }
  .col--xl--7 {
    width: 58.33333%;
    flex: none; }
  .col--xl--8 {
    width: 66.66667%;
    flex: none; }
  .col--xl--9 {
    width: 75%;
    flex: none; }
  .col--xl--10 {
    width: 83.33333%;
    flex: none; }
  .col--xl--11 {
    width: 91.66667%;
    flex: none; }
  .col--xl--12 {
    width: 100%;
    flex: none; }
  .col--xl--offset--1 {
    margin-left: 8.33333%; }
  .col--xl--offset--2 {
    margin-left: 16.66667%; }
  .col--xl--offset--3 {
    margin-left: 25%; }
  .col--xl--offset--4 {
    margin-left: 33.33333%; }
  .col--xl--offset--5 {
    margin-left: 41.66667%; }
  .col--xl--offset--6 {
    margin-left: 50%; }
  .col--xl--offset--7 {
    margin-left: 58.33333%; }
  .col--xl--offset--8 {
    margin-left: 66.66667%; }
  .col--xl--offset--9 {
    margin-left: 75%; }
  .col--xl--offset--10 {
    margin-left: 83.33333%; }
  .col--xl--offset--11 {
    margin-left: 91.66667%; }
  .col--xl--offset--12 {
    margin-left: 100%; } }

img {
  max-width: 100%;
  height: auto; }

.button-close {
  border: 0;
  background-color: transparent;
  position: absolute;
  height: 25px;
  display: block;
  z-index: 1;
  color: #111111;
  padding-right: 40px;
  top: 40px;
  right: 40px;
  font-size: 15px; }
  .button-close:after {
    content: " ";
    display: block;
    background-image: url(../images/ico-close.svg);
    background-repeat: no-repeat;
    width: 25px;
    height: 25px;
    position: absolute;
    top: 0;
    right: 0;
    transition-duration: 0.3s; }
  .button-close:hover {
    text-decoration: underline; }
    .button-close:hover:after {
      transform: rotate(180deg); }

a,
.link {
  padding-left: 1px;
  color: #000000;
  transition-duration: 0.2s;
  font-weight: 500; }
  a.link--renew,
  .link.link--renew {
    background-image: url(../images/renew-green.svg);
    background-repeat: none;
    background-repeat: no-repeat;
    padding-left: 20px; }
  a:hover,
  .link:hover {
    color: #1e1ec8;
    text-decoration: none; }
  a.link--info,
  .link.link--info {
    color: #1e1ec8; }
  a.link--small,
  .link.link--small {
    font-weight: 300;
    font-size: 12px; }
    a.link--small.link--renew,
    .link.link--small.link--renew {
      background-size: 19px 19px;
      padding-left: 22px; }
  a.link-registered,
  .link.link-registered {
    margin-left: 32px;
    font-weight: 700; }
    @media (max-width: 991px) {
      a.link-registered,
      .link.link-registered {
        margin-left: 0;
        margin-top: 0; } }

.link-primary {
  color: #28273d; }
  .link-primary:hover {
    color: inherit; }

.link--noborder {
  border: none;
  background-color: transparent; }

.a--arrow--green {
  position: relative;
  padding-right: 14px; }
  .a--arrow--green:after {
    content: '';
    position: absolute;
    right: 0;
    top: 50%;
    margin-top: -5px;
    background-image: url(../images/a_arrow_blue.svg);
    width: 7px;
    height: 10px; }

.a--arrow,
.a--arrow--back {
  position: relative; }
  .a--arrow:after,
  .a--arrow--back:after {
    margin-top: -5px;
    width: 7px;
    height: 10px;
    top: 50%;
    content: '';
    position: absolute; }

.a--arrow {
  padding-right: 12px; }
  .a--arrow:after {
    right: 0; }
  .a--arrow.black:after {
    background-image: url(../images/a_arrow_black.svg); }
  .a--arrow.green:after {
    background-image: url(../images/a_arrow_green.svg); }

.a--arrow--back {
  padding-left: 12px; }
  .a--arrow--back:after {
    left: 0;
    transform: scaleX(-1); }
  .a--arrow--back.black:after {
    background-image: url(../images/a_arrow_black.svg); }
  .a--arrow--back.green:after {
    background-image: url(../images/a_arrow_green.svg); }

p {
  margin: 0 0 1em; }

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  margin-top: 1em;
  margin-left: 0;
  margin-right: 0;
  line-height: 1.15;
  font-weight: 400;
  font-family: "neue-haas-grotesk-display", sans-serif;
  color: #1e1ec8; }
  h1:first-child,
  .h1:first-child,
  h2:first-child,
  .h2:first-child,
  h3:first-child,
  .h3:first-child,
  h4:first-child,
  .h4:first-child,
  h5:first-child,
  .h5:first-child,
  h6:first-child,
  .h6:first-child {
    margin-top: 0; }

h1,
.h1 {
  font-size: 40px;
  font-size: 2.5rem;
  margin-bottom: 0.4em; }

h2,
.h2 {
  font-size: 35px;
  font-size: 2.1875rem;
  margin-bottom: 0.45714em; }

h3,
.h3 {
  margin-bottom: 0.66667em;
  font-size: 24px;
  font-size: 1.5rem;
  color: #1e1ec8;
  font-weight: 400; }

h4,
.h4 {
  font-size: 23px;
  font-size: 1.4375rem;
  margin-bottom: 0.69565em; }

h5,
.h5 {
  font-size: 19px;
  font-size: 1.1875rem;
  margin-bottom: 0.84211em; }

h6,
.h6 {
  font-size: 17px;
  font-size: 1.0625rem;
  margin-bottom: 0.94118em; }

select,
textarea,
input[type=number],
input[type=text],
input[type=email],
input[type=date],
input[type=password] {
  border: 1px solid #d9d9d9;
  background-color: #ffffff;
  padding-left: 20px;
  padding-right: 20px;
  width: 100%;
  display: inline-block; }

input::-webkit-calendar-picker-indicator,
input::-webkit-inner-spin-button {
  display: none; }

form .nice-select {
  width: 100%;
  height: 52px;
  font-size: 18px;
  font-size: 1.125rem; }

form .nice-select__control {
  border: 1px solid #d9d9d9; }

form .nice-select,
form .nice-select__control {
  border-radius: 0;
  height: 52px; }
  form .nice-select input,
  form .nice-select__control input {
    box-shadow: none; }

form .nice-select__input {
  width: 100%;
  height: 100%; }

form .nice-select__option,
form .nice-select__value-container {
  padding: 0; }
  form .nice-select__option > div:not(.nice-select__placeholder),
  form .nice-select__value-container > div:not(.nice-select__placeholder) {
    width: 100%;
    height: 50px;
    padding: 0 20px;
    margin: 0; }

form .nice-select__single-value {
  line-height: 50px; }

form .nice-select__menu-list {
  padding-top: 0;
  padding-bottom: 0;
  max-height: 200px; }
  form .nice-select__menu-list > div {
    padding: 10px 20px; }

form .nice-select__placeholder {
  height: 26px;
  padding: 0 20px;
  padding-right: 30px;
  top: calc(50% - 11px);
  width: 100%;
  margin: 0;
  transform: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

textarea {
  padding-top: 15px;
  padding-bottom: 15px;
  min-height: 132px; }

select,
input[type=number],
input[type=text],
input[type=email],
input[type=date],
input[type=password] {
  height: 52px;
  font-size: 18px;
  font-size: 1.125rem; }

input[type=date] {
  max-width: 221px; }

hr {
  border: 0;
  margin: 20px 0;
  background-color: #e1e1e1;
  width: 100%;
  height: 1px;
  display: block; }

.subtitle {
  display: block;
  font-size: 50%; }

.hightlight {
  font-weight: 400; }

.scream {
  font-weight: 500; }

.scream--pending,
.scream--error {
  color: #f44336; }

.scream--paid,
.scream--success {
  color: #8eeb00; }

.scream--processing {
  color: #1e1ec8; }

.scream--storno {
  color: #000000; }

.scream--payup {
  color: #ec7e00; }

.ico {
  position: relative;
  display: inline-block; }
  .ico:after {
    float: left;
    content: "";
    top: 0;
    background-repeat: no-repeat; }

.ico-next:after {
  width: 6px;
  height: 10px;
  background-image: url(../images/ico-more-white.svg); }

.ico-next--green:after {
  width: 6px;
  height: 10px;
  background-image: url(../images/ico-more-right-blue.svg); }

.ico-next--gray:after {
  width: 6px;
  height: 10px;
  background-image: url(../images/ico-more-right-blue.svg);
  filter: grayscale(100%); }

.ico-pdf:after {
  margin-bottom: -3px;
  width: 18px;
  height: 18px;
  background-image: url(../images/ico-pdf.svg);
  opacity: 0.5; }

.invoice-pdf {
  text-decoration: none;
  min-width: 61px;
  display: inline-block; }
  .invoice-pdf span {
    text-decoration: underline; }
  .invoice-pdf:hover span {
    text-decoration: none; }

.h2--pop {
  font-size: 22px;
  font-weight: 500; }

.address-label {
  line-height: 44px;
  color: black;
  font-size: 17px;
  font-weight: 600; }

.intro__text--extra-step a:hover {
  text-decoration: none; }

.link {
  background-color: transparent;
  border: none;
  font-weight: 500;
  text-decoration: underline;
  padding-left: 0; }
  .link:hover {
    text-decoration: none; }

.green {
  color: #8eeb00; }

.ReactModal__Content h1 {
  font-weight: 700; }
  .ReactModal__Content h1 span {
    display: block;
    font-size: 22px;
    font-size: 1.375rem; }

.block {
  display: block; }

.none {
  display: none; }

.inline-block {
  display: inline-block; }

.inline {
  display: inline; }

.center {
  margin-left: auto;
  margin-right: auto; }

.relative {
  position: relative; }

.absolute {
  position: absolute; }

.static {
  position: static; }

.clearfix:before, .clearfix:after {
  content: "";
  display: table; }

.clearfix:after {
  clear: both; }

.cleaner {
  clear: both; }

.cleaner-left {
  clear: left; }

.cleaner-right {
  clear: right; }

.strong {
  font-weight: 700; }

.stronger {
  font-weight: 500; }

.slim {
  font-weight: 300; }

.italic {
  font-style: italic; }

.text-uppercase {
  text-transform: uppercase; }

.rm--margin {
  margin: 0; }

.spacer {
  position: relative;
  display: block; }

.spacer--10 {
  padding-bottom: 10px;
  margin-bottom: 10px; }

.spacer--20 {
  padding-bottom: 20px;
  margin-bottom: 20px; }

.spacer--30 {
  padding-bottom: 30px;
  margin-bottom: 30px; }

.spacer--top--30 {
  margin-top: 30px; }

.small-note,
.small-note p {
  font-size: 13px;
  color: #333; }

.small-note a {
  text-decoration: none; }
  .small-note a:hover {
    text-decoration: underline; }

.small-note h3 {
  font-size: inherit;
  margin: 0;
  font-weight: 600;
  color: black; }

.small-note .checkbox input [type='checkbox'],
.small-note .radio input [type='radio'] {
  vertical-align: -3px; }

.small-note.small-note--order {
  margin: 40px 0; }

.hidden {
  visibility: none; }

.visible {
  visibility: visible; }

.flex-middle {
  display: flex;
  align-items: center; }

.mb0 {
  margin-bottom: 0; }

@media (max-width: 1179px) {
  .melt--to--md {
    padding-top: 0; } }

.separator {
  margin-left: 10px;
  margin-right: 10px; }

.pin {
  text-align: center;
  display: flex;
  justify-content: center; }
  .pin .pin__input {
    max-width: 48px;
    margin-left: 5px;
    margin-right: 5px;
    border-radius: 5px;
    padding: 0 5px;
    text-align: center;
    border: 1px solid #707070; }

.pin-action {
  text-align: center;
  margin: 10px 0; }

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px; }

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5); }

@media (max-width: 767px) {
  .no__mobile {
    display: none; } }

@media (min-width: 768px) {
  .btn.no__desktop,
  .no__desktop {
    display: none; } }

/*
body::-webkit-scrollbar {
	-webkit-appearance: none;
    width: 1em;
}
body::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
}
*/
.mb-20 {
  margin-bottom: 20px; }

input:focus {
  box-shadow: 0 0 0 1pt #a6c7ff;
  outline: none; }

button:focus {
  box-shadow: 0 0 0 1pt #a6c7ff;
  outline: none; }

.radio input[type='radio'] {
  vertical-align: -2px; }

.checkbox input[type='checkbox'],
.radio input[type='radio'] {
  position: relative;
  appearance: none;
  border: 1px solid #c2c2c2;
  margin-right: 10px;
  background-color: white; }
  .checkbox input[type='checkbox']:checked:before,
  .radio input[type='radio']:checked:before {
    content: '';
    position: absolute; }
  .checkbox input[type='checkbox']:disabled,
  .radio input[type='radio']:disabled {
    border-color: #e7e7e7;
    background-color: #e7e7e7; }

.list__input {
  margin-right: 10px; }

.radio {
  display: flex; }

.checkbox {
  display: flex; }
  .checkbox span {
    font-size: 14px;
    font-size: 0.875rem;
    margin-top: 4px;
    font-weight: 300; }
    .checkbox span.checkbox__label {
      margin-top: 2px;
      font-size: 18px;
      font-size: 1.125rem; }
  .checkbox input[type='checkbox'] {
    min-width: 20px;
    width: 20px;
    height: 20px;
    margin-top: 3px; }
    .checkbox input[type='checkbox'][name='no_address'] {
      margin-top: 1px; }
    .checkbox input[type='checkbox']:checked {
      border: 1px solid #1e1ec8; }
      .checkbox input[type='checkbox']:checked:before {
        width: 100%;
        height: 100%;
        background-image: url(../images/checkbox.svg);
        background-repeat: no-repeat;
        background-size: 14px 16px;
        background-position: center; }

input[type='radio'] {
  flex: 0 0 20px;
  width: 20px;
  height: 20px;
  border-radius: 20px;
  margin-top: 1px; }
  input[type='radio']:checked {
    border-color: #1e1ec8; }
    input[type='radio']:checked:before {
      width: 8px;
      height: 8px;
      border-radius: 8px;
      background-color: #1e1ec8;
      left: 50%;
      top: 50%;
      margin-left: -4px;
      margin-top: -4px; }
  input[type='radio']:focus {
    box-shadow: 0 0 0 1pt #a6c7ff;
    outline: none; }

label {
  font-weight: 500;
  font-size: 18px;
  font-size: 1.125rem;
  position: relative; }

@media (max-width: 479px) {
  .form__row:not(:last-of-type),
  .form__row--fluid:not(:last-of-type) {
    margin-bottom: 20px; } }

@media (min-width: 480px) {
  .form__row:not(:last-of-type),
  .form__row--fluid:not(:last-of-type) {
    margin-bottom: 24px; } }

.form__row.form__row--small,
.form__row--fluid.form__row--small {
  margin-bottom: 0; }

.form__row.form__row--conditional,
.form__row--fluid.form__row--conditional {
  padding: 20px 0 30px 0; }

.form__row {
  display: flex;
  flex-flow: column;
  flex-wrap: wrap; }
  .form__row .form__item__group {
    flex-basis: 100%;
    margin-bottom: 26px; }
    @media (min-width: 768px) {
      .form__row .form__item__group {
        flex-basis: calc(50% - 30px); } }
  .form__row .form__item__group--checkbox {
    flex-basis: 100%; }
  .form__row .form__item {
    display: flex;
    flex-wrap: wrap; }
    .form__row .form__item:first-of-type {
      flex-basis: 100%; }
    .form__row .form__item:last-of-type {
      flex-basis: 100%; }
    .form__row .form__item label {
      margin: 0 0 6px 0;
      font-weight: 700; }
      @media (max-width: 579px) {
        .form__row .form__item label {
          margin-bottom: 10px; } }

.form__row--invoice {
  margin: 0; }

.form__row--register {
  flex-flow: initial;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 0 !important; }

.form__row--gdpr .form__item__group {
  margin-top: 10px;
  margin-bottom: 50px; }

.form__row--fluid .form__item:first-child {
  padding-right: 25px;
  flex: none; }

.form__item {
  flex: 1;
  position: relative; }

.form__invoice {
  padding: 0 0 10px;
  max-width: 370px; }

.form__invoice--full {
  max-width: 100%; }

.form__password__hint {
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 24px;
  margin-top: 4px; }

.toggle-input {
  position: relative; }
  .toggle-input.toggle-input--help {
    padding-right: 50px; }
    .toggle-input.toggle-input--help:after {
      margin: 0; }
  .toggle-input button {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 50px;
    background-color: transparent;
    border: none;
    border-left: 1px solid #eaeaea;
    border-radius: 0;
    text-indent: -999px;
    overflow: hidden; }
    .toggle-input button:after {
      content: '';
      background-repeat: no-repeat;
      background-position: center;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      margin-left: -2px; }
    .toggle-input button.toggle-password:after {
      background-image: url(../images/eye.svg); }
    .toggle-input button.toggle-password.visibility-on:after {
      background-image: url(../images/eye-off.svg); }
    .toggle-input button.toggle-help {
      border: 1px solid #d9d9d9;
      border-left: 0; }
      .toggle-input button.toggle-help:after {
        content: '?';
        font-size: 18px;
        font-size: 1.125rem;
        line-height: 52px;
        text-indent: 0;
        border-radius: 0;
        color: #c8c8c8;
        font-weight: 700; }

.activation .toggle-input.toggle-input--help {
  max-width: 370px; }

select {
  appearance: none;
  border-radius: 0; }
  select:focus {
    box-shadow: 0 0 0 1pt #a6c7ff;
    outline: none; }

select::-ms-expand {
  display: none;
  /* hide the default arrow in ie10 and ie11 */ }

select,
.nice-select .nice-select__control {
  background-position: calc(100% - 15px) 50%;
  background-image: url(../images/ico_dropdown.svg);
  background-repeat: no-repeat; }

.nice-select .nice-select__indicators {
  display: none; }

.address-pick .address-pick label {
  padding: 10px 10px 10px 36px;
  display: block; }
  .address-pick .address-pick label input[type='radio'] {
    margin-left: -24px;
    margin-right: 8px; }

.address-pick .address-pick.even {
  border-radius: 6px;
  background-color: #f2f2f2; }

.address-pick__list {
  max-height: 250px;
  overflow: auto; }

.quantity-picker {
  height: 30px;
  position: relative; }

input.quantity-picker__input {
  width: 120px;
  padding: 8px 35px;
  height: 30px;
  text-align: center; }

.quantity-picker__button {
  position: absolute;
  width: 35px;
  height: 30px;
  background-color: #ffffff;
  border: 1px solid #c8c8c8;
  border-radius: 0;
  top: 0; }
  .quantity-picker__button:disabled {
    background-color: rgba(200, 200, 200, 0.2);
    color: #707070;
    cursor: not-allowed; }

.quantity-picker__button--dec {
  left: 0; }

.quantity-picker__button--inc {
  right: 0; }

.contract {
  margin-bottom: 40px; }

.acknowledgment__box {
  font-weight: 400;
  font-size: 1rem; }
  .acknowledgment__box span {
    cursor: pointer;
    text-decoration: underline;
    font-size: 16px;
    font-size: 1rem; }

a.btn,
.btn {
  display: inline-block;
  padding: 12px 22px;
  font-weight: 400;
  font-size: 18px;
  line-height: 1;
  border: none;
  transition: background-color 0.2s;
  position: relative;
  text-decoration: none;
  text-align: center; }
  a.btn:disabled,
  .btn:disabled {
    background-color: #c8c8c8; }
    a.btn:disabled:hover,
    .btn:disabled:hover {
      background-color: #c8c8c8; }

a.btn--primary,
.btn--primary {
  color: #ffffff;
  background-color: #1e1ec8; }
  a.btn--primary:hover,
  .btn--primary:hover {
    background-color: #11118f; }

.btn--item_selected {
  background-color: #8eeb00; }
  .btn--item_selected:focus {
    box-shadow: none;
    outline: none; }
  .btn--item_selected:disabled {
    background-color: #8eeb00; }
    .btn--item_selected:disabled:hover {
      background-color: #8eeb00; }

.btn--reward {
  background-color: #ff005a;
  color: #ffffff; }
  .btn--reward:hover {
    color: #ffffff; }
  .btn--reward:focus {
    box-shadow: none;
    outline: none; }
  .btn--reward:disabled {
    background-color: #ff005a; }
    .btn--reward:disabled:hover {
      background-color: #ff005a; }

.btn--secondary {
  color: #000000;
  border: 1px solid #c8c8c8;
  background-color: transparent;
  transition-duration: 0.3s; }
  .btn--secondary:hover {
    background-color: #000000;
    color: #ffffff; }
    .btn--secondary:hover.btn--prev:after {
      background-image: url(../images/ico-more-white.svg);
      transform: scaleX(-1);
      margin-left: -1px; }
    .btn--secondary:hover.btn--next:after {
      background-image: url(../images/ico-more-white.svg); }
  .btn--secondary.btn--prev:after {
    background-image: url(../images/ico-more-left-blue.svg); }
  .btn--secondary.btn--next:after {
    background-image: url(../images/ico-more-right-blue.svg); }

a.btn--next,
.btn--next {
  padding-right: 45px; }
  a.btn--next:after,
  .btn--next:after {
    content: '';
    background-image: url(../images/ico-more-white.svg);
    background-repeat: no-repeat;
    width: 9px;
    height: 12px;
    position: absolute;
    right: 22px;
    top: 50%;
    margin-top: -6px;
    background-size: contain; }

a.btn--prev,
.btn--prev {
  padding-left: 42px; }
  a.btn--prev:after,
  .btn--prev:after {
    content: '';
    background-image: url(../images/ico-more-white.svg);
    background-repeat: no-repeat;
    width: 9px;
    height: 12px;
    position: absolute;
    left: 26px;
    top: 50%;
    margin-top: -6px;
    background-size: contain; }

a.btn--plus,
.btn--plus {
  padding-left: 42px; }
  a.btn--plus:after,
  .btn--plus:after {
    content: '';
    background-image: url(../images/primary-btn-plus.svg);
    background-repeat: no-repeat;
    width: 11px;
    height: 11px;
    position: absolute;
    left: 25px;
    top: 50%;
    margin-top: -5px;
    background-size: contain; }

a.btn--lock,
.btn--lock {
  padding-right: 52px; }
  a.btn--lock:after,
  .btn--lock:after {
    content: '';
    background-image: url(../images/login_lock.svg);
    background-repeat: no-repeat;
    width: 10px;
    height: 12px;
    position: absolute;
    right: 36px;
    top: 50%;
    margin-top: -6px; }

a.btn--delete,
a.btn--edit,
.btn--edit,
.btn--delete {
  padding: 0;
  text-indent: -999px;
  position: relative;
  overflow: hidden;
  width: 30px;
  line-height: 20px;
  border: none;
  background-color: transparent; }
  a.btn--delete:after,
  a.btn--edit:after,
  .btn--edit:after,
  .btn--delete:after {
    text-indent: 0;
    content: '';
    background-repeat: no-repeat;
    position: absolute;
    top: 0;
    right: 0;
    background-size: contain;
    width: 100%;
    height: 100%;
    background-position: center; }

a.btn--delete:after,
.btn--delete:after {
  background-image: url(../images/delete.svg); }

a.btn--edit:after,
.btn--edit:after {
  background-image: url(../images/edit.svg); }

a.btn--filter,
.btn--filter {
  display: inline-block;
  background-color: white;
  color: #84939f;
  border: 1px solid #eaeaea; }
  a.btn--filter:hover, a.btn--filter.active,
  .btn--filter:hover,
  .btn--filter.active {
    color: black;
    border-color: #1e1ec8; }

.btn--wideboi {
  min-width: 190px; }

.btn--right {
  float: right; }

.btn--help {
  position: absolute;
  top: 0;
  left: 0;
  width: 22px;
  height: 22px;
  border: none;
  background-color: transparent;
  background-image: url(../images/question.svg);
  background-repeat: no-repeat;
  background-size: contain;
  text-indent: -999px;
  overflow: hidden;
  margin-left: 5px; }

.btn--big {
  padding: 1rem 1.25rem; }

/* Layouts */
body {
  min-width: 320px;
  color: #000000;
  -webkit-font-smoothing: antialiased;
  line-height: 1.4;
  background-color: #f8f8f8; }
  body::-moz-placeholder {
    color: #707070;
    opacity: 1; }
  body:-ms-input-placeholder {
    color: #707070; }
  body::-webkit-input-placeholder {
    color: #707070; }
  @media (max-width: 479px) {
    body.modal-open {
      overflow: hidden; } }

.header__go_to_content {
  position: absolute;
  left: -9999px; }

/* Components */
.intro {
  width: 100vw;
  min-height: 100vh;
  background-image: url(../images/intro_background.png);
  background-size: cover;
  background-position: center;
  max-width: 100%; }
  .intro h1 {
    font-size: 35px;
    font-size: 2.1875rem; }
  @media (max-width: 579px) {
    .intro {
      padding-top: 15px;
      padding-bottom: 15px; } }

.intro__inner {
  height: 100%; }
  @media (min-width: 992px) {
    .intro__inner {
      display: flex;
      justify-content: flex-end; } }

.intro__inner__logo {
  z-index: 2;
  left: 0;
  top: 0; }
  @media (max-width: 991px) {
    .intro__inner__logo {
      padding-left: 20px;
      padding-top: 20px;
      max-width: 140px;
      margin: 0 auto; } }
  @media (min-width: 992px) {
    .intro__inner__logo {
      position: fixed;
      padding: 70px 60px; } }

@media (max-width: 991px) {
  .intro__inner__logo--big {
    display: none; } }

@media (min-width: 992px) {
  .logo-mobile {
    display: none; } }

.intro__inner__text {
  left: 0;
  bottom: 0;
  box-sizing: content-box; }
  @media (max-width: 991px) {
    .intro__inner__text {
      padding: 20px;
      text-align: center; } }
  @media (min-width: 992px) {
    .intro__inner__text {
      position: fixed;
      padding: 70px 60px;
      max-width: 600px; } }

@media (max-width: 1679px) {
  .intro__inner__text--big {
    display: none; } }

@media (min-width: 992px) and (max-width: 1179px) {
  .intro__inner__text--register {
    max-width: 40vw; } }

@media (min-width: 1180px) and (max-width: 1679px) {
  .intro__inner__text--register {
    max-width: calc(50vw - 120px); } }

.intro__inner__text__title {
  margin-bottom: 10px;
  line-height: 1;
  color: #ffffff;
  font-weight: 700; }
  @media (max-width: 767px) {
    .intro__inner__text__title {
      font-size: 36px;
      font-size: 2.25rem; } }
  @media (min-width: 768px) and (max-width: 1179px) {
    .intro__inner__text__title {
      font-size: 40px;
      font-size: 2.5rem; } }
  @media (min-width: 992px) and (max-width: 1179px) {
    .intro__inner__text__title {
      max-width: 72%; } }
  @media (min-width: 1180px) {
    .intro__inner__text__title {
      font-size: 75px;
      font-size: 4.6875rem; } }

.intro__inner__text__subtitle {
  line-height: 36px;
  color: #ffffff; }
  @media (max-width: 767px) {
    .intro__inner__text__subtitle {
      font-size: 24px;
      font-size: 1.5rem; } }
  @media (min-width: 768px) and (max-width: 1179px) {
    .intro__inner__text__subtitle {
      font-size: 30px;
      font-size: 1.875rem; } }
  @media (min-width: 992px) and (max-width: 1179px) {
    .intro__inner__text__subtitle {
      max-width: 72%; } }
  @media (min-width: 1180px) {
    .intro__inner__text__subtitle {
      font-size: 32px;
      font-size: 2rem; } }

.intro__box {
  position: relative;
  right: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  background-color: #ffffff; }
  @media (min-width: 992px) {
    .intro__box {
      min-height: 100vh; } }
  .intro__box form {
    width: 100%; }
  @media (min-width: 992px) {
    .intro__box {
      max-width: 482px; } }
  @media (max-width: 991px) {
    .intro__box {
      padding: 15px;
      width: 100%;
      justify-content: center; }
      .intro__box form {
        width: 100%; } }
  @media (min-width: 580px) {
    .intro__box {
      padding: 100px 64px; } }
  @media (max-width: 579px) {
    .intro__box {
      align-items: baseline; }
      .intro__box form {
        flex-basis: 100%; } }

@media (min-width: 1180px) {
  .intro__box--big {
    max-width: 960px; }
    .intro__box--big .intro__header {
      margin-bottom: 0; } }

@media (min-width: 992px) and (max-width: 1179px) {
  .intro__box--big {
    max-width: calc(100% - 300px); }
    .intro__box--big .intro__header {
      margin-bottom: 0; } }

@media (min-width: 768px) and (max-width: 991px) {
  .intro__box--big {
    max-width: none; } }

@media (min-width: 992px) and (max-width: 1179px) {
  .intro__box--register {
    max-width: 60vw; } }

@media (min-width: 1180px) and (max-width: 1679px) {
  .intro__box--register {
    max-width: 50vw; } }

@media (min-width: 1680px) {
  .intro__box--register {
    max-width: 960px; } }

.intro__logo-mobile {
  max-height: 50px; }
  @media (min-width: 768px) {
    .intro__logo-mobile {
      display: none; } }

.intro__links {
  padding-top: 15px; }

.intro__links__call-me {
  position: absolute;
  top: 60px;
  left: 64px;
  padding-left: 20px;
  line-height: 1; }
  .intro__links__call-me a {
    color: #1e1ec8;
    text-decoration: none;
    font-size: 18px;
    font-size: 1.125rem; }
    @media (max-width: 479px) {
      .intro__links__call-me a {
        font-size: 14px;
        font-size: 0.875rem; } }
  @media (max-width: 767px) {
    .intro__links__call-me {
      position: relative;
      top: auto;
      left: auto;
      margin-bottom: 10px; } }
  .intro__links__call-me:before {
    position: absolute;
    content: '';
    left: 0;
    top: calc(50% - 8px);
    background-image: url("../images/ico-phone.svg");
    background-size: 11px 15px;
    background-repeat: no-repeat;
    width: 11px;
    height: 15px; }

.intro__links__web {
  position: absolute;
  bottom: 66px;
  left: 64px; }
  .intro__links__web a {
    font-weight: 700;
    color: #000000;
    text-decoration: none; }
  @media (max-width: 767px) {
    .intro__links__web {
      position: relative;
      left: auto;
      bottom: auto;
      flex-basis: 100%;
      text-align: center;
      padding-top: 20px; } }

@media (max-width: 767px) {
  .intro__header {
    margin-bottom: 15px;
    margin-top: 15px;
    text-align: center; } }

@media (min-width: 480px) {
  .intro__header {
    margin-bottom: 40px; }
    .intro__header .intro__header__label {
      text-align: right; } }

.intro__header h1 {
  font-size: 45px;
  font-size: 2.8125rem;
  font-weight: bold; }
  @media (max-width: 479px) {
    .intro__header h1 {
      padding-top: 10px;
      font-size: 24px;
      font-size: 1.5rem; } }

.intro__buttons {
  display: flex; }
  @media (min-width: 480px) {
    .intro__buttons {
      align-items: center;
      justify-content: space-between; } }
  @media (max-width: 579px) {
    .intro__buttons {
      flex-wrap: wrap;
      justify-content: flex-end;
      flex-flow: column;
      align-items: flex-end; }
      .intro__buttons a {
        margin-top: 20px; } }
  @media (max-width: 479px) {
    .intro__buttons .btn {
      width: 100%; } }

.intro__buttons--register {
  justify-content: flex-start;
  flex-flow: initial;
  flex-wrap: wrap;
  align-items: center; }
  @media (max-width: 991px) {
    .intro__buttons--register {
      justify-content: space-between; } }
  @media (max-width: 479px) {
    .intro__buttons--register {
      justify-content: center; } }

@media (max-width: 479px) {
  .intro__header__headline {
    text-align: center; } }

@media (min-width: 480px) {
  .intro__header__headline {
    text-align: right; } }

.intro__buttons__link {
  border-top: 1px solid #eaeaea;
  border-bottom: 1px solid #eaeaea;
  margin-top: 60px;
  padding-top: 24px;
  padding-bottom: 24px; }
  .intro__buttons__link a {
    line-height: 2;
    font-weight: 700;
    color: #1e1ec8; }
  @media (max-width: 479px) {
    .intro__buttons__link {
      padding-top: 20px;
      margin-top: 30px; } }
  @media (max-width: 579px) {
    .intro__buttons__link {
      text-align: right; } }

.intro__forgotten {
  color: #000000;
  font-size: 14px;
  font-size: 0.875rem; }

@media (min-width: 768px) {
  .from__register__subtitle {
    font-size: 30px;
    font-size: 1.875rem;
    margin-bottom: 69px; } }

@media (max-width: 767px) {
  .from__register__subtitle {
    margin-bottom: 24px;
    text-align: center; } }

.from__register__subtitle div {
  margin-top: 5px;
  font-size: 18px;
  font-size: 1.125rem; }
  @media (max-width: 767px) {
    .from__register__subtitle div {
      font-size: 12px;
      font-size: 0.75rem;
      color: #1e1ec8; } }

#root {
  position: relative; }

/**
	Main menu
 */
.admin {
  position: relative; }

.admin__menu__closer {
  z-index: 100;
  border: 0;
  text-transform: uppercase;
  background-color: transparent;
  font-weight: 600;
  right: 15px;
  top: 15px;
  position: absolute;
  width: 20px;
  height: 20px;
  background-size: 20px;
  background-image: url(../images/menu_close.svg);
  background-repeat: no-repeat; }
  @media (min-width: 768px) {
    .admin__menu__closer {
      display: none; } }

.admin__menu {
  z-index: 50;
  transition-duration: 0.5s;
  background-color: #1e1ec8;
  min-height: 100%;
  padding-top: 60px;
  position: absolute;
  left: 0;
  top: 0; }
  @media (max-width: 479px) {
    .admin__menu {
      width: 100%;
      left: -100%;
      overflow-y: auto;
      height: calc(100vh + 16px);
      min-height: auto; } }
  @media (min-width: 1180px) {
    .admin__menu {
      width: 350px; } }
  @media (min-width: 1180px) and (max-width: 1679px) {
    .admin__menu {
      width: 290px; } }
  @media (min-width: 768px) and (max-width: 1179px) {
    .admin__menu {
      width: 210px; } }
  @media (min-width: 480px) and (max-width: 767px) {
    .admin__menu {
      width: 210px;
      left: -210px; } }
  .admin__menu > a {
    display: inline-block;
    margin-left: 70px;
    margin-bottom: 25px; }
    @media (max-width: 1179px) {
      .admin__menu > a {
        margin-left: 20px; } }
  .admin__menu.active {
    left: 0; }
  .admin__menu nav ul {
    padding: 0;
    margin: 0;
    list-style: none; }
  .admin__menu a,
  .admin__menu button {
    font-size: 17px;
    font-size: 1.0625rem;
    font-weight: 400;
    color: #ffffff; }

.contract_selector {
  color: white; }
  .contract_selector label {
    color: inherit;
    font-weight: 500;
    margin-bottom: 5px;
    display: block; }
  .contract_selector select {
    height: 40px;
    font-weight: 500;
    color: #00508c; }
    .contract_selector select option {
      color: black; }

.menu__first {
  position: relative;
  padding: 25px 70px;
  z-index: 5; }
  @media (min-width: 1180px) and (max-width: 1679px) {
    .menu__first {
      padding: 25px 40px; } }
  @media (max-width: 1179px) {
    .menu__first {
      padding: 20px 20px; } }
  @media (max-width: 579px) {
    .menu__first:last-of-type {
      padding-bottom: 100px; } }
  .menu__first.active {
    background-color: #1919aa; }
    .menu__first.active:before {
      content: '';
      position: absolute;
      right: 0;
      top: 0;
      width: 7px;
      height: 100%;
      background-color: #8eeb00; }
  .menu__first ul li {
    margin: 10px 0; }
  .menu__first ul a {
    color: #ffffff;
    position: relative;
    padding-left: 10px;
    display: inline-block;
    font-weight: 400;
    text-decoration: none; }
    .menu__first ul a:before {
      content: '–';
      position: absolute;
      left: 0; }
    .menu__first ul a:hover {
      color: #8eeb00; }
    .menu__first ul a.active {
      color: #8eeb00; }
  .menu__first .subnav-title {
    margin-bottom: 12px;
    position: relative;
    display: inline-block;
    text-decoration: none;
    text-align: left;
    font-size: 28px;
    font-size: 1.75rem;
    line-height: 1;
    font-weight: 700; }
    .menu__first .subnav-title.tv {
      padding-right: 30px; }
      .menu__first .subnav-title.tv:after {
        content: '';
        position: absolute;
        right: 0;
        bottom: 4px;
        background-image: url(../images/ico-tv.svg);
        width: 25px;
        height: 25px;
        background-size: 25px; }
    .menu__first .subnav-title:hover {
      color: #8eeb00; }
      .menu__first .subnav-title:hover:after {
        background-image: url(../images/ico-tv-green.svg); }

/**
Global administration components
 */
.admin__content {
  padding-top: 25px;
  padding-bottom: 30px;
  min-height: 100vh; }
  @media (min-width: 1180px) {
    .admin__content {
      padding-left: 350px; } }
  @media (min-width: 768px) and (max-width: 1179px) {
    .admin__content {
      padding-left: 210px; } }
  @media (min-width: 1180px) and (max-width: 1679px) {
    .admin__content {
      padding-left: 290px; } }
  .admin__content.admin__content--no-nav {
    padding-left: 0; }
  .admin__content h1 {
    color: #84939f;
    padding-right: 22px; }
    .admin__content h1 a {
      color: #1e1ec8;
      text-decoration: underline;
      font-weight: 300; }
      .admin__content h1 a:hover {
        color: #53606a; }
    .admin__content h1 .admin__content__separator {
      color: #000000; }
      @media (max-width: 579px) {
        .admin__content h1 .admin__content__separator {
          margin: 0 4px; } }
      @media (min-width: 580px) {
        .admin__content h1 .admin__content__separator {
          margin: 0 25px; } }
    .admin__content h1 .admin__content__subtitle {
      vertical-align: baseline;
      color: #1e1ec8;
      font-weight: 700; }
      @media (max-width: 579px) {
        .admin__content h1 .admin__content__subtitle {
          letter-spacing: -0.2px; } }

.footer--note {
  padding-top: 20px;
  font-size: 14px;
  font-size: 0.875rem; }

.pin-confirmation--inline {
  display: flex;
  align-items: center; }

.pin-confirmation.pin-confirmation--remove .form__item {
  margin-left: 0;
  display: inline-block;
  width: 100px; }

.pin-confirmation .form__item {
  margin-left: 10px;
  margin-right: 10px;
  position: relative;
  text-align: center;
  flex: 0 0 110px;
  width: 100%; }

.admin__menu__button {
  transition-duration: 0.5s;
  position: absolute;
  top: 25px;
  left: 1rem;
  z-index: 1;
  /*
	position: relative;
	*/
  border: 0;
  text-transform: uppercase;
  background-color: transparent;
  font-weight: 600;
  padding-left: 30px; }
  @media (min-width: 992px) {
    .admin__menu__button {
      display: none; } }
  .admin__menu__button:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 20px;
    height: 20px;
    background-size: 20px;
    background-image: url(../images/menu.svg); }

.admin__user {
  text-align: right; }
  @media (max-width: 1179px) {
    .admin__user a span {
      display: none; } }
  .admin__user .call-me {
    padding-left: 24px;
    cursor: pointer;
    vertical-align: middle;
    color: #1e1ec8;
    position: relative;
    display: inline-block;
    font-weight: 300;
    text-decoration: none; }
    @media (max-width: 767px) {
      .admin__user .call-me {
        display: none; } }
    .admin__user .call-me:hover {
      text-decoration: underline; }
    .admin__user .call-me:before {
      content: '';
      position: absolute;
      left: 0;
      top: 3px;
      width: 17px;
      height: 18px;
      background-image: url(../images/ico-phone.svg);
      background-repeat: no-repeat; }

.admin__user {
  text-align: right;
  /*
	@media (max-width: $screen-md-max) {
		display: inline-block;
		margin-left: 10px;
	}
	*/
  /*
	@media (min-width: $screen-lg) {
		margin-top: 20px;
	}
	*/ }
  .admin__user a {
    font-weight: 400;
    color: #2b3742;
    vertical-align: middle;
    text-decoration: none;
    position: relative;
    display: inline-block; }
    @media (max-width: 1179px) {
      .admin__user a {
        font-size: 0px;
        font-size: 0rem; } }
    @media (min-width: 1180px) {
      .admin__user a {
        font-size: 15px;
        font-size: 0.9375rem; } }
    .admin__user a:before {
      content: '';
      position: absolute;
      background-repeat: no-repeat;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0; }
    .admin__user a:hover {
      text-decoration: underline; }
    .admin__user a.user {
      margin-top: -4px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      font-weight: 700;
      color: #000000;
      /*
			@media (max-width: $screen-md-max) {
				padding: 0;
				width: 20px;
				height: 20px;
			}
			@media (min-width: $screen-lg) {

			}*/ }
      .admin__user a.user:before {
        background-image: url(../images/admin_user.png); }
    .admin__user a.logout {
      color: #fff;
      background-color: #1e1ec8;
      font-weight: 400;
      text-transform: none; }

@media (max-width: 579px) {
  .admin__user__info__button {
    font-size: 16px;
    font-size: 1rem;
    padding: 4px 0 0 0; } }

.admin__inner,
.bann {
  background-color: white;
  margin-bottom: 2.5rem; }
  @media (max-width: 767px) {
    .admin__inner,
    .bann {
      padding: 15px; } }
  @media (min-width: 768px) {
    .admin__inner,
    .bann {
      padding: 35px; } }

.admin__inner {
  width: 100%;
  /*
	h3 {
			@include fs(24);
			color: $blue-primary;
			font-weight: 400;
	}
	*/ }
  .admin__inner p strong {
    color: #000000; }
  .admin__inner h2 {
    font-weight: 700;
    color: #1e1ec8;
    margin-bottom: 20px; }
    @media (max-width: 991px) {
      .admin__inner h2 {
        font-size: 22px;
        font-size: 1.375rem; } }
    @media (min-width: 992px) and (max-width: 1679px) {
      .admin__inner h2 {
        font-size: 24px;
        font-size: 1.5rem; } }
    @media (min-width: 1680px) {
      .admin__inner h2 {
        font-size: 26px;
        font-size: 1.625rem; } }
  .admin__inner article {
    position: relative; }
    .admin__inner article time {
      padding-top: 10px;
      text-align: right;
      font-size: 16px;
      font-size: 1rem;
      color: #1e1ec8;
      width: 80px; }
      @media (max-width: 579px) {
        .admin__inner article time {
          position: relative;
          text-align: center;
          display: inline-block;
          margin: 10px 0; } }
      @media (min-width: 580px) {
        .admin__inner article time {
          position: absolute; } }
      .admin__inner article time:before {
        content: '';
        border-radius: 2px;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 4px;
        background-color: #1e1ec8; }
  .admin__inner.promo {
    padding: 0;
    position: relative; }
    .admin__inner.promo p {
      color: #ffffff; }
  .admin__inner.tv {
    background-size: cover;
    position: relative;
    background-position: center;
    background-image: url(../images/promo_tv.jpg);
    border: none;
    text-align: left;
    margin: 0; }
    .admin__inner.tv h2 {
      font-size: 32px;
      font-size: 2rem;
      padding-bottom: 40px;
      color: #ffffff; }
    .admin__inner.tv p {
      background-color: #8eeb00;
      color: #ffffff;
      font-weight: 600;
      display: inline-block;
      padding: 1rem 2rem;
      font-size: 17px;
      font-size: 1.0625rem;
      text-decoration: none;
      transition-duration: 0.2s; }
    .admin__inner.tv:hover p {
      background-color: #75c200; }
  .admin__inner .line-label {
    position: relative;
    font-size: 17px;
    color: #000000;
    font-weight: 500; }
    .admin__inner .line-label span {
      background-color: white;
      padding-right: 10px;
      position: relative; }
    .admin__inner .line-label:before {
      content: "";
      position: absolute;
      width: 100%;
      height: 1px;
      left: 0;
      top: 50%;
      background-color: #d9d9d9; }

.section {
  margin-bottom: 35px; }

.mt0 {
  margin-top: 0; }

.mt20 {
  margin-top: 20px; }

.mb20,
.form__row.mb20 {
  margin-bottom: 20px; }

.ui__loading {
  border-radius: 3px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.6); }
  .ui__loading span {
    font-size: 14px;
    margin-top: 10px; }
  .ui__loading .ui__loading__text {
    font-weight: 600;
    text-transform: uppercase;
    font-size: 25px;
    background-color: rgba(255, 255, 255, 0.7);
    padding: 20px 30px;
    margin: 20px 0 0;
    color: #1e1ec8; }
  .ui__loading--fixed {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0; }

.ui__loading__rect {
  background-color: #1e1ec8;
  height: 50px;
  width: 6px;
  margin: 0 2px;
  display: inline-block;
  animation: ui__loading 1.2s infinite ease-in-out;
  position: relative; }

.ui__loading__rect--1 {
  animation-delay: -1.2s; }

.ui__loading__rect--2 {
  animation-delay: -1.1s; }

.ui__loading__rect--3 {
  animation-delay: -1s; }

.ui__loading__rect--4 {
  animation-delay: -0.9s; }

.ui__loading__rect--5 {
  animation-delay: -0.8s; }

@keyframes ui__loading {
  0%,
  40%,
  100% {
    transform: scaleY(0.4); }
  20% {
    transform: scaleY(1); } }

.global-loading {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 55; }

.with-input {
  display: flex;
  align-items: center; }
  .with-input label {
    padding-right: 10px;
    flex: none; }

.wrap {
  flex-wrap: wrap; }

.admin__article__inner {
  margin-bottom: 30px; }
  @media (min-width: 580px) {
    .admin__article__inner {
      padding-left: 100px; } }
  .admin__article__inner h3 {
    font-size: 18px;
    font-size: 1.125rem;
    font-weight: 300; }
  .admin__article__inner p {
    font-weight: 300;
    margin-top: 10px;
    color: #84939f; }

.admin__inner__wrap {
  position: absolute;
  display: flex;
  height: 100%;
  width: 50%;
  top: 0;
  left: 30px;
  align-items: center; }

.admin__inner__wrap__content {
  display: block;
  color: #ffffff; }
  .admin__inner__wrap__content h2 {
    font-weight: 400;
    color: #ffffff; }
  .admin__inner__wrap__content strong {
    margin-bottom: 20px;
    display: block; }
  .admin__inner__wrap__content p {
    font-weight: 300;
    margin-bottom: 40px; }

.progress {
  display: flex;
  padding: 0; }

.progress__item {
  list-style-type: none;
  font-size: 17px;
  color: #a2a2a2;
  flex: 1 1 0;
  position: relative;
  padding-bottom: 20px;
  margin-right: 6px; }
  .progress__item:before, .progress__item:after {
    bottom: 0;
    left: 0;
    box-sizing: border-box;
    content: "";
    height: 8px;
    position: absolute;
    width: 100%; }
  .progress__item:before {
    background-color: #eaeaea; }
  .progress__item:last-child {
    margin-right: 0; }

.progress__item--active {
  color: #000; }
  .progress__item--active:before {
    background-color: #8eeb00; }

.progress__item--finished {
  color: #8eeb00;
  cursor: pointer; }
  .progress__item--finished:before {
    background-color: #8eeb00; }

.progress_label {
  text-align: center;
  display: block;
  font-weight: 700; }

.loader {
  position: relative;
  margin: 40px 0 60px 0; }

.vertical {
  width: 15px;
  max-height: 300px;
  height: calc(100vh - 300px);
  background-color: #d3d3d3;
  border-radius: 7px;
  font-size: 0px;
  font-size: 0rem;
  position: relative; }
  .vertical:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 0;
    background-color: #8eeb00;
    border-radius: 7px 7px 0 0;
    transition-duration: 0.8s; }

.vertical--1:before {
  height: calc(100% / 5 * (0)); }

.vertical--2:before {
  height: calc(100% / 5 * (1)); }

.vertical--3:before {
  height: calc(100% / 5 * (2)); }

.vertical--4:before {
  height: calc(100% / 5 * (3)); }

.vertical--5:before {
  height: calc(100% / 5 * (4));
  border-radius: 7px; }

.vertical--6:before {
  height: 100%;
  border-radius: 7px; }

.loader--1 li:nth-child(-n + 1) {
  opacity: 1; }
  .loader--1 li:nth-child(-n + 1):before {
    opacity: 0; }
  .loader--1 li:nth-child(-n + 1):after {
    opacity: 1; }

.loader--1 li:nth-child(1):before {
  opacity: 1; }

.loader--1 li:nth-child(1):after {
  opacity: 0; }

.loader--2 li:nth-child(-n + 2) {
  opacity: 1; }
  .loader--2 li:nth-child(-n + 2):before {
    opacity: 0; }
  .loader--2 li:nth-child(-n + 2):after {
    opacity: 1; }

.loader--2 li:nth-child(2):before {
  opacity: 1; }

.loader--2 li:nth-child(2):after {
  opacity: 0; }

.loader--3 li:nth-child(-n + 3) {
  opacity: 1; }
  .loader--3 li:nth-child(-n + 3):before {
    opacity: 0; }
  .loader--3 li:nth-child(-n + 3):after {
    opacity: 1; }

.loader--3 li:nth-child(3):before {
  opacity: 1; }

.loader--3 li:nth-child(3):after {
  opacity: 0; }

.loader--4 li:nth-child(-n + 4) {
  opacity: 1; }
  .loader--4 li:nth-child(-n + 4):before {
    opacity: 0; }
  .loader--4 li:nth-child(-n + 4):after {
    opacity: 1; }

.loader--4 li:nth-child(4):before {
  opacity: 1; }

.loader--4 li:nth-child(4):after {
  opacity: 0; }

.loader--5 li:nth-child(-n + 5) {
  opacity: 1; }
  .loader--5 li:nth-child(-n + 5):before {
    opacity: 0; }
  .loader--5 li:nth-child(-n + 5):after {
    opacity: 1; }

.loader--5 li:nth-child(5):before {
  opacity: 1; }

.loader--5 li:nth-child(5):after {
  opacity: 0; }

.loader--6 li:nth-child(-n + 6) {
  opacity: 1; }
  .loader--6 li:nth-child(-n + 6):before {
    opacity: 0; }
  .loader--6 li:nth-child(-n + 6):after {
    opacity: 1; }

.loader--6 li:nth-child(6):before {
  opacity: 1; }

.loader--6 li:nth-child(6):after {
  opacity: 0; }

.loader__notice {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 12px;
  top: 0;
  margin: 0;
  list-style: none; }
  .loader__notice li {
    opacity: 0;
    position: absolute;
    transition-duration: 0.3s; }
    .loader__notice li:nth-child(0) {
      top: calc((100% / 5 * (0 - 1)) + (100% / 5)/2 - 11px); }
    .loader__notice li:nth-child(1) {
      top: calc((100% / 5 * (1 - 1)) + (100% / 5)/2 - 11px); }
    .loader__notice li:nth-child(2) {
      top: calc((100% / 5 * (2 - 1)) + (100% / 5)/2 - 11px); }
    .loader__notice li:nth-child(3) {
      top: calc((100% / 5 * (3 - 1)) + (100% / 5)/2 - 11px); }
    .loader__notice li:nth-child(4) {
      top: calc((100% / 5 * (4 - 1)) + (100% / 5)/2 - 11px); }
    .loader__notice li:nth-child(5) {
      top: calc((100% / 5 * (5 - 1)) + (100% / 5)/2 - 11px); }
    .loader__notice li:before {
      content: '';
      width: 20px;
      height: 20px;
      background-image: url("../images/loader.svg");
      background-size: 20px;
      background-repeat: no-repeat;
      background-position: center;
      transition-duration: 0.3s;
      opacity: 1;
      display: inline-block;
      margin-left: 10px;
      position: absolute;
      right: 0; }
    .loader__notice li:after {
      content: '';
      width: 16px;
      height: 14px;
      background-image: url("../images/checkbox.svg");
      background-repeat: no-repeat;
      background-position: center;
      transition-duration: 0.8s;
      opacity: 0;
      display: inline-block;
      margin-left: 10px; }

.message-wrapper {
  display: flex;
  justify-content: center; }

div.message-block {
  max-width: 507px;
  padding: 46px; }
  @media (max-width: 1679px) {
    div.message-block {
      padding: 16px; } }
  @media (max-width: 991px) {
    div.message-block {
      display: none; } }
  div.message-block h1 {
    font-weight: 700;
    color: #1e1ec8;
    font-size: 32px;
    font-size: 2rem;
    padding-right: 0; }
    @media (max-width: 1679px) {
      div.message-block h1 {
        font-size: 24px;
        font-size: 1.5rem; } }
  div.message-block p {
    font-size: 28px;
    font-size: 1.75rem;
    font-weight: 700; }
    @media (max-width: 1679px) {
      div.message-block p {
        font-size: 20px;
        font-size: 1.25rem; } }

.list {
  padding: 0;
  margin: 0; }
  .list li {
    margin-top: 15px;
    margin-bottom: 15px; }
  .list .list__row {
    margin-left: -10px;
    margin-right: -10px;
    display: flex;
    flex-wrap: wrap; }
  @media (max-width: 479px) {
    .list .list__row--info .list__action {
      margin-top: 10px; } }
  .list .list__col {
    padding-left: 10px;
    padding-right: 10px; }
  .list p {
    margin-bottom: 0; }
  .list.align-center div,
  .list.align-center li {
    align-items: center; }
    .list.align-center div.list__row--info,
    .list.align-center li.list__row--info {
      align-items: flex-start; }
  .list.list--nowrap > .list__row {
    flex-wrap: nowrap; }

.list__label {
  flex: 0 0 220px;
  /*
    font-size: 16px;
    */
  color: black;
  font-weight: 500; }
  .list__label .list__label__sub {
    color: #84939f;
    font-weight: 400;
    font-size: 13px; }

.list__label__info {
  text-transform: lowercase; }

.list__col--auto {
  flex: 1; }

/*
.list__label--payment {
    flex: 0 0 180px;
}
*/
.info__box .list__label {
  flex: 0 0 215px; }

.list-col {
  padding-left: 10px;
  padding-right: 10px; }

.list__value {
  flex: 1;
  /*
    font-size: 15px;
    */
  padding-left: 10px;
  padding-right: 10px; }
  .list__value strong {
    font-weight: 500; }
  .list__value.list__value--number .form__item {
    max-width: 100px;
    display: inline-block; }

.list__value--cut {
  text-overflow: ellipsis;
  overflow: hidden; }

.list__action {
  justify-content: space-between; }

.list__action--icons {
  flex: 0 0 60px; }

.list__action--icons--single {
  flex: 0 0 30px; }

.list--services .list__value {
  flex: 0 0 255px; }

.list--services .list__label {
  flex: 0 0 320px; }

.list--payment-setup label {
  font-weight: 400; }

.list--payment-setup .active label {
  font-weight: 500; }

.footline {
  margin-top: 30px; }

.footline__btn--left {
  margin-right: 40px; }

.footline--contact {
  margin-top: 20px;
  margin-bottom: 60px; }

.footline--activation {
  margin-top: 50px;
  display: flex;
  justify-content: space-between; }

.footline--activation--1 {
  margin-top: 25px;
  justify-content: flex-end; }

.list--inside {
  margin-left: -10px;
  margin-right: -10px; }

@media (max-width: 1679px) and (min-width: 992px), (max-width: 991px) and (min-width: 768px), (max-width: 767px) {
  .list__row--col > div {
    flex-basis: 100%; } }

@media (max-width: 767px) {
  .list__col--width-1 {
    flex-basis: 100%; } }

.info__box {
  background-color: #f2f2f2;
  padding: 25px;
  position: relative; }
  .info__box .button-close {
    top: 25px;
    right: 25px; }

.digi-table {
  width: 100%;
  border-collapse: collapse; }
  .digi-table .cell__label {
    display: none; }
  .digi-table thead td,
  .digi-table thead th {
    background-color: #1e1ec8;
    color: white; }
  .digi-table td,
  .digi-table th {
    font-weight: 500; }
  .digi-table .cell {
    padding: 15px; }
    .digi-table .cell:after {
      visibility: hidden;
      display: block;
      font-size: 0;
      content: " ";
      clear: both;
      height: 0; }
  .digi-table .cell__label {
    padding-right: 10px; }
  .digi-table .even td,
  .digi-table .even th {
    background-color: #f2f2f2; }
  .digi-table .payment-note {
    display: inline-block;
    font-weight: 300; }
  .digi-table .payment-issue {
    margin-right: 20px; }
  @media (max-width: 1101px) and (min-width: 768px), (max-width: 767px) {
    .digi-table thead {
      display: none; }
    .digi-table td,
    .digi-table th {
      display: block;
      clear: left;
      float: left;
      width: 100%; }
    .digi-table .cell__label {
      display: block; }
    .digi-table .cell__label,
    .digi-table .cell__content {
      float: left;
      width: 50%; } }

.digi-table--invoices p {
  margin-bottom: 0; }

.digi-table--invoices tr td:last-child,
.digi-table--invoices tr th:last-child {
  text-align: right; }

.order__total {
  margin-top: 50px;
  width: 100%;
  border: 1px solid #c8c8c8; }
  .order__total th:first-child,
  .order__total td:first-child {
    text-align: left; }
  .order__total th:last-child,
  .order__total td:last-child {
    text-align: right; }
  .order__total th.order__total__text,
  .order__total td.order__total__text {
    text-align: left; }
  .order__total .order__total__row td.order__total__text {
    border-bottom: 1px solid #c8c8c8; }
  .order__total thead th {
    background-color: #1e1ec8;
    padding: 30px 35px;
    color: #ffffff; }
    @media (max-width: 991px) {
      .order__total thead th {
        padding: 15px 15px; } }
  .order__total .order__total__title {
    color: #ffffff;
    margin-bottom: 0; }
    @media (max-width: 991px) {
      .order__total .order__total__title {
        font-size: 18px;
        font-size: 1.125rem; } }
  .order__total .order__total__row {
    position: relative; }
    .order__total .order__total__row td {
      padding: 15px 35px;
      border-bottom: 1px solid #eaeaea; }
      @media (max-width: 991px) {
        .order__total .order__total__row td {
          padding: 10px 15px; } }
    .order__total .order__total__row:first-child td {
      padding-top: 40px; }
  .order__total .order__total__row--gray {
    background-color: #f1f0f0; }
  .order__total .order__total__row__name {
    font-size: 18px;
    font-size: 1.125rem;
    color: #1e1ec8; }
  .order__total td.order__total__row__one-time-payment {
    font-size: 18px;
    font-size: 1.125rem;
    font-weight: 700;
    color: #ffffff;
    background-color: #1e1ec8;
    text-align: left; }
  .order__total td.order__total__row__name--big {
    font-weight: 700;
    color: #000000;
    border-bottom: 1px solid #c8c8c8; }
  .order__total td.order__total__row__name--final {
    font-weight: 700;
    font-size: 24px;
    font-size: 1.5rem;
    border-bottom: 0;
    padding-top: 40px; }
    @media (max-width: 579px) {
      .order__total td.order__total__row__name--final {
        font-size: 22px;
        font-size: 1.375rem; } }
    .order__total td.order__total__row__name--final span {
      text-decoration: underline;
      cursor: pointer;
      font-size: 16px;
      font-size: 1rem;
      font-weight: 400;
      color: #84939f; }
      @media (max-width: 579px) {
        .order__total td.order__total__row__name--final span {
          font-size: 14px;
          font-size: 0.875rem;
          color: #84939f; } }
    .order__total td.order__total__row__name--final p {
      color: #1e1ec8;
      margin: 0; }
  .order__total .order__total__row__price__outer div {
    display: inline-block; }
  .order__total .order__total__row__price {
    font-size: 18px;
    font-size: 1.125rem;
    font-weight: 700;
    color: #000000; }
  .order__total .order__total__row__discount {
    color: #1e1ec8;
    font-size: 18px;
    font-size: 1.125rem;
    font-weight: 700;
    margin-left: 9px;
    white-space: nowrap; }
  .order__total .order__total__row__price--discounted {
    text-decoration: line-through; }
  .order__total td.order__total__row__price--big {
    border-bottom: 1px solid #c8c8c8;
    font-size: 24px;
    font-size: 1.5rem; }
    @media (max-width: 579px) {
      .order__total td.order__total__row__price--big {
        font-size: 22px;
        font-size: 1.375rem; } }
  .order__total td.order__total__row__price--final {
    font-size: 32px;
    font-size: 2rem;
    color: #1e1ec8;
    border-bottom: 0;
    padding-bottom: 0; }
    @media (max-width: 579px) {
      .order__total td.order__total__row__price--final {
        font-size: 26px;
        font-size: 1.625rem; } }

.order__total__loyalty .order__total__row:last-of-type {
  background-color: #d9d9d9; }
  .order__total__loyalty .order__total__row:last-of-type .order__total__row__name.order__total__row__name--big,
  .order__total__loyalty .order__total__row:last-of-type .order__total__row__price.order__total__row__price--big {
    color: #1e1ec8;
    font-size: 24px;
    font-size: 1.5rem; }

.order__total__more__info {
  text-decoration: underline;
  cursor: pointer;
  font-size: 16px;
  font-size: 1rem;
  font-weight: 400;
  color: #84939f; }
  @media (max-width: 579px) {
    .order__total__more__info {
      font-size: 14px;
      font-size: 0.875rem;
      color: #84939f; } }

.order__total__text__inner {
  display: inline; }
  .order__total__text__inner > p {
    display: inline; }

.order__total__parent__more__info {
  display: inline; }
  .order__total__parent__more__info > span {
    font-size: 13px;
    font-size: 0.8125rem; }

/*
.digi-table {
    padding-left: 1rem;
    padding-right: 1rem;
}
.digi-table__row--header {
    background-color: $blue-primary;
    color: white;
    p {
        color: white;
    }
}
.digi-table__row {
    margin-right: -1rem;
    margin-left: -1rem;
    border-radius: 6px;
    display: flex;
    p {
        margin-bottom: 6px;
    }
}
.digi-table__col {
    padding-right: 1rem;
    padding-left: 1rem;
}

.digi-table--invoice {

    .digi-table__row {
        flex-wrap: wrap;
    }
    .digi-table__col {
        flex-basis: 100%;
    }

    .col--1,
    .col--2,
    .col--3,
    .col--4 {
        padding-left: 50%;
        position: relative;
        &:before {
            content: "oooxxxxxxxxxxrrr";
            position: absolute;
            right: 50%;
            top: 0;
            padding-right: 15px;
            color: black;
        }
    }

}
*/
/*
.row,
.digi-table__row {
    &.even {
        background-color: #f2f2f2;
    }
}
.digi-table__row {
    padding-right: 25px;
    padding-left: 25px;
    margin-bottom: 20px;
    border-radius: 6px;
    p {
        margin-bottom: 6px;
    }
}
.digi-table__row--header {
    background-color: $blue-primary;
    color: white;
    p {
        color: white;
    }
}
.digi-table__row__multi {
    display: flex;
    .date {
        flex: 0 0 85px;
    }
    .payment-issue {
        flex: 0 0 100px;
    }
    .payment-note {
        font-weight: 300;
    }
}
.digi-table--adresses {
    .digi-table__row {
        margin-bottom: 0;
        padding-top: 10px;
        padding-bottom: 10px;
    }
    label {
        width: 100%;
    }
}
*/
.pagination {
  display: flex;
  justify-content: center;
  font-size: 15px;
  font-weight: 700;
  margin-top: 30px;
  margin-bottom: 30px; }
  .pagination a:hover {
    text-decoration: underline; }
  .pagination .pagination__prev,
  .pagination .pagination__next {
    padding-left: 28px;
    padding-right: 28px; }
  .pagination .pagination__prev,
  .pagination .pagination__next,
  .pagination .pagination__page {
    border: 1px solid #1e1ec8;
    border-radius: 23px;
    text-decoration: none; }
  .pagination .pagination__prev,
  .pagination .pagination__next,
  .pagination .pagination__page,
  .pagination .pagination__space {
    height: 44px;
    line-height: 44px;
    color: #1e1ec8;
    text-align: center;
    margin-left: 5px;
    margin-right: 5px;
    display: inline-block; }
  .pagination .pagination__space {
    line-height: 62px; }
  .pagination .pagination__page {
    width: 44px; }
    .pagination .pagination__page.active {
      background-color: black;
      border-color: black;
      color: white; }
  .pagination .ico-next:after {
    width: 7px;
    height: 10px;
    background-image: url(../images/ico-more-right-blue.svg); }
  .pagination .ico-prev:after {
    width: 7px;
    height: 10px;
    background-image: url(../images/ico-more-left-blue.svg); }

.services .h2 {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 10px; }

.services .h3 {
  font-size: 20px;
  font-weight: 500; }

.services .ui__loading {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0; }

.services__subtitle {
  margin-bottom: 30px;
  line-height: 1.5; }

.activation {
  padding-top: 46px; }

.packages {
  margin-top: 30px;
  margin-bottom: 70px;
  display: flex;
  flex-wrap: wrap; }

.packages__item {
  padding: 23px;
  border: 1px solid #c8c8c8;
  flex-basis: calc(100% / 3 - 4px);
  margin-bottom: 6px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between; }
  @media (max-width: 1300px) {
    .packages__item {
      text-align: center; } }
  @media (max-width: 1300px) {
    .packages__item .btn.btn--primary {
      width: 100%; } }
  @media (max-width: 1300px) {
    .packages__item button.link {
      margin: 0.5rem 0 0 0;
      text-align: center; } }
  @media (max-width: 1679px) {
    .packages__item button.link {
      margin-left: 0.5rem; } }
  @media (min-width: 1680px) {
    .packages__item button.link {
      margin-left: 1rem; } }
  .packages__item:not(:nth-child(4n)) {
    margin-right: 6px; }
  @media (min-width: 768px) and (max-width: 991px) {
    .packages__item {
      padding: 15px; } }
  @media (max-width: 579px) {
    .packages__item {
      flex-basis: 100%; }
      .packages__item:not(:nth-child(4n)) {
        margin-right: 0; } }

.packages__item--selected {
  background-color: #e8e8f9;
  border: 1px solid #1e1ec8; }

.packages__item--disabled {
  background-color: #eee;
  border: 1px solid #ccc;
  filter: grayscale(100%); }

.packages__item__title {
  font-size: 30px;
  font-size: 1.875rem;
  font-weight: 700; }

.packages__tag {
  position: absolute;
  top: 1rem;
  right: -1px;
  background-color: #ff005a;
  color: #fff;
  font-size: 15px;
  font-size: 0.9375rem;
  padding: 0.3rem 0.5rem; }

.packages__channels {
  margin-top: 1rem; }
  .packages__channels img {
    max-height: 80px;
    margin: 5px 7px 4px 0;
    filter: grayscale(100%); }

.packages__item__price__outer {
  display: flex;
  flex-wrap: wrap;
  padding: 22px 0; }
  @media (max-width: 991px) {
    .packages__item__price__outer {
      justify-content: center; } }

.packages__item__price {
  font-weight: 700;
  color: #1e1ec8;
  font-size: 18px;
  font-size: 1.125rem; }
  .packages__item__price:not(:last-child) {
    margin-right: 13px; }

.packages__item__price--discounted {
  text-decoration: line-through; }

.packages__item__discount {
  color: #ffffff;
  background-color: #1e1ec8;
  border-radius: 14px;
  padding: 1px 13px;
  font-size: 18px;
  font-size: 1.125rem; }
  .packages__item__discount span {
    font-weight: 700; }
  @media (max-width: 1179px) {
    .packages__item__discount {
      font-size: 16px;
      font-size: 1rem; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .packages__item__discount {
      font-size: 15px;
      font-size: 0.9375rem; } }

.supplementary-packages {
  margin-top: 30px;
  margin-bottom: 70px; }

.supplementary-packages__item {
  padding: 28px 23px;
  border: 1px solid #c8c8c8;
  margin-bottom: 7px;
  display: flex;
  justify-content: space-between; }
  @media (max-width: 991px) {
    .supplementary-packages__item {
      flex-wrap: wrap;
      padding: 15px 10px; } }
  .supplementary-packages__item--selected {
    background-color: #e8e8f9;
    border: 1px solid #1e1ec8; }
  .supplementary-packages__item--disabled {
    background-color: #eee;
    border: 1px solid #ccc;
    filter: grayscale(100); }
  .supplementary-packages__item--is-ppv {
    color: #ffffff;
    position: relative;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat; }
    .supplementary-packages__item--is-ppv:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(29, 31, 199, 0.6); }
    .supplementary-packages__item--is-ppv .supplementary-packages__item__inner {
      display: block; }
      .supplementary-packages__item--is-ppv .supplementary-packages__item__inner .supplementary-packages__item__info {
        padding-left: 32px;
        text-align: left; }
        .supplementary-packages__item--is-ppv .supplementary-packages__item__inner .supplementary-packages__item__info .link {
          color: #ffffff; }
      .supplementary-packages__item--is-ppv .supplementary-packages__item__inner span.supplementary-packages__item__name {
        color: #ffffff; }
    .supplementary-packages__item--is-ppv .supplementary-packages__item__price-wrapper .supplementary-packages__item__price {
      color: #ffffff; }

.supplementary-packages__item__inner {
  position: relative;
  display: flex; }
  @media (max-width: 991px) {
    .supplementary-packages__item__inner {
      justify-content: space-between;
      flex-basis: 100%; } }

.supplementary-packages__item__price-wrapper {
  position: relative; }

span.supplementary-packages__item__name {
  font-size: 18px;
  font-weight: 700;
  color: #1e1ec8;
  white-space: nowrap;
  margin-top: 0;
  margin-right: 20px; }
  @media (max-width: 991px) {
    span.supplementary-packages__item__name {
      margin-right: 10px; } }

.supplementary-packages__item__info {
  padding-left: 20px;
  flex-basis: 100%; }
  @media (max-width: 991px) {
    .supplementary-packages__item__info {
      padding-left: 9px;
      text-align: right; } }

.supplementary-packages__item__price__outer {
  display: flex;
  justify-content: flex-end; }
  @media (max-width: 991px) {
    .supplementary-packages__item__price__outer {
      padding-top: 10px;
      flex-basis: 100%;
      text-align: right; } }

.supplementary-packages__item__price {
  justify-self: flex-end;
  color: #1e1ec8;
  font-size: 18px;
  font-size: 1.125rem;
  font-weight: 700; }

.supplementary-packages__item__price--discounted {
  text-decoration: line-through; }

.supplementary-packages__item__discount {
  color: #ffffff;
  background-color: #1e1ec8;
  border-radius: 14px;
  padding: 1px 13px;
  font-size: 18px;
  font-size: 1.125rem;
  margin-left: 13px;
  white-space: nowrap; }
  .supplementary-packages__item__discount span {
    font-weight: 700; }
  @media (max-width: 1179px) {
    .supplementary-packages__item__discount {
      font-size: 16px;
      font-size: 1rem; } }

.portfolio__options {
  display: flex; }

.portfolio__options__item {
  flex-basis: 50%;
  padding: 10px;
  font-size: 18px;
  font-size: 1.125rem;
  border: 1px solid #c8c8c8;
  border-radius: 0;
  box-shadow: none;
  background-color: #ffffff;
  color: #000000; }
  .portfolio__options__item:not(:last-child) {
    border-right: 0; }

.portfolio__options__item--selected {
  background-color: #1e1ec8;
  color: #ffffff; }

.portfolio__content {
  border: 1px solid #c8c8c8;
  border-top: 0;
  padding: 37px 20px; }
  @media (max-width: 991px) {
    .portfolio__content {
      padding: 16px 8px; } }

.portfolio__content__info u {
  cursor: pointer; }

.portfolio__content__text {
  font-weight: 700;
  margin-bottom: 10px;
  font-size: 18px;
  font-size: 1.125rem;
  color: #000000; }
  .portfolio__content__text u {
    cursor: pointer; }

.portfolio__content__text--lonely {
  margin-bottom: 30px; }

.portfolio__content__subtitle {
  margin-bottom: 30px; }

.portfolio__content__subtitle--warning {
  color: #b70f0f;
  font-weight: 700; }

.portfolio__content__item {
  margin-bottom: 20px; }
  .portfolio__content__item:only-child {
    margin-bottom: 0; }
  .portfolio__content__item:last-child {
    margin-bottom: 0; }

.portfolio__content__item__content {
  display: flex;
  justify-content: space-between;
  position: relative; }
  @media (max-width: 991px) {
    .portfolio__content__item__content {
      flex-wrap: wrap; } }

span.portfolio__content__item__name {
  white-space: nowrap;
  margin-top: 0;
  margin-right: 20px;
  font-size: 18px;
  font-size: 1.125rem; }
  @media (max-width: 991px) {
    span.portfolio__content__item__name {
      font-size: 16px;
      font-size: 1rem; } }

span.portfolio__content__item__info {
  width: 20px;
  height: 20px;
  display: inline-block;
  border-radius: 50%;
  border: 1px solid #c8c8c8;
  color: #c8c8c8;
  font-weight: 700;
  text-align: center;
  line-height: 19px;
  cursor: pointer;
  margin-left: 10px;
  text-decoration: none; }
  span.portfolio__content__item__info--white {
    color: #ffffff;
    border-color: #ffffff; }

.portfolio__content__item__dialog {
  position: absolute;
  bottom: 100%;
  left: calc(100% - 110px);
  margin-bottom: 7px;
  text-align: center;
  padding: 4px 10px;
  width: 200px;
  border-radius: 6px;
  color: #ffffff;
  background-color: #707070;
  opacity: 0.9; }
  @media (max-width: 991px) {
    .portfolio__content__item__dialog {
      right: 0;
      left: auto;
      margin-right: -10px; } }
  .portfolio__content__item__dialog:after {
    content: ' ';
    position: absolute;
    top: 100%;
    right: calc(50% - 5px);
    border-width: 5px;
    border-style: solid;
    border-color: #707070 transparent transparent transparent; }
    @media (max-width: 991px) {
      .portfolio__content__item__dialog:after {
        margin-left: -5px;
        right: 15px; } }

.portfolio__content__item__name {
  position: relative; }

.portfolio__content__item__price {
  font-weight: 700; }
  @media (max-width: 991px) {
    .portfolio__content__item__price {
      text-align: right;
      padding-top: 10px;
      flex-basis: 100%; } }

.portfolio__content__item__inner {
  display: flex;
  flex-basis: 30%;
  justify-content: space-between; }
  @media (max-width: 1679px) {
    .portfolio__content__item__inner {
      flex-basis: 40%; } }
  @media (max-width: 991px) {
    .portfolio__content__item__inner {
      padding-top: 8px;
      flex-basis: 100%; } }

.instalation__item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px; }
  @media (max-width: 991px) {
    .instalation__item {
      flex-wrap: wrap; } }
  .instalation__item:last-child {
    margin-bottom: 0; }

.installation__item__price {
  font-weight: 700; }
  @media (max-width: 991px) {
    .installation__item__price {
      padding-top: 8px;
      flex-basis: 100%;
      text-align: right; } }

.list__item {
  font-weight: 500;
  display: flex;
  /*
  &.list__item--order {
      margin-top: 15px;
      margin-bottom: 15px;
  }*/ }
  .list__item.list__item--active {
    font-weight: 600; }
    .list__item.list__item--active * {
      font-weight: inherit; }

.list__item__info {
  font-weight: 500; }
  .list__item__info.list__item__info--active {
    font-weight: 600; }

.updown__header {
  display: flex;
  align-items: center;
  margin-top: 25px;
  margin-bottom: 15px; }
  .updown__header a {
    color: #1e1ec8;
    text-decoration: none; }
    .updown__header a:hover {
      text-decoration: underline; }
  .updown__header h3 {
    flex: 1;
    color: black;
    font-size: 17px;
    margin-bottom: 0;
    position: relative;
    margin-right: 15px; }
    .updown__header h3:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 1px;
      left: 0;
      top: 50%;
      background-color: #eaeaea; }
    .updown__header h3 span {
      background-color: white;
      padding-right: 10px;
      position: relative; }

.list--inkaso {
  width: 100%; }

.list__invoice {
  display: flex; }
  .list__invoice .form__item:first-child {
    flex-basis: 200px; }
  .list__invoice .form__item:nth-child(2) {
    flex-basis: calc(100% - 325px); }
  .list__invoice .form__item:last-child {
    flex-basis: 125px; }

.updown__header h3,
.updown__content h3 {
  font-weight: 600; }

.updown__header a,
.updown__header label,
.updown__content a,
.updown__content label {
  font-weight: 500; }

.slide_switch {
  position: relative;
  padding-right: 13px;
  display: inline-block; }
  .slide_switch:after {
    content: '';
    position: absolute;
    right: 0;
    top: 50%;
    margin-top: -2px;
    background-image: url(../images/ico-up.svg);
    width: 9px;
    height: 6px; }

.closed .slide_switch:after {
  transform: scaleY(-1); }

.closed .updown__content {
  height: 0;
  overflow: hidden; }

.tags {
  margin-left: -10px;
  margin-right: -10px; }

.tag {
  margin: 0 10px; }
  .tag.tag--active {
    color: #8eeb00; }
  .tag.tag--discount {
    color: #b70f0f; }
  .tag.tag--price {
    color: #000000; }
  .tag.tag--old-price {
    color: green;
    text-decoration: line-through; }

.order__list {
  border-top: 1px solid #eaeaea;
  border-bottom: 1px solid #eaeaea;
  margin: 15px 0;
  padding: 5px 0; }

.confirmation-wrap {
  text-align: center; }

.confirmation {
  padding: 50px 0;
  display: inline-block;
  text-align: left; }

.confirmation__header {
  display: inline-block; }

.offer > .list__row {
  justify-content: space-between;
  flex-wrap: nowrap; }

.offer .list__col--label {
  padding-right: 6px; }

.offer .list__col--info {
  padding-left: 0;
  flex: 1 1 auto; }

@media (max-width: 579px) {
  .list--modal .list__col {
    flex-basis: 100%;
    margin-bottom: 10px; } }

.validation--error {
  border: 1px solid #b70f0f; }
  .validation--error:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    background-color: yellow;
    width: 100%;
    height: 100%; }

.validation--message {
  position: absolute;
  left: 0;
  bottom: calc(100% + 6px);
  border-radius: 4px;
  color: #b70f0f;
  font-weight: 600;
  padding: 5px 10px;
  background-color: #ffc2ba;
  font-size: 14px;
  font-size: 0.875rem; }
  .validation--message:before {
    content: '';
    position: absolute;
    left: 20px;
    bottom: -5px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 5px 5px 0 5px;
    border-color: #ffc2ba transparent transparent transparent; }

.validation--block {
  font-weight: 600;
  padding: 5px 10px;
  border-radius: 4px; }
  .validation--block h3 {
    color: inherit;
    margin: 10px 0; }

.alert {
  background-color: #ffc2ba;
  color: #b70f0f; }
  .alert.alert--full {
    width: 100%; }

.success {
  background-color: #dff0d4;
  color: #2c7635; }

.info {
  background-color: #d1e4f1;
  color: #1e1ec8; }

.flash {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9991;
  transition: transform 0.5s ease-in-out;
  transform: translate(0, -100%); }
  .flash .button-close {
    color: white;
    top: 20px;
    right: 20px; }
    .flash .button-close:after {
      background-image: url(../images/ico-close-white.svg); }
  .flash .alert {
    position: relative;
    margin-bottom: 5px;
    padding: 20px 10px;
    color: white; }
    .flash .alert h2 {
      color: inherit;
      margin-bottom: 5px;
      margin-top: 15px;
      font-size: 18px;
      font-weight: 500; }
    .flash .alert ul {
      margin-top: 0;
      margin-bottom: 0;
      padding-left: 18px; }

.flash-down {
  transform: translate(0, 0);
  margin-top: 0; }

.alert--error {
  background-color: #940000;
  color: white;
  box-shadow: 0 2px 2px 0 #7a0000; }

.alert--success {
  background-color: #009b12;
  color: white;
  box-shadow: 0 2px 2px 0 #079726; }

.alert--warning {
  background-color: #ec7e00;
  color: white;
  box-shadow: 0 2px 2px 0 #7a0000; }

.alert--info {
  background-color: #1e1ec8;
  color: white;
  box-shadow: 0 2px 2px 0 #7a0000; }

.check {
  background-repeat: no-repeat;
  background-position: center center;
  height: 150px;
  margin: 15px 0; }

.check--success {
  background-image: url(../images/check.svg); }

.ReactModalPortal {
  position: relative;
  z-index: 51; }

.popup-headline {
  margin-bottom: 28px; }

.modal--call-me label {
  font-weight: normal; }

.modal-body {
  margin-bottom: 40px; }

.modal__body {
  padding: 48px 46px;
  background-color: #e8e8f9; }
  .modal__body .modal__title {
    margin-bottom: 26px; }
  .modal__body p {
    font-weight: 600; }

.modal__title {
  font-size: 28px;
  font-size: 1.75rem;
  color: #1e1ec8;
  margin-bottom: 0;
  font-weight: 700; }

.modal__subtitle {
  margin-bottom: 20px;
  display: block; }
  .modal__subtitle p {
    font-weight: 500; }

.modal__subtitle--blue {
  color: #1e1ec8; }

.modal__actions {
  padding-top: 20px;
  display: flex;
  justify-content: space-between; }

.channel__list h2 {
  font-size: 17px;
  font-size: 1.0625rem;
  color: #1e1ec8;
  font-weight: 700;
  margin-top: 1.5em; }

.channel__list div {
  display: flex;
  flex-wrap: wrap; }
  .channel__list div > span {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 8px 0; }
    @media (max-width: 767px) {
      .channel__list div > span {
        flex-basis: calc(100% / 2); } }
    @media (min-width: 768px) {
      .channel__list div > span {
        flex-basis: calc(100% / 4); } }
    .channel__list div > span img {
      width: auto;
      object-fit: contain;
      object-position: left;
      height: auto;
      max-width: 50px;
      max-height: 30px;
      display: block;
      padding: 5px 15px 5px 0; }

.panel {
  background-color: white;
  max-height: 0;
  transition: max-height 0.2s ease-out; }
  .panel:not(.panel--open) {
    overflow: hidden; }

.panel--open {
  max-height: none; }

/*
.address-form {
    .panel {
        padding-top: 32px;
        margin-top: -32px;
    }
}
*/
.info__box--invoices {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 10px; }
  .info__box--invoices p {
    margin-bottom: 0; }
  .info__box--invoices > div {
    margin: 15px; }

.status {
  font-weight: 500; }

.payment-result--ok h2 {
  font-size: 24px; }

.payment-result--ok p {
  font-weight: 400;
  font-size: 20px; }
  .payment-result--ok p strong {
    font-weight: 500; }

.info__box--documents {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 10px; }
  .info__box--documents p {
    margin-bottom: 0; }
  .info__box--documents > div {
    margin: 15px; }

.inbox ul {
  margin: 0;
  padding: 0; }
  .inbox ul li {
    list-style-type: none; }

.inbox__message {
  padding-left: 60px;
  margin-bottom: 15px; }
  .inbox__message p {
    margin: 0; }

.inbox__message__header {
  margin-bottom: 5px;
  display: flex;
  justify-content: space-between; }

.inbox__message__header__title,
.inbox__message__header__author {
  font-size: 18px;
  font-size: 1.125rem;
  font-weight: 500; }

.inbox__message__header__title {
  color: #1e1ec8; }

.inbox__list a,
.inbox__message__header a {
  text-decoration: none;
  color: inherit;
  font-weight: inherit;
  font-size: inherit; }
  .inbox__list a:hover .inbox__message__header__title,
  .inbox__message__header a:hover .inbox__message__header__title {
    text-decoration: underline; }

.inbox__list .unread .inbox__message__header__title {
  position: relative; }
  .inbox__list .unread .inbox__message__header__title:before {
    content: '';
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #1e1ec8;
    position: absolute;
    left: -20px;
    top: 5px; }

.inbox__filter {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 20px; }

.message__header__headline {
  display: flex;
  justify-content: space-between; }

.message__header {
  border-bottom: 1px solid #eaeaea;
  margin-bottom: 20px; }
  .message__header .message__header__label {
    margin-top: 10px;
    margin-bottom: 15px; }

.Toastify__toast-container--top-center {
  top: 0; }

.Toastify__toast {
  padding: 15px;
  margin-bottom: 4px;
  font-family: inherit; }
  .Toastify__toast .button-close {
    position: relative;
    top: 0;
    right: 0; }

.Toastify__toast--info .button-close,
.Toastify__toast--success .button-close,
.Toastify__toast--warning .button-close,
.Toastify__toast--error .button-close {
  color: white; }
  .Toastify__toast--info .button-close:after,
  .Toastify__toast--success .button-close:after,
  .Toastify__toast--warning .button-close:after,
  .Toastify__toast--error .button-close:after {
    background-image: url(../images/ico-close-white.svg); }

.Toastify__toast--success {
  color: #000000;
  background-color: #8eeb00; }
  .Toastify__toast--success .button-close {
    color: black; }
    .Toastify__toast--success .button-close:after {
      background-image: url(../images/ico-close.svg); }

.form__bank__details .form__item, .form__bank__details input, .form__bank__details select {
  display: inline; }
  .form__bank__details .form__item[name="bankPre"], .form__bank__details input[name="bankPre"], .form__bank__details select[name="bankPre"] {
    width: calc(578px * 0.3); }
  .form__bank__details .form__item[name="bankAccount"], .form__bank__details input[name="bankAccount"], .form__bank__details select[name="bankAccount"] {
    width: calc(578px * 0.4); }
  .form__bank__details .form__item[name="bankCode"], .form__bank__details input[name="bankCode"], .form__bank__details select[name="bankCode"] {
    width: calc(578px * 0.22); }

.react-datepicker {
  border-color: #c8c8c8;
  border-radius: 0; }

.react-datepicker__header {
  background-color: #ffffff;
  border-color: #c8c8c8; }

.react-datepicker__triangle {
  border-bottom-color: #ffffff; }

.react-datepicker__day:focus {
  box-shadow: 0 0 0 1pt #A6C7FF;
  outline: none; }

.react-datepicker__day--selected {
  background-color: #1e1ec8; }
  .react-datepicker__day--selected:focus {
    box-shadow: 0 0 0 1pt #A6C7FF;
    outline: none; }

.react-datepicker__day--keyboard-selected {
  background-color: #11118f; }
  .react-datepicker__day--keyboard-selected:focus {
    box-shadow: 0 0 0 1pt #A6C7FF;
    outline: none; }

.react-datepicker__current-month {
  color: #1e1ec8;
  text-transform: capitalize; }

.react-datepicker__navigation--previous:hover {
  border-right-color: #1e1ec8; }

.react-datepicker__navigation--next:hover {
  border-left-color: #1e1ec8; }

.react-datepicker__year-read-view--down-arrow {
  display: none; }

.react-datepicker__year-read-view--selected-year {
  font-size: 18px;
  font-size: 1.125rem;
  font-weight: 700; }

.react-datepicker__year-select {
  height: 30px;
  font-size: 14px;
  font-size: 0.875rem;
  padding: 0 22px 0 18px;
  background-position: calc(100% - 5px) 50%;
  text-align: left; }

.reward {
  padding: 2rem;
  color: #000000;
  height: 100%;
  position: relative;
  min-height: 360px; }
  .reward h2 {
    position: relative;
    margin: 0; }
  .reward p {
    position: relative;
    color: #1e1ec8;
    margin-top: 2rem; }
  .reward img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; }
  .reward .btn {
    position: absolute;
    bottom: 2rem;
    left: 2rem; }

@media (min-width: 1180px) {
  .reward__big {
    margin-right: 320px; } }

.reward__more {
  background-color: #e8e8f9;
  background-image: url(../images/ico_gift_blue.png);
  padding: 26px 26px 26px 100px;
  background-repeat: no-repeat;
  background-size: 50px;
  background-position: left 20px center;
  color: #1e1ec8;
  font-weight: 700;
  height: 100%; }

.subreward {
  padding: 2rem;
  border: 2px solid #d9d9d9;
  height: 100%; }
  .subreward h3 {
    font-size: 20px;
    font-size: 1.25rem;
    font-weight: 700;
    margin-bottom: 1rem; }
  .subreward.selected {
    border: 2px solid #1e1ec8; }
  .subreward.disabled {
    opacity: 0.5; }
  .subreward span {
    display: inline-block;
    margin-top: 0.25rem; }
  .subreward .btn {
    margin-top: 2rem;
    font-weight: 700; }

.subreward__voucher {
  background-color: #ffffff;
  padding: 16px 32px;
  position: relative;
  display: inline-block;
  margin-top: 20px;
  color: #1e1ec8;
  font-weight: 700; }
  .subreward__voucher:before, .subreward__voucher:after {
    content: '';
    position: absolute;
    top: 50%;
    margin-top: -16px;
    border: 16px solid #e8e8f9;
    border-radius: 100%; }
  .subreward__voucher:before {
    left: -16px; }
  .subreward__voucher:after {
    right: -16px; }

.subreward--choosen {
  background-color: #e8e8f9;
  border: 0;
  height: 100%; }

.subreward__price {
  text-decoration: line-through;
  font-weight: 700; }

.subreward__info {
  font-weight: 700;
  color: #eb345e; }

.reward-selection-alert {
  padding: 1rem;
  background-color: #eb345e;
  color: #ffffff;
  margin-bottom: 1rem; }

.loyalty-reward-info {
  padding: 1rem 1rem 1rem 3.4rem;
  background-color: #eaeaea;
  background-image: url(../images/ico_gift.svg);
  background-repeat: no-repeat;
  background-position: left 1.25rem center; }

.loyalty__countdown {
  background: #eb345e;
  color: #ffffff;
  padding: 20px 30px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  height: 100%; }

.loyalty__countdown__title {
  display: block;
  font-size: 25px;
  font-size: 1.5625rem;
  line-height: 1; }

.loyalty__countdown__subtitle {
  font-size: 16px;
  font-size: 1rem;
  font-weight: 400;
  margin: 0; }

.loalty__countdown__timer {
  display: flex;
  justify-content: space-between;
  text-align: center;
  padding-left: 1rem; }
  .loalty__countdown__timer span {
    line-height: 1;
    min-width: 60px;
    font-size: 14px;
    font-size: 0.875rem; }
    .loalty__countdown__timer span span {
      font-size: 30px;
      font-size: 1.875rem;
      font-weight: 700; }

.reward__countdown {
  right: 2rem;
  top: 2rem; }
  @media (max-width: 1179px) {
    .reward__countdown {
      position: relative;
      max-width: 300px;
      margin-left: 2rem; } }
  @media (min-width: 1180px) {
    .reward__countdown {
      position: absolute; } }
  .reward__countdown .loyalty__countdown {
    padding: 16px; }
  .reward__countdown .loyalty__countdown__title {
    text-align: center;
    width: 100%; }
  .reward__countdown .loyalty__countdown__subtitle {
    font-weight: 700;
    font-size: 18px;
    font-size: 1.125rem;
    margin-bottom: 0.5rem;
    display: block; }
  .reward__countdown .loalty__countdown__timer {
    padding: 0;
    width: 100%; }
    .reward__countdown .loalty__countdown__timer span {
      min-width: 40px; }

.loyalty__partners__item {
  background-color: #e8e8f9;
  padding: 40px; }
  .loyalty__partners__item img {
    max-width: 50%; }

.loyalty__partners__desc {
  margin-bottom: 0;
  padding-bottom: 8px; }

.loyalty__partners__image {
  border: 8px solid #e8e8f9; }
  .loyalty__partners__image img {
    display: block; }

.loyalty__banner {
  background-image: url("../images/tellyklub.jpg");
  background-size: cover;
  background-position: center;
  height: 100%;
  text-align: left;
  border: none;
  position: relative; }
  .loyalty__banner h2 {
    font-size: 32px;
    font-size: 2rem; }
  .loyalty__banner p {
    background-color: #eb345e;
    color: #ffffff;
    font-weight: 600;
    display: inline-block;
    padding: 1rem 2rem;
    font-size: 17px;
    font-size: 1.0625rem;
    text-decoration: none;
    transition-duration: 0.2s; }

/* animations */
.fade-enter {
  opacity: 0;
  height: 0; }

.fade-enter-active {
  opacity: 1;
  transition: opacity 200ms; }

.fade-enter-done {
  opacity: 1;
  transition: opacity 200ms; }

.fade-exit {
  opacity: 1;
  height: 32px; }

.fade-exit-active {
  opacity: 0;
  height: 32px;
  transition: opacity 200ms; }

.fade-exit-done {
  opacity: 0;
  height: 0;
  transition: opacity 200ms; }

/*  */
@media (max-width: 579px), (min-width: 1180px) and (max-width: 1380px) {
  .list.list--6--2 .list__row {
    flex-wrap: wrap; }
    .list.list--6--2 .list__row .list__col {
      flex-basis: 100%; }
      .list.list--6--2 .list__row .list__col:not(:last-child) {
        margin-bottom: 10px; } }

@media (max-width: 579px) {
  .admin__inner article time {
    width: auto; }
    .admin__inner article time br {
      display: none; }
  .btn--block--xs {
    display: block;
    width: 100%; }
  .admin__user a:not(:last-child) {
    margin-right: 16px; }
  .admin__menu__wrap {
    padding-left: calc(1rem + 80px); }
  .offer > .list__row {
    flex-wrap: wrap; }
  .offer .list__col--info {
    padding-left: 10px;
    flex-basis: 100%;
    order: 3; }
  .offer-step--1 .list__col--info button {
    margin-left: 27px; }
  .footline {
    flex-wrap: wrap;
    display: flex; }
    .footline button {
      flex-basis: 100%; }
    .footline .separator {
      display: none; }
  .footline--btn button:not(:last-child) {
    margin-bottom: 1rem; }
  .footline--nextprev button {
    margin-bottom: 1rem; }
    .footline--nextprev button:first-child {
      order: 2; }
  .digi-table--services .cell__label {
    width: 43%; }
  .digi-table--services .cell__content {
    width: 57%; }
  .ReactModal__Content {
    padding: 20px !important; }
    .ReactModal__Content .button-close {
      top: 15px;
      right: 15px; }
  .list.align-center li,
  .list.align-center div {
    align-items: flex-start; }
  .btn--next,
  .btn--next {
    padding-left: 45px; } }

@media (min-width: 480px) {
  .with-buttons li {
    min-height: 43px; } }

@media (min-width: 580px) {
  .footline button:not(:last-child) {
    margin-right: 10px; } }

@media (min-width: 580px) and (max-width: 767px) {
  .admin__user a:not(:last-child) {
    margin-right: 25px; }
  .admin__menu__wrap {
    padding-left: calc(1rem + 121px); } }

@media (min-width: 580px) and (max-width: 767px) {
  .form--call-me .row--2 .col:last-child {
    flex: 0 0 210px; } }

@media (max-width: 767px) {
  body {
    font-size: 15px;
    font-size: 0.9375rem; }
  .list__label {
    font-size: 16px;
    font-size: 1rem; }
  .inbox__message {
    padding-left: 35px; }
  .flash .alert {
    padding: 25px 10px 10px 15px; }
  .flash .button-close {
    top: 12px;
    right: 12px; }
  .logo-phone {
    margin-top: 2px;
    margin-right: 16px; }
    .logo-phone img {
      display: block; }
  .admin__content {
    padding-top: 0; }
    .admin__content h1 {
      font-size: 16px;
      font-size: 1rem;
      margin-top: 15px; }
  .admin__menu__wrap {
    margin-left: -1rem;
    margin-right: -1rem;
    padding-right: 1rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    background-color: #1e1ec8; }
  .admin__user a {
    height: 25px;
    background-size: contain; }
    .admin__user a.call-me {
      width: 25px; }
      .admin__user a.call-me:before {
        background-image: url(../images/ico-phone.svg); }
    .admin__user a.user {
      width: 21px; }
      .admin__user a.user:before {
        background-image: url(../images/ico_uzivatel-phone.svg); }
  .admin__user__info {
    display: inline; }
  .admin__menu__button {
    position: absolute;
    top: 32px;
    left: 1rem;
    z-index: 1;
    line-height: 25px;
    color: white;
    font-weight: 300; }
    .admin__menu__button:before {
      content: '';
      width: 25px;
      height: 25px;
      background-size: contain;
      background-image: url(../images/ico_menu-phone.svg); }
  .admin__menu__closer {
    top: 32px; } }

@media (max-width: 991px) {
  .admin__menu__wrap {
    display: flex;
    justify-content: flex-end;
    align-items: center; }
  .footline--contact {
    margin-top: 25px;
    margin-bottom: 40px; }
  .confirmation {
    text-align: center; } }

@media (max-width: 767px) {
  .logout {
    width: 25px; }
    .logout:before {
      background-image: url(../images/ico_logout-phone.svg); } }

@media (min-width: 1180px) {
  .logout {
    padding: 10px 20px; } }

@media (min-width: 1180px) and (max-width: 1679px) {
  .admin__menu > a {
    margin-left: 40px; } }

@media (min-width: 768px) and (max-width: 1679px) {
  .admin__content h1 {
    font-size: 30px;
    font-size: 1.875rem; } }

@media (min-width: 768px) and (max-width: 1179px) {
  .admin__user__info {
    display: inline; }
  .admin__user a {
    display: inline-block;
    width: 20px;
    height: 20px; }
    .admin__user a:before {
      background-position: center center; }
    .admin__user a.logout {
      width: 25px;
      background-color: transparent; }
      .admin__user a.logout:before {
        background-image: url(../images/ico_logout-blue.svg); }
  .admin__user a:not(:last-child) {
    margin-right: 6px; } }

@media (min-width: 768px) {
  body {
    font-size: 16px;
    font-size: 1rem; }
  .list__label {
    font-size: 17px;
    font-size: 1.0625rem; }
  .logo-phone {
    display: none; }
  .form--call-me .row--2 .col:first-child {
    flex: 1; }
  .form--call-me .row--2 .col:last-child {
    flex: 0 0 210px; }
  .admin__user .logout {
    color: #fff;
    background-color: #1e1ec8;
    font-weight: 400;
    text-transform: none; } }

@media (min-width: 1180px) {
  .admin__user .logout {
    padding: 6px 18px;
    flex-shrink: 0; }
    .admin__user .logout:before {
      background-position: calc(100% - 10px) center; }
  .admin__user .call-me {
    margin-bottom: 25px; }
    .admin__user .call-me:before {
      background-position: left center; }
  .admin__user .user {
    margin-right: 12px; }
  .admin__user .user,
  .admin__user .call-me {
    padding-left: 24px; }
  .admin__user__info {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    text-align: left; }
  .admin__content {
    /*
    header + div.banner {
        margin-bottom: 0;
    }
    */ }
    .admin__content > header {
      margin-bottom: 50px;
      align-items: flex-end; }
      .admin__content > header h1 {
        margin-bottom: 0; } }

@media (max-width: 479px) {
  .list--payment-setup .list__col {
    flex-basis: 100%; }
  .list--payment-setup .list__action .btn {
    margin: 15px 0 25px 0;
    width: 100%; } }

@media (max-width: 767px), (min-width: 768px) and (max-width: 868px) {
  .list__row--col--setting .list__value {
    flex-basis: 100%; }
  .list__row--input .list__value {
    padding-left: 35px; } }

.list__label--payment-success {
  flex-grow: 1; }

/* debug */
.debug__notices {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 999;
  cursor: pointer;
  width: 60px;
  height: 40px;
  background-color: #f00; }
  .debug__notices:before {
    font-size: 18px;
    font-size: 1.125rem;
    position: absolute;
    text-align: center;
    color: #fff;
    line-height: 40px;
    left: 0;
    width: 60px;
    top: 0;
    font-weight: 700; }
    @media (max-width: 359px) {
      .debug__notices:before {
        content: 'IS-1'; } }
    @media (min-width: 360px) and (max-width: 479px) {
      .debug__notices:before {
        content: 'XS-2'; } }
    @media (min-width: 480px) and (max-width: 579px) {
      .debug__notices:before {
        content: 'XM-3'; } }
    @media (min-width: 580px) and (max-width: 767px) {
      .debug__notices:before {
        content: 'TS-4'; } }
    @media (min-width: 768px) and (max-width: 991px) {
      .debug__notices:before {
        content: 'SM-5'; } }
    @media (min-width: 992px) and (max-width: 1179px) {
      .debug__notices:before {
        content: 'MD-6'; } }
    @media (min-width: 1180px) {
      .debug__notices:before {
        content: 'LG-7'; } }

.debug__login {
  padding: 0 10px;
  position: fixed;
  bottom: 0;
  left: 60px;
  z-index: 999;
  cursor: pointer;
  display: block;
  line-height: 40px;
  background-color: #000;
  text-decoration: none;
  color: #fff; }
  .debug__login:hover {
    color: #fff;
    background-color: #5a5a5a; }
