.Toastify__toast-container--top-center {
    top: 0;
}

.Toastify__toast {
    padding: 15px;
    margin-bottom: 4px;
    font-family: inherit;
    .button-close {
        position: relative;
        top: 0;
        right: 0;
    }
}

.Toastify__toast--info,
.Toastify__toast--success,
.Toastify__toast--warning,
.Toastify__toast--error {
    .button-close {
        color: white;
        &:after {
            background-image: url(../images/ico-close-white.svg);
        }
    }
}

.Toastify__toast--success {
    color: $black;
    background-color: $green;
    .button-close {
        color: black;
        &:after {
            background-image: url(../images/ico-close.svg);
        }
    }
}
