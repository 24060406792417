.info__box--invoices {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 10px;
    p {
        margin-bottom: 0;
    }
    > div {
        margin: 15px;
    }
}
.status {
    font-weight: 500;
}
.payment-result--ok {
    h2 {
        font-size: 24px;
    }
    p {
        font-weight: 400;
        font-size: 20px;
        strong {
            font-weight: 500;
        }
    }
}