#root {
	position: relative;
}

/**
	Main menu
 */
.admin {
	position: relative;
}

.admin__menu__closer {
	@media (min-width: $screen-sm) {
		display: none;
	}
	z-index: 100;
	border: 0;
	text-transform: uppercase;
	background-color: transparent;
	font-weight: $fw-600;
	right: 15px;
	top: 15px;
	position: absolute;
	width: 20px;
	height: 20px;
	background-size: 20px;
	background-image: url(../images/menu_close.svg);
	background-repeat: no-repeat;
}

.admin__menu {
	@media (max-width: $screen-xs-max) {
		width: 100%;
		left: -100%;
		overflow-y: auto;
		height: calc(100vh + 16px);
		min-height: auto;
	}
	@media (min-width: $screen-lg) {
		width: $left-nav-with;
	}

	@media (min-width: $screen-lg) and (max-width: $screen-lg-max) {
		width: $left-nav-witdth-tablet;
	}

	@media (min-width: $screen-sm) and (max-width: $screen-md-max) {
		width: $left-nav-witdth-mobile;
	}

	@media (min-width: $screen-xm) and (max-width: $screen-ts-max) {
		width: $left-nav-witdth-mobile;
		left: -$left-nav-witdth-mobile;
	}

	> a {
		display: inline-block;
		margin-left: 70px;
		margin-bottom: 25px;
		@media (max-width: $screen-md-max) {
			margin-left: 20px;
		}
	}

	&.active {
		left: 0;
	}

	z-index: 50;
	transition-duration: 0.5s;
	background-color: $blue-primary;
	min-height: 100%;
	padding-top: 60px;
	position: absolute;
	left: 0;
	top: 0;

	nav {
		ul {
			padding: 0;
			margin: 0;
			list-style: none;
		}
	}

	a,
	button {
		@include fs(17);
		font-weight: $fw-400;
		color: $white;
	}
}

.contract_selector {
	color: white;

	label {
		color: inherit;
		font-weight: 500;
		margin-bottom: 5px;
		display: block;
	}

	select {
		height: 40px;
		font-weight: 500;
		color: #00508c;

		option {
			color: black;
		}
	}
}

.menu__first {
	position: relative;
	padding: 25px 70px;

	@media (min-width: $screen-lg) and (max-width: $screen-lg-max) {
		padding: 25px 40px;
	}

	@media (max-width: $screen-md-max) {
		padding: 20px 20px;
	}

	&:last-of-type {
		@media (max-width: $screen-xm-max) {
	    padding-bottom: 100px;
		}
	}


	z-index: 5;

	&.active {
		background-color: $blue-secondary;

		&:before {
			content: '';
			position: absolute;
			right: 0;
			top: 0;
			width: 7px;
			height: 100%;
			background-color: $yellow;
		}
	}

	ul {
		li {
			margin: 10px 0;
		}

		a {
			color: $white;
			position: relative;
			padding-left: 10px;
			display: inline-block;
			font-weight: 400;
			text-decoration: none;

			&:before {
				content: '–';
				position: absolute;
				left: 0;
			}

			&:hover {
				color: $green;
			}

			&.active {
				color: $yellow;
			}
		}
	}

	.subnav-title {
		margin-bottom: 12px;
		position: relative;
		display: inline-block;
		text-decoration: none;
		text-align: left;
		@include fs(28);
		line-height: 1;
		font-weight: 700;

		&.tv {
			padding-right: 30px;

			&:after {
				content: '';
				position: absolute;
				right: 0;
				bottom: 4px;
				background-image: url(../images/ico-tv.svg);
				width: 25px;
				height: 25px;
				background-size: 25px;
			}
		}

		&:hover {
			color: $green;

			&:after {
				background-image: url(../images/ico-tv-green.svg);
			}
		}
	}
}

/**
Global administration components
 */


.admin__content {
	padding-top: 25px;
	padding-bottom: 30px;

	@media (min-width: $screen-lg) {
		padding-left: $left-nav-with;
	}

	@media (min-width: $screen-sm) and (max-width: $screen-md-max) {
		padding-left: $left-nav-witdth-mobile;
	}

	@media (min-width: $screen-lg) and (max-width: $screen-lg-max) {
		padding-left: $left-nav-witdth-tablet;
	}


	min-height: 100vh;

	&.admin__content--no-nav {
		padding-left: 0;
	}

	h1 {
		color: #84939f;
		padding-right: 22px;

		a {
			color: #1e1ec8;
			text-decoration: underline;
			font-weight: $fw-300;

			&:hover {
				color: darken(#84939f, 20%);
			}
		}

		.admin__content__separator {
			color: $black;
			@media (max-width: $screen-xm-max) {
				margin: 0 4px;
			}
			@media (min-width: $screen-ts) {
				margin: 0 25px;   
			}
		}

		.admin__content__subtitle {
			vertical-align: baseline;
			color: $blue-primary;
			font-weight: $fw-700;
			@media (max-width: $screen-xm-max) {
				letter-spacing: -0.2px;
			}
		}
	}
}

.footer--note {
	padding-top: 20px;
	@include fs(14);
}

.pin-confirmation--inline {
	display: flex;
	align-items: center;
}

.pin-confirmation {

	&.pin-confirmation--remove {
		.form__item {
			margin-left: 0;
			display: inline-block;
			width: 100px;
		}
	}

	.form__item {
		margin-left: 10px;
		margin-right: 10px;
		position: relative;
		text-align: center;
		flex: 0 0 110px;
		width: 100%;
	}
}
