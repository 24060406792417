.react-datepicker {
  border-color: $border-gray-light;
  border-radius: 0;
}

.react-datepicker__header {
  background-color: $white;
  border-color: $border-gray-light;
}

.react-datepicker__triangle {
  border-bottom-color: $white ;
}


.react-datepicker__day {
  &:focus {
    box-shadow: 0 0 0 1pt #A6C7FF;
    outline: none;
  }
}

.react-datepicker__day--selected {
  background-color: $blue-primary;
  &:focus {
    box-shadow: 0 0 0 1pt #A6C7FF;
    outline: none;
  }
}

.react-datepicker__day--keyboard-selected {
  background-color: $btn-primary-hover;
  &:focus {
    box-shadow: 0 0 0 1pt #A6C7FF;
    outline: none;
  }
}

.react-datepicker__current-month {
  color: $blue-primary;
  text-transform: capitalize;
}

.react-datepicker__navigation--previous {
  &:hover {
    border-right-color: $blue-primary;
  }
}

.react-datepicker__navigation--next {
  &:hover {
    border-left-color: $blue-primary;
  }
}

.react-datepicker__year-read-view--down-arrow {
  display: none;
}

.react-datepicker__year-read-view--selected-year {
  @include fs(18);
  font-weight: 700;
}

.react-datepicker__year-select {
  height: 30px;
  @include fs(14);
  padding: 0 22px 0 18px;
  background-position: calc(100% - 5px) 50%;
  text-align: left;
}
