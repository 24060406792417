input {
  &:focus {
    box-shadow: 0 0 0 1pt #a6c7ff;
    outline: none;
  }
}

button {
  &:focus {
    box-shadow: 0 0 0 1pt #a6c7ff;
    outline: none;
  }
}

.radio input[type='radio'] {
  vertical-align: -2px;
}

.checkbox input[type='checkbox'],
.radio input[type='radio'] {
  position: relative;
  appearance: none;
  border: 1px solid #c2c2c2;
  margin-right: 10px;
  background-color: white;

  &:checked {
    &:before {
      content: '';
      position: absolute;
    }
  }

  &:disabled {
    border-color: #e7e7e7;
    background-color: #e7e7e7;
  }
}

.list__input {
  margin-right: 10px;
}

.radio {
  display: flex;
}

.checkbox {
  display: flex;
  span {
    @include fs(14);
    margin-top: 4px;
    font-weight: 300;
    &.checkbox__label {
      margin-top: 2px;
      @include fs(18);
    }
  }

  input[type='checkbox'] {
    min-width: 20px;
    width: 20px;
    height: 20px;
    margin-top: 3px;

    &[name='no_address'] {
      margin-top: 1px;
    }

    &:checked {
      border: 1px solid $blue-primary;

      &:before {
        width: 100%;
        height: 100%;
        background-image: url(../images/checkbox.svg);
        background-repeat: no-repeat;
        background-size: 14px 16px;
        background-position: center;
      }
    }
  }
}

input[type='radio'] {
  flex: 0 0 20px;
  width: 20px;
  height: 20px;
  border-radius: 20px;
  margin-top: 1px;

  &:checked {
    border-color: $blue-primary;
    &:before {
      width: 8px;
      height: 8px;
      border-radius: 8px;
      background-color: $blue-primary;
      left: 50%;
      top: 50%;
      margin-left: -4px;
      margin-top: -4px;
    }
  }
  &:focus {
    box-shadow: 0 0 0 1pt #a6c7ff;
    outline: none;
  }
}

label {
  font-weight: 500;
  @include fs(18);
  position: relative;
}

.form__row,
.form__row--fluid {
  &:not(:last-of-type) {
    @media (max-width: $screen-xs-max) {
      margin-bottom: 20px;
    }
    @media (min-width: $screen-xm) {
      margin-bottom: 24px;
    }
  }
  &.form__row--small {
    margin-bottom: 0;
  }
  &.form__row--conditional {
    padding: 20px 0 30px 0;
  }
}

.form__row {
  display: flex;

  flex-flow: column;
  flex-wrap: wrap;

  .form__item__group {
    flex-basis: 100%;
    margin-bottom: 26px;
    @media (min-width: $screen-sm) {
      flex-basis: calc(50% - 30px);
    }
  }

  .form__item__group--checkbox {
    flex-basis: 100%;
  }

  .form__item {
    display: flex;
    flex-wrap: wrap;

    &:first-of-type {
      flex-basis: 100%;
    }

    &:last-of-type {
      flex-basis: 100%;
    }

    label {
      margin: 0 0 6px 0;
      font-weight: 700;
      @media (max-width: $screen-xm-max) {
        margin-bottom: 10px;
      }
    }
  }
}

.form__row--invoice {
  margin: 0;
}

.form__row--register {
  flex-flow: initial;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 0 !important;
}

.form__row--gdpr {
  .form__item__group {
    margin-top: 10px;
    margin-bottom: 50px;
  }
}

.form__row--fluid {
  .form__item {
    &:first-child {
      padding-right: 25px;
      flex: none;
    }
  }
}

.form__item {
  flex: 1;
  position: relative;
}

.form__invoice {
  padding: 0 0 10px;
  max-width: 370px;
}

.form__invoice--full {
  max-width: 100%;
  // overflow: hidden;
}

.form__password__hint {
  @include fs(14);
  line-height: 24px;
  margin-top: 4px;
}

.toggle-input {
  position: relative;
  &.toggle-input--help {
    padding-right: 50px;
    &:after {
      margin: 0;
    }
  }

  button {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 50px;
    background-color: transparent;
    border: none;
    border-left: 1px solid $silver;
    border-radius: 0;
    text-indent: -999px;
    overflow: hidden;

    &:after {
      content: '';
      background-repeat: no-repeat;
      background-position: center;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      margin-left: -2px;
    }

    &.toggle-password {
      &:after {
        background-image: url(../images/eye.svg);
      }

      &.visibility-on {
        &:after {
          background-image: url(../images/eye-off.svg);
        }
      }
    }

    &.toggle-help {
      border: 1px solid $border-gray;
      border-left: 0;
      &:after {
        content: '?';
        @include fs(18);
        line-height: 52px;
        text-indent: 0;
        border-radius: 0;
        color: $border-gray-light;
        font-weight: 700;
      }
    }
  }
}

.activation {
  .toggle-input {
    &.toggle-input--help {
      max-width: 370px;
    }
  }
}

select {
  appearance: none;
  border-radius: 0;
  &:focus {
    box-shadow: 0 0 0 1pt #a6c7ff;
    outline: none;
  }
  // &:disabled {
  //   background: none;
  // }
}

select::-ms-expand {
  display: none; /* hide the default arrow in ie10 and ie11 */
}

select,
.nice-select .nice-select__control {
  background-position: calc(100% - 15px) 50%;
  background-image: url(../images/ico_dropdown.svg);
  background-repeat: no-repeat;
}

.nice-select {
  .nice-select__indicators {
    display: none;
  }
}

.address-pick {
  .address-pick {
    label {
      padding: 10px 10px 10px 36px;
      display: block;

      input[type='radio'] {
        margin-left: -24px;
        margin-right: 8px;
      }
    }

    &.even {
      border-radius: 6px;
      background-color: #f2f2f2;
    }
  }
}

.address-pick__list {
  max-height: 250px;
  overflow: auto;
}

.quantity-picker {
  height: 30px;
  position: relative;
}

input.quantity-picker__input {
  width: 120px;
  padding: 8px 35px;
  height: 30px;
  text-align: center;
}

.quantity-picker__button {
  position: absolute;
  width: 35px;
  height: 30px;
  background-color: $white;
  border: 1px solid $border-gray-light;
  border-radius: 0;
  top: 0;
  &:disabled {
    background-color: transparentize($border-gray-light, 0.8);
    color: $placeholder-gray;
    cursor: not-allowed;
  }
}

.quantity-picker__button--dec {
  left: 0;
}

.quantity-picker__button--inc {
  right: 0;
}

.contract {
  margin-bottom: 40px;
}

.acknowledgment__box {
  font-weight: 400;
  font-size: 1rem;

  span {
    cursor: pointer;
    text-decoration: underline;
    @include fs(16);
  }
}
