.inbox {
    ul {
        margin: 0;
        padding: 0;
        li {
            list-style-type: none;
        }
    }
}
.inbox__message {
    padding-left: 60px;
    margin-bottom: 15px;
    p {
        margin: 0;
    }
}
.inbox__message__header {
    margin-bottom: 5px;
    display: flex;
    justify-content: space-between;
}
.inbox__message__header__title,
.inbox__message__header__author {
    @include fs(18);
    font-weight: 500;
}
.inbox__message__header__title {
    color: $blue-primary;
}
.inbox__list,
.inbox__message__header {
    a {
        text-decoration: none;
        color: inherit;
        font-weight: inherit;
        font-size: inherit;
        &:hover {
            .inbox__message__header__title {
                text-decoration: underline;
            }
        }
    }
}
.inbox__list {
    .unread {
        .inbox__message__header__title {
            position: relative;
            &:before {
                content: '';
                width: 10px;
                height: 10px;
                border-radius: 5px;
                background-color: $blue-primary;
                position: absolute;
                left: -20px;
                top: 5px;
            }
        }
    }
}
.inbox__filter {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    margin-bottom: 20px;
}
.message__header__headline {
    display: flex;
    justify-content: space-between;
}
.message__header {
    border-bottom: 1px solid $silver;
    margin-bottom: 20px;
    .message__header__label {
        margin-top: 10px;
        margin-bottom: 15px;
    }
}

