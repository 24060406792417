// Colors
$btn-primary-text: #ffffff;
$background: #f8f8f8;
$btn-primary-background: #1e1ec8;
$btn-primary-hover: #11118f;
$link-color: #8eeb00;
$link-primary-color: #28273d;
$link-action-color: #1e1ec8;
$blue-primary: #1e1ec8 !default;
$blue-background: #e8e8f9;
$blue-secondary: #1919aa !default;
$text-gray: #84939f !default;
$placeholder-gray: #707070 !default;
$border-gray: #d9d9d9 !default;
$border-gray-light: #c8c8c8 !default;
$green: #8eeb00;
$validation-text-alert: #b70f0f;
$validataion-background-alert: #ffc2ba;
$validation-text-success: #2c7635;
$validataion-background-success: #dff0d4;
$validation-text-info: #1e1ec8;
$validataion-background-info: #d1e4f1;
$alert--success: #009b12;
$alert--info: #1e1ec8;
$alert--error: #940000;
$alert--warning: #ec7e00;
$yellow: #8eeb00;
$scream: #f44336;
$discount: #b70f0f;
$pink: #eb345e;
$red: #ff005a;

$blue-overlay: rgba(29,31,199,0.6);

$silver: #eaeaea;

$black-1: rgba(0, 0, 0, 0.1);
$black-2: rgba(0, 0, 0, 0.2);
$black-3: rgba(0, 0, 0, 0.3);
$black-4: rgba(0, 0, 0, 0.4);
$black-5: rgba(0, 0, 0, 0.5);
$black-6: rgba(0, 0, 0, 0.6);
$black-7: rgba(0, 0, 0, 0.7);
$black-8: rgba(0, 0, 0, 0.8);
$black-9: rgba(0, 0, 0, 0.9);
$black: #000000;

$white-1: rgba(255, 255, 255, 0.1);
$white-2: rgba(255, 255, 255, 0.2);
$white-3: rgba(255, 255, 255, 0.3);
$white-4: rgba(255, 255, 255, 0.4);
$white-5: rgba(255, 255, 255, 0.5);
$white-6: rgba(255, 255, 255, 0.6);
$white-7: rgba(255, 255, 255, 0.7);
$white-8: rgba(255, 255, 255, 0.8);
$white-9: rgba(255, 255, 255, 0.9);
$white: #ffffff;

// Number of columns
$columns: 12 !default;

// Base font size (only number without px value)
$fs-base: 16 !default;

// Standard fonts to be used.
$standard-font: 'neue-haas-grotesk-display', sans-serif !default;
$monospace-font: 'neue-haas-grotesk-display', monospace, serif !default;

// Headline fonts to be used.
$headline-font: 'neue-haas-grotesk-display', sans-serif !default;

// Font sizes
$fw-100: 100 !default;
$fw-200: 200 !default;
$fw-300: 300 !default;
$fw-400: 400 !default;
$fw-500: 500 !default;
$fw-600: 600 !default;
$fw-700: 700 !default;
$fw-800: 800 !default;
$fw-900: 900 !default;

// Color for the ‘mark’ element.
$mark-bg: #ff0;
$mark-color: #000;

// Border color for fieldsets.
$fieldset-border: #c0c0c0;

$left-nav-with: 350px;
$left-nav-witdth-tablet: 290px;
$left-nav-witdth-mobile: 210px;

// Grid breakpoints
$breakpoints: (
  is: 320px,
  xs: 360px,
  xm: 480px,
  ts: 580px,
  sm: 768px,
  md: 992px,
  lg: 1180px,
  xl: 1680px,
) !default;

// Screen sizes
$screen-is: 320px !default;
$screen-xs: 360px !default;
$screen-xm: 480px !default;
$screen-ts: 580px !default;
$screen-sm: 768px !default;
$screen-md: 992px !default;
$screen-lg: 1180px !default;
$screen-xl: 1680px !default;
$screen-fullhd: 1920px !default;

$screen-is-max: ($screen-xs - 1) !default;
$screen-xs-max: ($screen-xm - 1) !default;
$screen-xm-max: ($screen-ts - 1) !default;
$screen-ts-max: ($screen-sm - 1) !default;
$screen-sm-max: ($screen-md - 1) !default;
$screen-md-max: ($screen-lg - 1) !default;
$screen-lg-max: ($screen-xl - 1) !default;
$screen-notfullhd: ($screen-fullhd - 1) !default;

//
$rt-toast-width: 100%;
$rt-color-error: #d61400;
