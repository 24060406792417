.pagination {
    display: flex;
    justify-content: center;
    font-size: 15px;
    font-weight: 700;
    margin-top: 30px;
    margin-bottom: 30px;

    a {
        &:hover {
            text-decoration: underline;
        }
    }
    .pagination__prev,
    .pagination__next {
        padding-left: 28px;
        padding-right: 28px;
    }
    .pagination__prev,
    .pagination__next,
    .pagination__page {
        border: 1px solid #1e1ec8;
        border-radius: 23px;
        text-decoration: none;
    }
    .pagination__prev,
    .pagination__next,
    .pagination__page,
    .pagination__space {
        height: 44px;
        line-height: 44px;
        color: #1e1ec8;
        text-align: center;
        margin-left: 5px;
        margin-right: 5px;
        display: inline-block;
    }

    .pagination__space {
        line-height: 62px;
    }

    .pagination__page {
        width: 44px;
        &.active {
            background-color: black;
            border-color: black;
            color: white;
        }
    }
    .ico-next {
        &:after {
            width: 7px;
            height: 10px;
            background-image: url(../images/ico-more-right-blue.svg);
        }
    }
    .ico-prev {
        &:after {
            width: 7px;
            height: 10px;
            background-image: url(../images/ico-more-left-blue.svg);
        }
    }
}