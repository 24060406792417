.info__box--documents {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 10px;
    p {
        margin-bottom: 0;
    }
    > div {
        margin: 15px;
    }
}