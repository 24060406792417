@media (max-width: $screen-xm-max),
  (min-width: $screen-lg) and (max-width: $screen-lg + 200px) {
  .list {
    &.list--6--2 {
      .list__row {
        flex-wrap: wrap;
        .list__col {
          flex-basis: 100%;
          &:not(:last-child) {
            margin-bottom: 10px;
          }
        }
      }
    }
  }
}

@media (max-width: $screen-xm-max) {
  .admin__inner {
    article {
      time {
        width: auto;
        br {
          display: none;
        }
      }
    }
  }
  .btn--block--xs {
    display: block;
    width: 100%;
  }
  .admin__user {
    a {
      &:not(:last-child) {
        margin-right: 16px;
      }
    }
  }
  .admin__menu__wrap {
    padding-left: calc(1rem + 80px);
  }
  .offer {
    > .list__row {
      flex-wrap: wrap;
    }
    .list__col--info {
      padding-left: 10px;
      flex-basis: 100%;
      order: 3;
    }
  }
  .offer-step--1 {
    .list__col--info {
      button {
        margin-left: 27px;
      }
    }
  }

  .footline {
    flex-wrap: wrap;
    display: flex;
    button {
      flex-basis: 100%;
    }
    .separator {
      display: none;
    }
  }

  .footline--btn {
    button {
      &:not(:last-child) {
        margin-bottom: 1rem;
      }
    }
  }
  .footline--nextprev {
    button {
      margin-bottom: 1rem;
      &:first-child {
        order: 2;
      }
    }
  }
  .digi-table--services {
    .cell__label {
      width: 43%;
    }
    .cell__content {
      width: 57%;
    }
  }
  .ReactModal__Content {
    padding: 20px !important;
    .button-close {
      top: 15px;
      right: 15px;
    }
  }
  .list.align-center {
    li,
    div {
      align-items: flex-start;
    }
  }

  .btn--next,
  .btn--next {
    padding-left: 45px;
  }
}

@media (min-width: $screen-xm) {
  .with-buttons {
    li {
      min-height: 43px;
    }
  }
}

@media (min-width: $screen-ts) {
  // .form--call-me {
  //   .row--3 {
  //     .col {
  //       &:last-child {
  //         text-align: right;
  //       }
  //     }
  //   }
  // }
  .footline {
    button {
      &:not(:last-child) {
        margin-right: 10px;
      }
    }
  }
}

@media (min-width: $screen-ts) and (max-width: $screen-ts-max) {
  .admin__user {
    a {
      &:not(:last-child) {
        margin-right: 25px;
      }
    }
  }
  .admin__menu__wrap {
    padding-left: calc(1rem + 121px);
  }
}

@media (min-width: $screen-ts) and (max-width: $screen-ts-max) {
  .form--call-me {
    .row--2 {
      .col {
        &:last-child {
          flex: 0 0 210px;
        }
      }
    }
  }
}

@media (max-width: $screen-ts-max) {
  body {
    @include fs(15);
  }
  .list__label {
    @include fs(16);
  }
  .inbox__message {
    padding-left: 35px;
  }
  .flash {
    .alert {
      padding: 25px 10px 10px 15px;
    }
    .button-close {
      top: 12px;
      right: 12px;
    }
  }
  .logo-phone {
    margin-top: 2px;
    margin-right: 16px;
    img {
      display: block;
    }
  }
  .admin__content {
    padding-top: 0;
    h1 {
      @include fs(16);
      margin-top: 15px;
    }
  }
  .admin__menu__wrap {
    margin-left: -1rem;
    margin-right: -1rem;
    padding-right: 1rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    background-color: $blue-primary;
  }
  .admin__user {
    a {
      height: 25px;
      background-size: contain;
      &.call-me {
        width: 25px;
        &:before {
          background-image: url(../images/ico-phone.svg);
        }
      }
      &.user {
        width: 21px;
        &:before {
          background-image: url(../images/ico_uzivatel-phone.svg);
        }
      }
    }
  }
  .admin__user__info {
    display: inline;
  }
  .admin__menu__button {
    position: absolute;
    top: 32px;
    left: 1rem;
    z-index: 1;
    line-height: 25px;
    color: white;
    font-weight: 300;
    &:before {
      content: '';
      width: 25px;
      height: 25px;
      background-size: contain;
      background-image: url(../images/ico_menu-phone.svg);
    }
  }
  .admin__menu__closer {
    top: 32px;
  }
}

@media (max-width: $screen-sm-max) {
  .admin__menu__wrap {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .footline--contact {
    margin-top: 25px;
    margin-bottom: 40px;
  }
  .confirmation {
    text-align: center;
  }
}

@media (max-width: $screen-ts-max) {
  .logout {
    width: 25px;
    &:before {
      background-image: url(../images/ico_logout-phone.svg);
    }
  }
}

@media (min-width: $screen-lg) {
  .logout {
    padding: 10px 20px;
  }
}

@media (min-width: $screen-lg) and (max-width: $screen-lg-max) {
  .admin__menu {
    > a {
      margin-left: 40px;
    }
  }
}

@media (min-width: $screen-sm) and (max-width: $screen-lg-max) {
  .admin__content {
    h1 {
      @include fs(30);
    }
  }
}

@media (min-width: $screen-sm) and (max-width: $screen-md-max) {
  .admin__user__info {
    display: inline;
  }
  .admin__user {
    a {
      display: inline-block;
      width: 20px;
      height: 20px;
      &:before {
        background-position: center center;
      }
      &.logout {
        width: 25px;
        background-color: transparent;
        &:before {
          background-image: url(../images/ico_logout-blue.svg);
        }
      }
    }
    a {
      &:not(:last-child) {
        margin-right: 6px;
      }
    }
  }
}

@media (min-width: $screen-sm) {
  body {
    @include fs(16);
  }

  .list__label {
    @include fs(17);
  }

  .logo-phone {
    display: none;
  }

  .form--call-me {
    .row--2 {
      .col {
        &:first-child {
          flex: 1;
        }
        &:last-child {
          flex: 0 0 210px;
        }
      }
    }
    // .row--3 {
    //   .col {
    //     &:last-child {
    //       text-align: right;
    //     }
    //   }
    // }
  }

  .admin__user {
    .logout {
      color: #fff;
      background-color: #1e1ec8;
      font-weight: 400;
      text-transform: none;
    }
  }
}

@media (min-width: $screen-lg) {
  .admin__user {
    .logout {
      padding: 6px 18px;
      &:before {
        background-position: calc(100% - 10px) center;
      }
      flex-shrink: 0;
    }
    .call-me {
      margin-bottom: 25px;
      &:before {
        background-position: left center;
      }
    }
    .user {
      margin-right: 12px;
    }
    .user,
    .call-me {
      padding-left: 24px;
    }
  }
  .admin__user__info {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    text-align: left;
  }
  .admin__content {
    > header {
      margin-bottom: 50px;
      align-items: flex-end;
      h1 {
        margin-bottom: 0;
      }
    }

    /*
    header + div.banner {
        margin-bottom: 0;
    }
    */
  }
}

@media (max-width: $screen-xs-max) {
  .list--payment-setup {
    .list__col {
      flex-basis: 100%;
    }
    .list__action {
      .btn {
        margin: 15px 0 25px 0;
        width: 100%;
      }
    }
  }
}

@media (max-width: $screen-ts-max),
  (min-width: $screen-sm) and (max-width: $screen-sm + 100px) {
  .list__row--col--setting {
    .list__value {
      flex-basis: 100%;
    }
  }
  .list__row--input {
    .list__value {
      padding-left: 35px;
    }
  }
}

.list__label--payment-success {
  flex-grow: 1;
}
