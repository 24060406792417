img {
    max-width: 100%;
    height: auto;
}


.button-close {
    border: 0;
    background-color: transparent;
    position: absolute;
    height: 25px;
    display: block;
    z-index: 1;
    color: #111111;
    padding-right: 40px;
    top: 40px;
    right: 40px;
    font-size: 15px;
    &:after {
        content: " ";
        display: block;
        background-image: url(../images/ico-close.svg);
        background-repeat: no-repeat;
        width: 25px;
        height: 25px;
        position: absolute;
        top: 0;
        right: 0;
        transition-duration: 0.3s;
    }
    &:hover {
        &:after {
            transform: rotate(180deg);
        }
        text-decoration: underline;
    }
}
a,
.link {
    // Fix font overflow rendering in safari
    padding-left: 1px;

    color: $black;
    transition-duration: 0.2s;
    font-weight: $fw-500;
    &.link--renew {
        background-image: url(../images/renew-green.svg);
        background-repeat: none;
        background-repeat: no-repeat;
        padding-left: 20px;
    }
    &:hover {
        color: $link-action-color;
        text-decoration: none;
    }
    &.link--info {
        color: $blue-primary;
    }
    &.link--small {
        font-weight: 300;
        font-size: 12px;
        &.link--renew {
            background-size: 19px 19px;
            padding-left: 22px;
        }
    }
    &.link-registered {
        margin-left: 32px;
        font-weight: 700;
        @media (max-width: $screen-sm-max) {
            margin-left: 0;
            margin-top: 0;
        }
    }
}
.link-primary {
    color: $link-primary-color;
    &:hover {
        color: inherit;
    }
}

.link--noborder {
    border: none;
    background-color: transparent;
}


.a--arrow--green {
    position: relative;
    padding-right: 14px;
    &:after {
        content: '';
        position: absolute;
        right: 0;
        top: 50%;
        margin-top: -5px;
        background-image: url(../images/a_arrow_blue.svg);
        width: 7px;
        height: 10px;
    }
}
.a--arrow,
.a--arrow--back {
    position: relative;
    &:after {
        margin-top: -5px;
        width: 7px;
        height: 10px;
        top: 50%;
        content: '';
        position: absolute;
    }
}
.a--arrow {
    padding-right: 12px;
    &:after {
        right: 0;
    }
    &.black {
        &:after {
            background-image: url(../images/a_arrow_black.svg);
        }
    }
    &.green {
        &:after {
            background-image: url(../images/a_arrow_green.svg);
        }
    }
}
.a--arrow--back {
    padding-left: 12px;
    &:after {
        left: 0;
        transform: scaleX(-1);
    }
    &.black {
        &:after {
            background-image: url(../images/a_arrow_black.svg);
        }
    }
    &.green {
        &:after {
            background-image: url(../images/a_arrow_green.svg);
        }
    }
}

p {
    margin: 0 0 1em;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
    margin-top: 1em;
    margin-left: 0;
    margin-right: 0;
    line-height: 1.15;
    font-weight: $fw-400;
    font-family: $headline-font;
    color: $blue-primary;
    &:first-child {
        margin-top: 0;
    }
}

h1,
.h1 {
    @include fs(40);
    @include mbem(40);
}

h2,
.h2 {
    @include fs(35);
    @include mbem(35);
}

h3,
.h3 {
    @include mbem(24);
    @include fs(24);
    color: $blue-primary;
    font-weight: 400;
}

h4,
.h4 {
    @include fs(23);
    @include mbem(23);
}

h5,
.h5 {
    @include fs(19);
    @include mbem(19);
}

h6,
.h6 {
    @include fs(17);
    @include mbem(17);
}
select,
textarea,
input[type=number],
input[type=text],
input[type=email],
input[type=date],
input[type=password] {
    border: 1px solid $border-gray;
    background-color: $white;
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
    display: inline-block;
}

input::-webkit-calendar-picker-indicator,
input::-webkit-inner-spin-button {
    display: none;
}

form {
    .nice-select {
        width: 100%;
        height: 52px;
        @include fs(18);
    }
    .nice-select__control {
        border: 1px solid $border-gray;
    }
    .nice-select,
    .nice-select__control {
        border-radius: 0;
        height: 52px;
        input {
            box-shadow: none;
        }
    }
    .nice-select__input {
        width: 100%;
        height: 100%;
    }
    .nice-select__option,
    .nice-select__value-container {
        padding: 0;
        > div:not(.nice-select__placeholder) {
            width: 100%;
            height: 50px;
            padding: 0 20px;
            margin: 0;
        }
    }

    .nice-select__single-value {
        line-height: 50px;
    }

    .nice-select__menu-list {
        padding-top: 0;
        padding-bottom: 0;
        max-height: 200px;
        >div {
            padding: 10px 20px;
        }
    }

    .nice-select__placeholder {
        height: 26px;
        padding:  0 20px;
        padding-right: 30px;
        top: calc(50% - 11px);
        width: 100%;
        margin: 0;
        transform: none;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

    }
}
textarea {
    padding-top: 15px;
    padding-bottom: 15px;
    min-height: 132px;
}
select,
input[type=number],
input[type=text],
input[type=email],
input[type=date],
input[type=password] {
    height: 52px;
    @include fs(18);
}

input[type=date] {
    max-width: 221px;
}
hr {
    border: 0;
    margin: 20px 0;
    background-color: #e1e1e1;
    width: 100%;
    height: 1px;
    display: block;
}

.subtitle {
    display: block;
    font-size: 50%;
}
.hightlight {
    font-weight: 400;
}
.scream {
    font-weight: 500;
}
.scream--pending,
.scream--error {
    color: $scream;
}
.scream--paid,
.scream--success {
    color: $green;
}
.scream--processing {
    color: $blue-primary;
}
.scream--storno {
    color: $black;
}
.scream--payup {
    color: $alert--warning;
}
.ico {
    position: relative;
    display: inline-block;
    &:after {
        float: left;
        content: "";
        top: 0;
        background-repeat: no-repeat;
    }
}
.ico-next {
    &:after {
        width: 6px;
        height: 10px;
        background-image: url(../images/ico-more-white.svg);
    }
}
.ico-next--green {
    &:after {
        width: 6px;
        height: 10px;
        background-image: url(../images/ico-more-right-blue.svg);
    }
}
.ico-next--gray {
    &:after {
        width: 6px;
        height: 10px;
        background-image: url(../images/ico-more-right-blue.svg);
        filter: grayscale(100%);
    }
}
.ico-pdf {
    &:after {
        margin-bottom: -3px;
        width: 18px;
        height: 18px;
        background-image: url(../images/ico-pdf.svg);
        opacity: 0.5;
    }
}
.invoice-pdf {
    text-decoration: none;
    min-width: 61px;
    display: inline-block;
    span {
        text-decoration: underline;
    }
    &:hover {
        span {
            text-decoration: none;
        }
    }
}
.h2--pop {
    font-size: 22px;
    font-weight: 500;
}
.address-label {
    line-height: 44px;
    color: black;
    font-size: 17px;
    font-weight: 600;
}
.intro__text--extra-step {
    a {
        &:hover {
            text-decoration: none;
        }
    }
}
.link {
    background-color: transparent;
    border: none;
    font-weight: 500;
    text-decoration: underline;
    padding-left: 0;
    &:hover {
        text-decoration: none;
    }
}
.green {
    color: $green;
}
.ReactModal__Content {
	h1 {
      font-weight: $fw-700;
		span {
			display: block;
        @include fs(22);
		}
	}
}
