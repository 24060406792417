.reward {
	padding: 2rem;
	color: $black;
	height: 100%;
	position: relative;
	min-height: 360px;

	h2 {
		position: relative;
		margin: 0;
	}

	p {
		position: relative;
		color: $blue-primary;
		margin-top: 2rem;
	}

	img {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	.btn {
		position: absolute;
		bottom: 2rem;
		left: 2rem;
	}

}

.reward__big {
	@media (min-width: $screen-lg) {
		margin-right: 320px;
	}
}

.reward__more {
	background-color: #e8e8f9;
	background-image: url(../images/ico_gift_blue.png);
	padding: 26px 26px 26px 100px;
	background-repeat: no-repeat;
	background-size: 50px;
	background-position: left 20px center;
	color: $blue-primary;
	font-weight: $fw-700;
	height: 100%;
}

.subreward {
	padding: 2rem;
	border: 2px solid $border-gray;
	height: 100%;

	h3 {
		@include fs(20);
		font-weight: $fw-700;
		margin-bottom: 1rem;
	}

	&.selected {
		border: 2px solid $blue-primary;
	}

	&.disabled {
		opacity: 0.5;
	}

	span {
		display: inline-block;
		margin-top: 0.25rem;

	}

	.btn {
		margin-top: 2rem;
		font-weight: $fw-700;
	}
}

.subreward__voucher {
	background-color: $white;
	padding: 16px 32px;
	position: relative;
	display: inline-block;
	margin-top: 20px;
	color: $blue-primary;
	font-weight: $fw-700;

	&:before,
	&:after {
		content: '';
		position: absolute;
		top: 50%;
		margin-top: -16px;
		border: 16px solid #e8e8f9;
		border-radius: 100%;
	}

	&:before {

		left: -16px;
	}

	&:after {
		right: -16px;
	}
}

.subreward--choosen {
	background-color: #e8e8f9;
	border: 0;
	height: 100%;
}


.subreward__price {
	text-decoration: line-through;
	font-weight: $fw-700;
}

.subreward__info {
	font-weight: $fw-700;
	color: $pink;
}

.reward-selection-alert {
	padding: 1rem;
	background-color: $pink;
	color: $white;
	margin-bottom: 1rem;
}

.loyalty-reward-info {
	padding: 1rem 1rem 1rem 3.4rem;
	background-color: $silver;
	background-image: url(../images/ico_gift.svg);
	background-repeat: no-repeat;
	background-position: left 1.25rem center;
}

.loyalty__countdown {
	background: $pink;
	color: $white;
	padding: 20px 30px;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	height: 100%;
}

.loyalty__countdown__title {
	display: block;
	@include fs(25);
	line-height: 1;
}

.loyalty__countdown__subtitle {
	@include fs(16);
	font-weight: $fw-400;
	margin: 0;
}

.loalty__countdown__timer {
	display: flex;
	justify-content: space-between;
	text-align: center;

	padding-left: 1rem;

	span {
		line-height: 1;
		min-width: 60px;
		@include fs(14);

		span {
			@include fs(30);

			font-weight: $fw-700;

		}
	}
}

.reward__countdown {
	@media (max-width: $screen-md-max) {
		position: relative;
		max-width: 300px;
		margin-left: 2rem;
	}

	@media (min-width: $screen-lg) {
		position: absolute;
	}
	right: 2rem;
	top: 2rem;

	.loyalty__countdown {
		padding: 16px;
	}

	.loyalty__countdown__title {
		text-align: center;
		width: 100%;
	}

	.loyalty__countdown__subtitle {
		font-weight: $fw-700;
		@include fs(18);
		margin-bottom: 0.5rem;
		display: block;
	}

	.loalty__countdown__timer {
		padding: 0;
		width: 100%;

		span {
			min-width: 40px;
		}
	}
}

.loyalty__partners__item {
	background-color: #e8e8f9;
	padding: 40px;

	img {
		max-width: 50%;
	}
}

.loyalty__partners__desc {
	margin-bottom:  0;
	padding-bottom: 8px;
}

.loyalty__partners__image {
	border: 8px solid #e8e8f9;

	img {
		display: block;
	}
}

.loyalty__banner {
	background-image: url('../images/tellyklub.jpg');
	background-size: cover;
	background-position: center;
	height: 100%;
	text-align: left;
	border: none;
	position: relative;

	h2 {
		@include fs(32);
	}

	p {
		background-color: $pink;
		color: $white;
		font-weight: 600;
		display: inline-block;
		padding: 1rem 2rem;
		@include fs(17);
		text-decoration: none;
		transition-duration: 0.2s;
	}
}
