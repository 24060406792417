body {
	min-width: $screen-is;
	color: $black;
	@include placeholder($placeholder-gray);
	-webkit-font-smoothing: antialiased;
	line-height: 1.4;
	background-color: $background;
	@media (max-width: $screen-xs-max) {
	    &.modal-open {
			overflow: hidden;
		}
	}
}

.header__go_to_content {
	position: absolute;
	left: -9999px;
}