.intro {
	width: 100vw;
	min-height: 100vh;
	background-image: url(../images/intro_background.png);
	background-size: cover;
	background-position: center;
	max-width: 100%;

	h1 {
		@include fs(35);
	}

	@media (max-width: $screen-xm-max) {
		padding-top: 15px;
		padding-bottom: 15px;
	}
}

.intro__inner {
	@media (min-width: $screen-md) {
		display: flex;
		justify-content: flex-end;
	}
	height: 100%;
}

.intro__inner__logo {
	z-index: 2;
	left: 0;
	top: 0;
	@media (max-width: $screen-sm-max) {
		padding-left: 20px;
		padding-top: 20px;
		max-width: 140px;
		margin: 0 auto;
	}
	@media (min-width: $screen-md) {
		position: fixed;
		padding: 70px 60px;
	}
}

.intro__inner__logo--big {
	@media (max-width: $screen-sm-max) {
		display: none;
	}
}

.logo-mobile {
	@media (min-width: $screen-md) {
		display: none;
	}
}

.intro__inner__text {
	@media (max-width: $screen-sm-max) {
		padding: 20px;
		text-align: center;
	}
	@media (min-width: $screen-md) {
		position: fixed;
		padding: 70px 60px;
		max-width: 600px;
	}
	left: 0;
	bottom: 0;
	box-sizing: content-box;
}

.intro__inner__text--big {
	@media (max-width: $screen-lg-max) {
		display: none;
	}
}

.intro__inner__text--register {

	@media (min-width: $screen-md)  and (max-width: $screen-md-max) {
		max-width: 40vw;
	}

	@media (min-width: $screen-lg) and (max-width: $screen-lg-max) {
		max-width: calc(50vw - 120px);
	}
}

.intro__inner__text__title {
	@media (max-width: $screen-ts-max) {
		@include fs(36);
	}
	@media (min-width: $screen-sm) and (max-width: $screen-md-max) {
		@include fs(40);
	}
	@media (min-width: $screen-md) and (max-width: $screen-md-max) {
		max-width: 72%;
	}
	@media (min-width: $screen-lg) {
		@include fs(75);
	}
	margin-bottom: 10px;
	line-height: 1;
	color: $white;
	font-weight: 700;
}

.intro__inner__text__subtitle {
	@media (max-width: $screen-ts-max) {
		@include fs(24);
	}
	@media (min-width: $screen-sm) and (max-width: $screen-md-max) {
		@include fs(30);
	}
	@media (min-width: $screen-md) and (max-width: $screen-md-max) {
		max-width: 72%;
	}
	@media (min-width: $screen-lg) {
		@include fs(32);
	}
	line-height: 36px;
	color: $white;
}

.intro__box {
	position: relative;
	right: 0;
	width: 100%;
	height: 100%;
	@media (min-width: $screen-md) {
		min-height: 100vh;
	}
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	background-color: $white;

	form {
		width: 100%;
	}

	@media (min-width: $screen-md) {
		max-width: 482px;
	}
	@media (max-width: $screen-sm-max) {
		padding: 15px;
		width: 100%;
		justify-content: center;
		form {
			width: 100%;
		}
	}

	@media (min-width: $screen-ts) {
		padding: 100px 64px;
	}
	@media (max-width: $screen-xm-max) {
		align-items: baseline;

		form {
			flex-basis: 100%;
		}
	}
}

.intro__box--big {

	@media (min-width: $screen-lg) {
		max-width: 960px;
		.intro__header {
			margin-bottom: 0;
		}
	}
	@media (min-width: $screen-md) and (max-width: $screen-md-max) {
		max-width: calc(100% - 300px);
		.intro__header {
			margin-bottom: 0;
		}
	}
	@media (min-width: $screen-sm) and (max-width: $screen-sm-max) {
		max-width: none;
	}

}

.intro__box--register {

	@media (min-width: $screen-md) and (max-width: $screen-md-max) {
		max-width: 60vw;
	}
	@media (min-width: $screen-lg) and (max-width: $screen-lg-max) {
		max-width: 50vw;
	}
	@media (min-width: $screen-xl) {
		max-width: 960px;
	}

}

.intro__logo-mobile {
	max-height: 50px;
	@media (min-width: $screen-sm) {
		display: none;
	}
}

.intro__links {
	padding-top: 15px;
}


.intro__links__call-me {
	position: absolute;
	top: 60px;
	left: 64px;
	padding-left: 20px;
	line-height: 1;

	a {
		color: $blue-primary;
		text-decoration: none;
		@include fs(18);
		@media (max-width: $screen-xs-max) {
			@include fs(14);
		}
	}

	@media (max-width: $screen-ts-max) {
		position: relative;
		top: auto;
		left: auto;
		margin-bottom: 10px;
	}

	&:before {
		position: absolute;
		content: '';
		left: 0;
		top: calc(50% - 8px);
		background-image: url("../images/ico-phone.svg");
		background-size: 11px 15px;
		background-repeat: no-repeat;
		width: 11px;
		height: 15px;
	}
}

.intro__links__web {
	position: absolute;
	bottom: 66px;
	left: 64px;

	a {
		font-weight: 700;
		color: $black;
		text-decoration: none;
	}

	@media (max-width: $screen-ts-max) {
		position: relative;
		left: auto;
		bottom: auto;
		flex-basis: 100%;
		text-align: center;
		padding-top: 20px;
	}
}

.intro__header {
	@media (max-width: $screen-ts-max) {
		margin-bottom: 15px;
		margin-top: 15px;
		text-align: center;
	}
	@media (min-width: $screen-xm) {
		margin-bottom: 40px;
		.intro__header__label {
			text-align: right;
		}
	}

	h1 {
		@include fs(45);
		font-weight: bold;
		@media (max-width: $screen-xs-max) {
			padding-top: 10px;
			@include fs(24);
		}
	}
}

.intro__buttons {
	display: flex;
	@media (min-width: $screen-xm) {
		align-items: center;
		justify-content: space-between;
	}
	@media (max-width: $screen-xm-max) {
		flex-wrap: wrap;
		justify-content: flex-end;
		flex-flow: column;
		align-items: flex-end;
		a {
			margin-top: 20px;
		}
	}
	@media (max-width: $screen-xs-max) {
		.btn {
			width: 100%;
		}
	}
}

.intro__buttons--register {
	justify-content: flex-start;
	flex-flow: initial;
	flex-wrap: wrap;
	align-items: center;
	@media (max-width: $screen-sm-max) {
		justify-content: space-between;
	}
	@media (max-width: $screen-xs-max) {
		justify-content: center;
	}
}

.intro__header__headline {
	@media (max-width: $screen-xs-max) {
		text-align: center;
	}
	@media (min-width: $screen-xm) {
		text-align: right;
	}
}

.intro__buttons__link {
	border-top: 1px solid #eaeaea;
	border-bottom: 1px solid #eaeaea;
	margin-top: 60px;
	padding-top: 24px;
	padding-bottom: 24px;

	a {
		line-height: 2;
		font-weight: 700;
		color: $blue-primary;
	}

	@media (max-width: $screen-xs-max) {
		padding-top: 20px;
		margin-top: 30px;
	}
	@media (max-width: $screen-xm-max) {
		text-align: right;
	}
}

.intro__forgotten {
	color: $black;
	@include fs(14);
}

.from__register__subtitle {
	@media (min-width: $screen-sm) {
		@include fs(30);
		margin-bottom: 69px;
	}
	@media (max-width: $screen-ts-max) {
		margin-bottom: 24px;
		text-align: center;
	}

	div {
		margin-top: 5px;
		@include fs(18);
		@media (max-width: $screen-ts-max) {
			@include fs(12);
			color: $btn-primary-background;
		}
	}
}
