
.admin__menu__button {
	@media (min-width: $screen-md) {
		display: none;
	}
	transition-duration: 0.5s;
	position: absolute;
	top: 25px;
	left: 1rem;
	z-index: 1;
	/*
	position: relative;
	*/
	border: 0;
	text-transform: uppercase;
	background-color: transparent;
	font-weight: $fw-600;
	padding-left: 30px;

	&:before {
		content: '';
		position: absolute;
		left: 0;
		top: 0;
		width: 20px;
		height: 20px;
		background-size: 20px;
		background-image: url(../images/menu.svg);
	}
}

.admin__user {
	text-align: right;

	a {
		span {
			@media (max-width: $screen-md-max) {
				display: none;
			}
		}
	}

	.call-me {
		padding-left: 24px;

		@media (max-width: $screen-ts-max) {
			display: none;
		}
		cursor: pointer;
		vertical-align: middle;
		color: $blue-primary;
		position: relative;

		display: inline-block;
		font-weight: $fw-300;
		text-decoration: none;

		&:hover {
			text-decoration: underline;
		}

		&:before {
			content: '';
			position: absolute;
			left: 0;
			top: 3px;

			width: 17px;
			height: 18px;

			background-image: url(../images/ico-phone.svg);
			background-repeat: no-repeat;
		}
	}
}

.admin__user {
	text-align: right;
	/*
	@media (max-width: $screen-md-max) {
		display: inline-block;
		margin-left: 10px;
	}
	*/
	/*
	@media (min-width: $screen-lg) {
		margin-top: 20px;
	}
	*/
	a {
		font-weight: $fw-400;
		color: #2b3742;
		vertical-align: middle;
		text-decoration: none;
		@media (max-width: $screen-md-max) {
			@include fs(0);
		}
		@media (min-width: $screen-lg) {
			@include fs(15);
		}
		position: relative;
		display: inline-block;

		&:before {
			content: '';
			position: absolute;
			background-repeat: no-repeat;
			width: 100%;
			height: 100%;
			left: 0;
			top: 0;
		}

		&:hover {
			text-decoration: underline;
		}

		&.user {
			margin-top: -4px;
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
			font-weight: $fw-700;
			color: $black;
			/*
			@media (max-width: $screen-md-max) {
				padding: 0;
				width: 20px;
				height: 20px;
			}
			@media (min-width: $screen-lg) {

			}*/
			&:before {
				background-image: url(../images/admin_user.png);
			}
		}

		&.logout {
			color: #fff;
			background-color: #1e1ec8;
			font-weight: 400;
			text-transform: none;
		}
	}
}

.admin__user__info__button {
	@media (max-width: $screen-xm-max) {
		@include fs(16);
		padding: 4px 0 0 0;
	}
}
