.row {
	display: flex;
	flex: 0 1 100%;
	flex-direction: row;
	flex-wrap: wrap;
	list-style: none;
	margin: -1rem -1rem 1rem -1rem;
	padding: 0;

	@include variant(ng) {
		margin: 0;
	}

	@media (min-width: $screen-lg) {
		@include variant(big) {
			margin: -1rem -1rem 1rem -1rem;
		}
	}
}